import axios from 'axios';
import React from 'react';

const calculatePrice = ({ products }) => {
  let totalPrice = 0;
  let discountedPrice = 0;

  products.forEach(product => {
    let productPrice = product.quantity * product.price;
    let productDiscountedPrice = product.quantity * product.discountedPrice;
    totalPrice = totalPrice + productPrice;
    discountedPrice = discountedPrice + productDiscountedPrice;
  });
  return { totalPrice, discountedPrice };
};

const renderSum = products => {
  const { totalPrice, discountedPrice } = calculatePrice(products);
  if (totalPrice !== discountedPrice) {
    return (
      <strong>
        <strike style={{ textDecoration: 'line-through', color: 'red' }}>{totalPrice}</strike>{' '}
        {discountedPrice}грн
      </strong>
    );
  } else {
    return <strong>{totalPrice}грн </strong>;
  }
};

const checkIsAdminLoggined = props => {
  const token = localStorage.getItem("token");
  if (!token) {
    props.history.push("/login");
    return;
  }
  axios
    .get("/api/v1/auth/current-user", {
      headers: {
        Authorization: token
      }
    })
    .then(res => {
      if (res.status === 200) {
        if (res.data.data.type !== "ADMIN") {
          props.history.push("/login");
        }
      }
    })
    .catch(reason => {
      props.history.push("/login");
    });
};

const checkIfUserIsLoggined = props => {
  const token = localStorage.getItem("token");
  if (!token) {
    props.history.push("/login");
    return;
  }
  axios
      .get("/api/v1/auth/current-user", {
        headers: {
          Authorization: token
        }
      })
      .then(res => {
        if (res.status !== 200) {
          props.history.push("/login");
        }
      })
      .catch(reason => {
        props.history.push("/login");
      });
};

export { renderSum, calculatePrice, checkIsAdminLoggined, checkIfUserIsLoggined };


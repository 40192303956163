import React, { Component } from "react";
import axios from "axios";
import "./styles/style.css";
import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";
import SignUp from "./components/pages/SignUp";
import Login from "./components/pages/Login";
import RestorePassword from "./components/pages/RestorePassword";
import { CheckoutPage } from "./components/pages/CheckoutPage";
import DerebanProductPage from "./components/pages/DerebanProductPage";
import GeneralAdminOrdersPage from "./components/new/GeneralAdminOrdersPage";
import AdminProductsPage from "./components/pages/AdminProductsPage";
import AdminUsersPage from "./components/pages/AdminUsersPage";
import PreOrderPage from "./components/pages/PreOrderPage";
import GeneralProductsPage from "./components/new/GeneralProductsPage";
import UserPersonalCabinetPage from "./components/pages/UserPersonalCabinetPage";
import ClientInfoPage from "./components/pages/ClientInfoPage"
import ProductPage from "./components/pages/ProductPage"
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import AdminOrderPage from './components/new/AdminOrderPage';

// http://31.131.24.21
axios.defaults.baseURL = 'https://waaagh-shop.com.ua';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={GeneralProductsPage} />
          <Route exact path="/boxes/" component={GeneralProductsPage} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/restore-password" component={RestorePassword} />
          <Route exact path="/checkout" component={CheckoutPage} />
          <Route exact path="/dereban-product/:id" component={DerebanProductPage} />
          <Route exact path="/product/:id" component={ProductPage} />
          <Route exact path="/pre-order" component={PreOrderPage} />
          <Route exact path="/admin/users" component={AdminUsersPage} />
          <Route exact path="/admin/orders/new" component={GeneralAdminOrdersPage} />
          <Route exact path="/admin/order/:orderId" component={AdminOrderPage} />
          <Route exact path="/admin/products" component={AdminProductsPage} />
          <Route exact path="/user/personal-cabinet" component={UserPersonalCabinetPage} />
          <Route exact path="/info/:eventKey" component={ClientInfoPage}/>
          <Redirect from="/boxes/:categories" to="/boxes?categoryIds=:categories" />
          <Redirect from="/nodeapi/:url" to="/nodeapi/:url" />
          <Redirect from="/api/v1/:url" to="/api/v1/:url" />
          <Redirect from="/" to="/boxes/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import { useBannerStore } from '../store/Banner';

const Banner = () => {
  const bannerPath = useBannerStore(state => state.bannerPath);

  return bannerPath ? (
    <div className='text-center'>
      <Link to={'/pre-order'}>
        <img className='banner img-thumbnail' src={bannerPath} alt='img' />
      </Link>
    </div>
  ) : (
    <div />
  );
};

export default Banner;

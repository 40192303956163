import React from 'react';
import { updateOrder } from '../../utils/adminOrderService';

const AdminCommentModal = ({ selectedOrderId, adminComment, setAdminComment, refreshOrders }) => {

  const handleAdminCommentChange = (e) => {
    setAdminComment(e.target.value);
  };

  const updateComment = () => {
    if (adminComment.trim() === '') {
      return;
    }
    updateOrder(selectedOrderId, null, null, null, null, null, null, adminComment)
      .then(() => {
        document.getElementById('adminCommentModal').click();
        refreshOrders();
      })
      .catch(error => {
        console.error('Failed to update order:', error);
      });
  };

  return (
    <div className='modal fade' id='adminCommentModal' tabIndex='-1' role='dialog'
         aria-labelledby='adminCommentModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-body pb-1'>
        <textarea
          className='form-control'
          value={adminComment}
          onChange={handleAdminCommentChange}
            placeholder="Відредагуйте коментар адміністратора"
            rows="3"
            />
          </div>
          <div className='modal-footer pt-1'>
            <button type='button' className='btn btn-sm btn-secondary' data-dismiss='modal'>Відмінити</button>
            <button type='button' className='btn btn-sm btn-success' onClick={updateComment}>Зберегти зміни
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCommentModal;

import React, { useEffect, useState } from 'react';
import { checkIsAdminLoggined } from '../../utils/utils';
import {
  fetchOrderStatuses,
  fetchPaymentStatuses,
  fetchPaymentTypes,
  fetchProductOrderStatuses,
  fetchProductOrderTypes, fetchReceiveMethods,
} from '../../utils/apiUtils';
import axios, { get } from 'axios';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import {
  defineOrderStatusClassName,
  definePaymentStatusClassName, generateOrderText, handleOrderStatusChange,
  handlePaymentStatusChange, handlePaymentTypeChange, renderPrice,
} from '../../utils/adminOrderService';
import OrderItemDesktop from './OrderItemDesktop';
import { BASE_URL } from '../../shared/constants';
import AdminCommentModal from './AdminCommentModal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Alert from './Alert';
import OrderItemMobile from './OrderItemMobile';
import NovaPostCityInput from './NovaPostCityInput';
import NovaPostDepartmentInput from './NovaPostDepartmentInput';
import NovaPostAddressInput from './NovaPostAddressInput';


const AdminOrderPage = props => {

  const token = localStorage.getItem('token');

  //enums
  const [orderStatusesEnum, setOrderStatusesEnum] = useState([]);
  const [paymentTypesEnum, setPaymentTypesEnum] = useState([]);
  const [paymentStatusesEnum, setPaymentStatusesEnum] = useState([]);
  const [poProductOrderTypeEnum, setPoProductOrderTypeEnum] = useState([]);
  const [poProductStatusEnum, setPoProductStatusEnum] = useState([]);

  const [order, setOrder] = useState(null);

  const [modalImage, setModalImage] = useState('');
  let [selectedItem, setSelectedItem] = useState(null);
  const [editedUserInfo, setEditedUserInfo] = useState(null);

  const [successAPIMessage, setSuccessAPIMessage] = useState('');
  const [successAPIMessageModal, setSuccessAPIMessageModal] = useState('');
  const [errorAPIMessage, setErrorAPIMessage] = useState('');
  const [errorAPIMessageModal, setErrorAPIMessageModal] = useState('');

  const [showUserComment, setShowUserComment] = useState(false);
  const [showAdminComment, setShowAdminComment] = useState(false);
  const [showMaxQuantityTooltip, setShowMaxQuantityTooltip] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);

  const [adminComment, setAdminComment] = useState(false);
  const [percentDiscountOnProduct, setPercentDiscountOnProduct] = useState('');
  const [flatDiscountOnProduct, setFlatDiscountOnProduct] = useState('');
  const [userSearchQuery, setUserSearchQuery] = useState('');
  const [userSearchResults, setUserSearchResults] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isGeneratedMessageInClipboard, setIsGeneratedMessageInClipboard] = useState(false);
  const [isSuccessfullyDeleted, setIsSuccessfullyDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [packagingBoxesOptions, setPackagingBoxesOptions] = useState([]);
  const [selectedPackagingBox, setSelectedPackagingBox] = useState(null);
  const [customPackaging, setCustomPackaging] = useState(false);

  const [receiveMethods, setReceiveMethods] = useState([]);
  const [selectedReceiveMethod, setSelectedReceiveMethod] = useState(null);


  useEffect(() => {
    checkIsAdminLoggined(props);
    window.scrollTo(0, 0);
    localStorage.setItem('currentUrl', window.location.pathname);
    fetchOrderStatuses(token, setOrderStatusesEnum);
    fetchPaymentTypes(token, setPaymentTypesEnum);
    fetchPaymentStatuses(token, setPaymentStatusesEnum);
    fetchProductOrderTypes(token, setPoProductOrderTypeEnum);
    fetchProductOrderStatuses(token, setPoProductStatusEnum);
    fetchReceiveMethods(token, setReceiveMethods);
    getOrderInfo();
    getPackagingBoxes();
  }, []);

  const getOrderInfo = () => {
    axios
      .get('/api/v1/order/' + props.match.params.orderId, {
        headers: {
          Authorization: token,
        },
      })
      .then(res => {
        if (res.status === 200) {
          setOrder(res.data.data);
        }
      }).catch(function(error) {
        setOrder(null);
    });
  };

  const getPackagingBoxes = () => {
    axios
      .get('/api/v1/packaging-boxes', {
        headers: {
          Authorization: token,
        },
      })
      .then(res => {
        if (res.status === 200) {
          setPackagingBoxesOptions(res.data.data);
        }
      })
      .catch(function (error) {
        console.error('Error fetching packaging boxes:', error);
      });
  };

  const updateProductOrder = () => {
    setDisableButtons(true);
    const token = localStorage.getItem('token');

    const requestData = {
      orderId: order.orderId,
      productId: selectedItem.productId,
      price: selectedItem.price,
      discountedPrice: selectedItem.discountedPrice,
      purchasePrice: selectedItem.purchasePrice,
      quantity: selectedItem.quantity,
      promCommission: selectedItem.promCommission,
      productOrderType: selectedItem.productOrderType ? selectedItem.productOrderType.databaseValue : null,
      productStatus: selectedItem.productStatus ? selectedItem.productStatus.databaseValue : null,
    };

    axios
      .put('/api/v1/order/product-order', requestData, {
        headers: {
          Authorization: token,
        },
      })
      .then(response => {
        setSuccessAPIMessageModal('Дані успішно оновлені');
        setErrorAPIMessageModal('');
        getOrderInfo();
        setDisableButtons(false);
      })
      .catch(error => {
        console.error('Error updating product order:', error);
        setErrorAPIMessageModal(error.response.data.errorMessage);
        setSuccessAPIMessageModal('');
        setDisableButtons(false);
      });
  };

  const updateProductOrderQuantity = (newQuantity) => {
    setDisableButtons(true);
    const isDelete = newQuantity === 0;
    const token = localStorage.getItem('token');

    const requestData = {
      orderId: order.orderId,
      productId: selectedItem.productId,
      quantity: newQuantity
    };

    axios
      .put('/api/v1/order/product-order/quantity', requestData, {
        headers: {
          Authorization: token,
        },
      })
      .then(response => {
        setSuccessAPIMessageModal(isDelete ? 'Товар успішно видалено' : 'Кількість успішно змінена');
        setErrorAPIMessageModal('');
        getOrderInfo();
        setDisableButtons(false);
        setIsSuccessfullyDeleted(isDelete);
      })
      .catch(error => {
        console.error('Error updating product order:', error);
        setErrorAPIMessageModal(error.response.data.errorMessage);
        setSuccessAPIMessageModal('');
        setDisableButtons(false);
      });
  };

  const updateDeliveryDetails = () => {
    setDisableButtons(true);
    const token = localStorage.getItem('token');
    const requestData = {
      orderId: order.orderId,
      userId: editedUserInfo.userId,
      receiveMethodId: selectedReceiveMethod.id,
      userInfoId: editedUserInfo.id,
      name: editedUserInfo.name,
      surname: editedUserInfo.surname,
      phone: editedUserInfo.phone,
      city: editedUserInfo.city || null,
      departmentNumber: editedUserInfo.departmentNumber || null,
      novaPostCityRef: editedUserInfo.novaPostCityRef || null,
      novaPostAddressRef: editedUserInfo.novaPostAddressRef || null,
      street: editedUserInfo.street || null,
      novaPostStreetRef: editedUserInfo.novaPostStreetRef || null,
      buildingNumber: editedUserInfo.buildingNumber || null,
      flatNumber: editedUserInfo.flatNumber || null,
    };

    axios.put('/api/v1/user/user-info', requestData, {
      headers: {
        Authorization: token,
      },
    })
      .then(response => {
        setSuccessAPIMessage('Дані успішно оновлено');
        setErrorAPIMessage('');
        getOrderInfo();
        setDisableButtons(false);
      })
      .catch(error => {
        setSuccessAPIMessage('');
        setErrorAPIMessage('Не вдалося оновити дані');
        setDisableButtons(false);
      });
  }

  const handleUserSearch = (userSearchQuery) => {
    if (userSearchQuery.length < 2) return;
    axios
      .get(`/api/v1/user?idOrPhoneOrEmail=${userSearchQuery}`, {
        headers: {
          Authorization: token,
        },
      })
      .then(res => {
        if (res.status === 200) {
          setUserSearchResults(res.data.data);
        }
      }).catch(function(error) {
    });
  };

  const createShipment = () => {
    setIsLoading(true);
    if (!selectedPackagingBox || !selectedPackagingBox.length || !selectedPackagingBox.width || !selectedPackagingBox.height) {
      setErrorAPIMessage('Виберіть габарити коробки або введіть їх вручну');
      setIsLoading(false);
      return;
    } else {
      setErrorAPIMessage("");
    }

    const requestData = {
      orderId: order.orderId,
      packagingParameters: [
        {
          packagingBoxId: selectedPackagingBox.id || null,
          weight: selectedPackagingBox.weight,
          length: selectedPackagingBox.length,
          width: selectedPackagingBox.width,
          height: selectedPackagingBox.height
        }
      ]
    };


    axios.post(`/api/v1/delivery-service/nova-post/shipment`, requestData, {
      headers: {
        Authorization: token,
      },
    })
      .then(response => {
        setSuccessAPIMessage("ТТН успішно сформована");
        setErrorAPIMessage('');
        setIsLoading(false);
        getOrderInfo();
      })
      .catch(error => {
        console.log("error = ", error);
        setSuccessAPIMessage("");
        setErrorAPIMessage(error.response.data.errorMessage ? error.response.data.errorMessage : 'Не вдалось створити ТТН');
        setIsLoading(false);
        console.error('Error creating shipment', error);
      });
  };

  const handleCustomBoxChange = (e) => {
    const { name, value } = e.target;
    setSelectedPackagingBox(prev => ({ ...prev, [name]: value }));
  };

  const handleEditProductOrderButtonClick = (item) => {
    let maxQuantity = item.stockQuantity + item.quantity;
    setSelectedItem({
      ...item,
      maxQuantity: maxQuantity
    });
    setShowMaxQuantityTooltip(false);
    setSuccessAPIMessageModal('');
    setErrorAPIMessageModal('');
    setIsSuccessfullyDeleted(false);
    setPercentDiscountOnProduct('');
    setFlatDiscountOnProduct('');
  };

  const handleEditDeliveryInfoButtonClick = () => {
    setEditedUserInfo(order.userInfo);
    setSelectedReceiveMethod(order.userInfo.receiveMethod);
    setSuccessAPIMessageModal('');
    setErrorAPIMessageModal('');
    setUserSearchQuery(order.userInfo.userId ? 'id: ' + order.userInfo.userId : "");
  }

  const calculateDiscount = (percentDiscount, flatDiscount) => {
    if (selectedItem === undefined || selectedItem === null) {
      return;
    }
    const price = selectedItem.price;
    const newDiscountedPrice = flatDiscount && flatDiscount > 0 ? price - flatDiscount
      : Math.ceil(price - ((price * percentDiscount) / 100));
    setSelectedItem({
      ...selectedItem,
      discountedPrice: newDiscountedPrice
    });
  }

  const onChangeQuantity = (add) => {
    let resultQuantity = add ? selectedItem.quantity + 1 : selectedItem.quantity - 1;

    console.log(selectedItem);

    //ignore max quantity for TO_ORDER products when status not IN_STOCK
    if (selectedItem.productOrderType.databaseValue === 'TO_ORDER' && selectedItem.productStatus.databaseValue !== 'IN_STOCK' && resultQuantity >= 1) {
      setSelectedItem({
        ...selectedItem, quantity: resultQuantity,
      });
      return;
    }

    if (resultQuantity >= 1 && resultQuantity <= selectedItem.maxQuantity) {
      setSelectedItem({
        ...selectedItem,
        quantity: resultQuantity
      });
    }

    if (resultQuantity > selectedItem.maxQuantity) {
      setShowMaxQuantityTooltip(true);
    }
  }

  const handleConfirmProductOrderEdit = () => {
    updateProductOrder();
  };

  const handleConfirmProductOrderQuantityEdit = () => {
    updateProductOrderQuantity(selectedItem.quantity);
  };

  const handleConfirmDeleteProductOrder = () => {
    updateProductOrderQuantity(0);
  };

  const handleConfirmDeliveryEdit = () => {
    updateDeliveryDetails();
  };

  const handleUserSearchInputChange = event => {
    setUserSearchQuery(event.target.value);
    handleUserSearch(event.target.value);
  };

  const handleUserSearchResultClick = (user) => {
    setUserSearchQuery(`${user.id}, ${user.phoneNumber}, ${user.emailAddress}`);
    setEditedUserInfo(prev => ({ ...prev, userId: user.id }));
    setUserSearchResults([]);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setAlertMessage("Додано в буфер обміну");
      setShowAlert(true);
      setIsGeneratedMessageInClipboard(false);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const handleReceiveMethodChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    const selectedMethod = receiveMethods.find(method => method.id === selectedValue);
    setSelectedReceiveMethod(selectedMethod);
    if (selectedMethod) {
      if (selectedValue === 1) {
        setEmptyAddress();
      } else if (selectedValue === 2) {
        setEmptyCityAndDepartmentNumber();
      } else if (selectedValue === 3) {
        setEmptyCityAndDepartmentNumberAndAddress();
      }
    }
  };

  const setEmptyCityAndDepartmentNumber = () => {
    setEditedUserInfo(prev => ({
      ...prev,
      city: '',
      novaPostCityRef: '',
      novaPostDeliveryCityRef: '',
      departmentNumber: '',
      novaPostAddressRef: ''
    }));
  };

  const setEmptyAddress = () => {
    setEditedUserInfo(prev => ({
      ...prev,
      street: '',
      novaPostStreetRef: '',
      buildingNumber: '',
      flatNumber: ''
    }));
  };

  const setEmptyCityAndDepartmentNumberAndAddress = () => {
    setEditedUserInfo(prev => ({
      ...prev,
      city: '',
      novaPostCityRef: '',
      novaPostDeliveryCityRef: '',
      departmentNumber: '',
      novaPostAddressRef: '',
      street: '',
      novaPostStreetRef: '',
      buildingNumber: '',
      flatNumber: ''
    }));
  };

  const renderGenerateMessageToClipboardIcon = () => {
    if (!isGeneratedMessageInClipboard) {
      return (
        <i
          className='bi bi-clipboard-data pl-1'
          style={{ cursor: 'pointer' }}
          onClick={() => generateOrderTextToClipboard(order)}
        />
      )
    } else {
      return (
        <i
          className='bi bi-clipboard-check text-success pl-1'
          style={{ cursor: 'pointer' }}
        />
      )
    }
  }

  const renderUserComment = () => {
    return (
      <div>
        {order.userComment && !showUserComment && (
          <span className='cursor-pointer font-weight-bold text-success'
                onClick={() => setShowUserComment(true)}>
                          <i className='bi bi-chat-square-text pr-1' />
                          Коментар клієнта
                          </span>
        )}

        {order.userComment && showUserComment && (
          <div className='card bg-light mt-1'>
            <div className='card-header d-flex justify-content-between align-items-center'>
              <div className='font-weight-bold text-success'>
                <i className='bi bi-chat-square-text pr-1' />
                Коментар клієнта
              </div>
              <span
                className='cursor-pointer'
                onClick={() => setShowUserComment(false)}
              >
                                &times;
                              </span>
            </div>
            <div className='card-body p-2'>
              <span className='card-text' style={{ whiteSpace: 'pre-line' }}>{order.userComment}</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderAdminComment = () => {
    return (
      <div>
        {!order.adminComment && (
          <span className='font-weight-bold cursor-pointer' data-toggle='modal'
                data-target='#adminCommentModal'
                onClick={() => {
                  setAdminComment('');
                }}>
                          <i className='bi bi-chat-square-text pr-1' />Додати коментар
                        </span>
        )}
        {order.adminComment && !showAdminComment && (
          <span className='cursor-pointer font-weight-bold text-danger'
                onClick={() => setShowAdminComment(true)}>
                            <i className='bi bi-chat-square-text pr-1' />
                            Коментар адміністратора
                          </span>
        )}

        {order.adminComment && showAdminComment && (
          <div className='card bg-light mt-1'>
            <div className='card-header d-flex justify-content-between align-items-center'>
              <div className='font-weight-bold text-danger'>
                <i className='bi bi-chat-square-text pr-1' />
                Коментар адміністратора
              </div>
              <span
                className='cursor-pointer'
                onClick={() => setShowAdminComment(false)}
              >
                                &times;
                              </span>
            </div>
            <div className='card-body p-2'>
                              <span className='card-text' style={{ whiteSpace: 'pre-line' }}>
                                {order.adminComment} {' '}
                                <i className='bi bi-pencil-fill cursor-pointer' data-toggle='modal'
                                   data-target='#adminCommentModal'
                                   onClick={() => {
                                     setAdminComment(order.adminComment);
                                   }} />
                              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const generateOrderTextToClipboard = (order) => {
    let text = generateOrderText(order);

    navigator.clipboard.writeText(text);
    setIsGeneratedMessageInClipboard(true);

    setAlertMessage('Повідомлення успішно згенеровано і додано в буфер обміну');
    setShowAlert(true);
  };


  return (
    <div className='container'>
      <Helmet>
        <title>Деталі замовлення</title>
      </Helmet>

      <div className='row'>
        <div className='col p-0'>
          <Header />
        </div>
      </div>

      <Alert show={showAlert} setShow={setShowAlert} message={alertMessage} timeout={1500} />

      {order && (
        <AdminCommentModal
          selectedOrderId={order.orderId}
          adminComment={adminComment}
          setAdminComment={setAdminComment}
          refreshOrders={getOrderInfo}
        />
      )}

      <div className='modal fade' id='imageModal' tabIndex='-1' role='dialog' aria-labelledby='imageModalLabel'
           aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <img src={BASE_URL + '/api/v1/product/image/' + modalImage} alt='Product' className='img-fluid' />
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='changeDiscountModal' tabIndex='-1' role='dialog'
           aria-labelledby='changeDiscountModalLabel'
           aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              {selectedItem && (
                <form>
                  <div className='form-group'>
                    <label>Ціна:</label>
                    <input type='number' className='form-control' value={selectedItem.price}
                           onChange={(e) => setSelectedItem({ ...selectedItem, price: e.target.value })} />
                  </div>
                  <div className='form-group'>
                    <label className='text-center font-weight-bold w-100'>Встановити знижку:</label>
                    <div className="row">
                      <div className="col-6">
                        <label className='d-block'>Відсоток знижки</label>
                        <input
                          type='number'
                          className='form-control'
                          value={percentDiscountOnProduct}
                          onChange={(e) => {
                            setPercentDiscountOnProduct(e.target.value);
                            setFlatDiscountOnProduct('');
                            calculateDiscount(e.target.value, null);
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <label className='d-block'>Знижка в грн</label>
                        <input
                          type='number'
                          className='form-control'
                          value={flatDiscountOnProduct}
                          onChange={(e) => {
                            setFlatDiscountOnProduct(e.target.value);
                            setPercentDiscountOnProduct('');
                            calculateDiscount(null, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label>Ціна зі знижкою:</label>
                    <input type='number' className='form-control' value={selectedItem.discountedPrice}
                           onChange={(e) => setSelectedItem({ ...selectedItem, discountedPrice: e.target.value })} />
                  </div>
                </form>
              )}
              <div>
                {successAPIMessageModal && <div className='alert alert-success mb-0' role='alert'>{successAPIMessageModal}</div>}
                {errorAPIMessageModal && <div className='alert alert-danger mb-0' role='alert'>{errorAPIMessageModal}</div>}
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal'>Закрити</button>
              <button type='button' className='btn btn-success' onClick={() => handleConfirmProductOrderEdit()}
                      disabled={disableButtons}>
                Застосувати знижку
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='confirmDeleteModal' tabIndex='-1' role='dialog'
           aria-labelledby='confirmDeleteModalLabel'
           aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <p className='font-weight-bold mb-0'>Ви дійсно хочете видалити цей товар з замовлення?</p>
              <p className='text-muted'>Якщо товар "В наявності на складі" або в "Передзамовленні" і "Замовлений" - його кількість буде автоматично
                збільшена після видалення</p>
              <p className='font-weight-bold text-danger mb-0'>Замовлення буде автоматично видалено після видалення останнього товару</p>
              <div className='pt-3'>
                {successAPIMessageModal &&
                  <div className='alert alert-success mb-0' role='alert'>{successAPIMessageModal}</div>}
                {errorAPIMessageModal &&
                  <div className='alert alert-danger mb-0' role='alert'>{errorAPIMessageModal}</div>}
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal'>Закрити</button>
              {!isSuccessfullyDeleted && (
                <button type='button' className='btn btn-danger' onClick={() => handleConfirmDeleteProductOrder()}
                        disabled={disableButtons}>
                  Видалити товар
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='editQuantityModal' tabIndex='-1' role='dialog'
           aria-labelledby='editQuantityModalLabel' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header justify-content-center'>
              {selectedItem && (
                <h5 className='modal-title font-weight-bold' id='editQuantityModalLabel'>Змінити кількість товару</h5>
              )}
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'
                      style={{ position: 'absolute', right: '15px' }}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              {selectedItem && (
                <div className="d-flex justify-content-center align-items-center">
                  <div className="quantity-selector d-flex align-items-center">
                    <div className="disable-select d-flex align-items-center">
                <span
                  onClick={() => onChangeQuantity(false)}
                  className='minus bg-dark'
                >
                  -
                </span>
                      <span className='quantity pt-1 px-2'>{selectedItem.quantity}шт.</span>
                      <OverlayTrigger placement='top' overlay={showMaxQuantityTooltip ? (<Tooltip id='tooltip'>
                        <strong>Обрано максимальну кількість, більше немає в наявності</strong>
                      </Tooltip>) : (<span></span>)}>
                  <span
                    onClick={() => onChangeQuantity(true)}
                    className='plus bg-dark'
                  >
                    +
                  </span>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              )}
              <div className="pt-3">
                {successAPIMessageModal && <div className='alert alert-success mb-0' role='alert'>{successAPIMessageModal}</div>}
                {errorAPIMessageModal && <div className='alert alert-danger mb-0' role='alert'>{errorAPIMessageModal}</div>}
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal'>Закрити</button>
              <button type='button' className='btn btn-success' onClick={() => handleConfirmProductOrderQuantityEdit()}
                      disabled={disableButtons}>
                Зберегти
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='editProductOrderModal' tabIndex='-1' role='dialog'
           aria-labelledby='editProductOrderModalLabel'
           aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              {selectedItem && (
                <h5 className='modal-title font-weight-bold' id='editModalLabel'>Редагування: {selectedItem.name}</h5>
              )}
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              {selectedItem && (
                <form>
                  <div className='form-group'>
                    <label>Ціна:</label>
                    <input type='number' className='form-control' value={selectedItem.price}
                           onChange={(e) => setSelectedItem({ ...selectedItem, price: e.target.value })} />
                  </div>
                  <div className='form-group'>
                    <label>Ціна зі знижкою:</label>
                    <input type='number' className='form-control' value={selectedItem.discountedPrice}
                           onChange={(e) => setSelectedItem({ ...selectedItem, discountedPrice: e.target.value })} />
                  </div>
                  <div className='form-group'>
                    <label>Ціна закупки:</label>
                    <input type='number' className='form-control' value={selectedItem.purchasePrice}
                           onChange={(e) => setSelectedItem({ ...selectedItem, purchasePrice: e.target.value })} />
                  </div>
                  <div className='form-group'>
                    <label>Комісія прому:</label>
                    <input type='number' className='form-control' value={selectedItem.promCommission}
                           onChange={(e) => setSelectedItem({ ...selectedItem, promCommission: e.target.value })} />
                  </div>
                  <div className='form-group'>
                    <label>Тип замовлення товару:</label>
                    <select className='form-control'
                            value={selectedItem.productOrderType ? selectedItem.productOrderType.databaseValue : "Не визначено"}
                            onChange={(e) => setSelectedItem({
                              ...selectedItem,
                              productOrderType: { ...selectedItem.productOrderType, databaseValue: e.target.value },
                            })}>
                      {!selectedItem.productOrderType && (
                        <option value="Не визначено">Не визначено</option>
                      )}
                      {poProductOrderTypeEnum.map((type, index) => (
                        <option key={index} value={type.databaseValue}>{type.clientValue}</option>
                      ))}
                    </select>
                  </div>
                  <div className='form-group'>
                    <label>Статус товару:</label>
                    <select className='form-control'
                            value={selectedItem.productStatus ? selectedItem.productStatus.databaseValue : "Не визначено"}
                            onChange={(e) => setSelectedItem({
                              ...selectedItem,
                              productStatus: { ...selectedItem.productStatus, databaseValue: e.target.value },
                            })}>
                      {!selectedItem.productStatus && (
                        <option value="Не визначено">Не визначено</option>
                      )}
                      {poProductStatusEnum.map((status, index) => (
                        <option key={index} value={status.databaseValue}>{status.shortValue}</option>
                      ))}
                    </select>
                  </div>
                </form>
              )}
              {successAPIMessageModal && <div className='alert alert-success mb-0' role='alert'>{successAPIMessageModal}</div>}
              {errorAPIMessageModal && <div className='alert alert-danger mb-0' role='alert'>{errorAPIMessageModal}</div>}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal'>Закрити</button>
              <button type='button' className='btn btn-success'
                      onClick={() => handleConfirmProductOrderEdit()}
                      disabled={disableButtons}>
                Зберегти
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='editDeliveryModal' tabIndex='-1' role='dialog'
           aria-labelledby='editDeliveryModalLabel'
           aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              {editedUserInfo && (
                <h5 className='modal-title font-weight-bold' id='editDeliveryModalLabel'>Редагування даних для
                  доставки</h5>
              )}
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              {editedUserInfo && (
                <div>
                  <div className='form-group'>
                    <label>Ім'я:</label>
                    <input type='text' className='form-control' value={editedUserInfo.name}
                           onChange={(e) => setEditedUserInfo({ ...editedUserInfo, name: e.target.value })} />
                  </div>
                  <div className='form-group'>
                    <label>Прізвище:</label>
                    <input type='text' className='form-control' value={editedUserInfo.surname}
                           onChange={(e) => setEditedUserInfo({ ...editedUserInfo, surname: e.target.value })} />
                  </div>
                  <div className='form-group'>
                    <label>Телефон:</label>
                    <input type='text' className='form-control' value={editedUserInfo.phone}
                           onChange={(e) => setEditedUserInfo({ ...editedUserInfo, phone: e.target.value })} />
                  </div>

                  <div className='form-group mb-3'>
                    <label htmlFor='receiveMethod'>Спосіб отримання</label>
                    <select
                      className='form-control'
                      id='receiveMethod'
                      onChange={handleReceiveMethodChange}
                      value={selectedReceiveMethod ? selectedReceiveMethod.id : ''}
                    >
                      {!selectedReceiveMethod && (
                        <option value='' disabled hidden>
                          Оберіть спосіб отримання
                        </option>
                      )}
                      {receiveMethods.map(method => (
                        <option key={method.id} value={method.id}>
                          {method.description}
                        </option>
                      ))}
                    </select>
                  </div>

                  {selectedReceiveMethod && (
                    <div>
                      {selectedReceiveMethod.id !== 3 && (
                        <NovaPostCityInput
                          value={editedUserInfo.city}
                          onCityChange={(suggestion) => setEditedUserInfo({
                            ...editedUserInfo,
                            city: suggestion.present,
                            novaPostCityRef: suggestion.ref,
                            novaPostDeliveryCityRef: suggestion.deliveryCity
                          })}
                        />
                      )}

                      {selectedReceiveMethod.id === 1 && (
                        <NovaPostDepartmentInput
                          value={editedUserInfo.departmentNumber}
                          onDepartmentChange={(suggestion) => setEditedUserInfo({
                            ...editedUserInfo,
                            departmentNumber: suggestion.description,
                            novaPostAddressRef: suggestion.ref,
                          })}
                          novaPostCityRef={editedUserInfo.novaPostCityRef}
                        />
                      )}

                      {selectedReceiveMethod.id === 2 && (
                        <NovaPostAddressInput
                          street={editedUserInfo.street}
                          buildingNumber={editedUserInfo.buildingNumber}
                          flatNumber={editedUserInfo.flatNumber}
                          onStreetChange={(suggestion) => setEditedUserInfo({
                            ...editedUserInfo,
                            street: suggestion.street,
                            novaPostStreetRef: suggestion.ref,
                          })}
                          onBuildingNumberChange={(buildingNumber) => setEditedUserInfo({
                            ...editedUserInfo,
                            buildingNumber: buildingNumber
                          })}
                          onFlatNumberChange={(flatNumber) => setEditedUserInfo({
                            ...editedUserInfo,
                            flatNumber: flatNumber
                          })}
                          novaPostDeliveryCityRef={editedUserInfo.novaPostDeliveryCityRef}/>
                      )}
                    </div>
                  )}
                  <div className='form-group'>
                    <label>Прикріплено до користувача:</label>
                    <input
                      type='search'
                      className='form-control'
                      value={userSearchQuery}
                      placeholder='Введіть id, телефон або імейл користувача'
                      onChange={handleUserSearchInputChange}
                    />
                    {userSearchResults.length > 0 && (
                      <ul className='dropdown-menu show'
                          style={{ width: '100%', maxHeight: '150px', overflowY: 'auto' }}>
                        {userSearchResults.map((user, index) => (
                          <React.Fragment key={user.id}>
                            <li className='dropdown-item' onClick={() => handleUserSearchResultClick(user)}>
                              {user.id}, {user.phoneNumber}, {user.emailAddress}
                            </li>
                            {index < userSearchResults.length - 1 && <div className='dropdown-divider'></div>}
                          </React.Fragment>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              )}
              {successAPIMessage && <div className='alert alert-success mb-0' role='alert'>{successAPIMessage}</div>}
              {errorAPIMessage && <div className='alert alert-danger mb-0' role='alert'>{errorAPIMessage}</div>}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal'>Закрити</button>
              <button type='button' className='btn btn-success' onClick={() => handleConfirmDeliveryEdit()}
                      disabled={disableButtons}>
                Зберегти
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='selectWeightModal' tabIndex='-1' role='dialog'
           aria-labelledby='selectWeightModalLabel' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header justify-content-center'>
              <h5 className='modal-title font-weight-bold'>Оберіть пакування</h5>
            </div>
            <div className='modal-body'>
              <div className='container'>
                <div className='row'>
                  {packagingBoxesOptions && packagingBoxesOptions.map((box, index) => (
                    <div key={index} className='col-lg-4 col-md-4 col-sm-6 col-6'>
                      <div onClick={() => {
                        setSelectedPackagingBox(box);
                        setErrorAPIMessage('');
                      }}
                           className={`box-option ${selectedPackagingBox === box ? 'selected' : ''}`}>
                        <img src={BASE_URL + '/api/v1/product/image/' + box.awsImageName} alt={box.description}
                             className='img-fluid' />
                        <p>{box.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <h6 className='font-weight-bold cursor-pointer' onClick={() => {
                      setSelectedPackagingBox({});
                      setCustomPackaging(!customPackaging);
                    }}>
                      {!customPackaging ? 'Ввести габарити вручну' : 'Відмінити введення габаритів'}
                    </h6>
                  </div>
                </div>
                  {selectedPackagingBox && customPackaging && (
                    <div className='row'>
                      <div className='col-lg-4 col-md-4 col-sm-12'>
                        <label>Довжина (см)</label>
                        <input type='number' className='form-control' name='length'
                               value={selectedPackagingBox.length || ''} onChange={handleCustomBoxChange} />
                      </div>
                      <div className='col-lg-4 col-md-4 col-sm-12'>
                        <label>Ширина (см)</label>
                        <input type='number' className='form-control' name='width'
                               value={selectedPackagingBox.width || ''} onChange={handleCustomBoxChange} />
                      </div>
                      <div className='col-lg-4 col-md-4 col-sm-12'>
                        <label>Висота (см)</label>
                        <input type='number' className='form-control' name='height'
                               value={selectedPackagingBox.height || ''} onChange={handleCustomBoxChange} />
                      </div>
                      <div className='col-12'>
                        <label>Вага (кг) (не обов'язкове поле)</label>
                        <input type='number' className='form-control' name='weight'
                               value={selectedPackagingBox.weight || ''} onChange={handleCustomBoxChange} />
                      </div>
                    </div>
                  )}
                {successAPIMessage &&
                  <div className='alert alert-success mb-0 mt-2' role='alert'>{successAPIMessage}</div>}
                {errorAPIMessage &&
                  <div className='alert alert-danger mb-0 mt-2' role='alert'>{errorAPIMessage}</div>}
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal'>Закрити</button>
              {order && !order.trackingNumber && order.userInfo.receiveMethod.id !== 3 && (
                <button type='button' className='btn btn-dark' onClick={createShipment} disabled={isLoading}>
                  {isLoading ? 'Завантаження...' : 'Створити накладну'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='content d-none d-lg-block font-size-15px'>
        {order === null ? (
          <h5 className='text-center font-weight-bold pt-2 text-danger'>Замовлення не знайдено</h5>
        ) : (
          <div>
            <div className='row py-3'>
              <div className='col-12 p-0'>
                <div className='card'>
                  <div className='card-header'>
                    <div className='d-flex justify-content-between p-0'>
                      <div className='d-flex align-items-center'>
                        <div className='font-weight-bold'>
                          #{order.orderId}
                        </div>
                        {renderGenerateMessageToClipboardIcon()}
                        <div className='dropdown font-size-20px ml-3 mr-3'>
                          <div className={defineOrderStatusClassName(order.orderStatus)}
                               data-toggle='dropdown'
                               aria-expanded='false'>
                            {order.orderStatus.adminValue}
                          </div>
                          <div className='dropdown-menu order-status'>
                            {orderStatusesEnum
                              .filter(orderStatus => orderStatus.adminValue !== order.orderStatus.adminValue)
                              .map((status, index) => (
                                <a
                                  key={index}
                                  className='dropdown-item'
                                  onClick={() => handleOrderStatusChange(order, status, getOrderInfo)}>
                                  {status.adminValue}
                                </a>
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className='d-flex align-items-center'>
                        <div className='dropdown mr-2'>
                          <div className='dropdown-toggle'
                               data-toggle='dropdown'
                               aria-expanded='false'>
                            {order.paymentType.databaseValue === 'CREDIT_CARD' ? (
                              <i className='bi bi-credit-card pr-1' />
                            ) : order.paymentType.databaseValue === 'CASH_ON_DELIVERY' ? (
                              <i className='bi bi-cash-coin pr-1' />
                            ) : null}
                            {order.paymentType.adminValue}
                          </div>
                          <div className='dropdown-menu payment-type'>
                            {paymentTypesEnum
                              .filter(paymentType => paymentType.adminValue !== order.paymentType.adminValue)
                              .map((paymentType, index) => (
                                <a
                                  key={index}
                                  className='dropdown-item'
                                  onClick={() => handlePaymentTypeChange(order, paymentType, getOrderInfo)}>
                                  {paymentType.adminValue}
                                </a>
                              ))}
                          </div>
                        </div>

                        <div className='dropdown pb-1 mr-3'>
                          <div className={definePaymentStatusClassName(order.paymentStatus)}
                               data-toggle='dropdown'
                               aria-expanded='false'>
                            {order.paymentStatus.adminValue}
                          </div>
                          <div className='dropdown-menu payment-statuses'>
                            {paymentStatusesEnum
                              .filter(paymentStatus => paymentStatus.adminValue !== order.paymentStatus.adminValue)
                              .map((paymentStatus, index) => (
                                <a
                                  key={index}
                                  className='dropdown-item'
                                  onClick={() => handlePaymentStatusChange(order, paymentStatus, getOrderInfo)}>
                                  {paymentStatus.adminValue}
                                </a>
                              ))}
                          </div>
                        </div>

                        <span className='text-muted'>{order.createTime}</span>
                      </div>
                    </div>
                  </div>
                  <div className='card-body pt-0'>
                    {order.orderItems.map((item, index) => (
                      <OrderItemDesktop
                        key={index}
                        item={item}
                        setModalImage={setModalImage}
                        handleEditClick={handleEditProductOrderButtonClick}
                      />
                    ))}
                    <div className='row'>
                      <div className="col-6 pt-3">
                        {renderUserComment()}
                      </div>

                      <div className="col-6 pt-3">
                        {renderAdminComment()}
                      </div>
                    </div>
                  </div>
                  <div className='card-footer d-flex justify-content-end'>
                    <div className='mr-2'>Загальна сума:</div>
                    {renderPrice(order)}
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-3">
              <div className='col-6 p-0'>
                <div className='card mb-3'>
                  <div className='card-header d-flex justify-content-between align-items-center'>
                    <h5 className='card-title font-weight-bold m-0'>Дані для доставки: </h5>
                    <i className='bi bi-pencil-fill cursor-pointer' data-toggle='modal' data-target='#editDeliveryModal'
                       onClick={() => handleEditDeliveryInfoButtonClick()} />
                  </div>
                  <div className='card-body'>
                    {order.trackingNumber && (
                      <p className='card-text mb-3' style={{ fontSize: '1.25rem' }}>
                        <strong>Номер накладної:</strong>
                        <span className=''>
                          {' '}{order.trackingNumber.slice(0, -4)}
                          <span className='font-weight-bold'>{order.trackingNumber.slice(-4)}</span>
                        </span>
                      </p>
                    )}
                    <p className='card-text mb-1'><strong>Тип доставки:</strong> {order.userInfo.receiveMethod.description}</p>
                    <p className='card-text mb-1'><strong>Ім'я:</strong> {order.userInfo.name}</p>
                    <p className='card-text mb-1'><strong>Прізвище:</strong> {order.userInfo.surname}</p>
                    <p className='card-text mb-1' style={{ cursor: 'pointer' }}
                       onClick={() => copyToClipboard(order.userInfo.phone)}>
                      <strong>Телефон:</strong> {order.userInfo.phone}
                    </p>
                    {order.userInfo.receiveMethod.id === 1 && (
                      <div>
                        <p className='card-text mb-1'><strong>Місто:</strong> {order.userInfo.city}</p>
                        <p className='card-text mb-1'><strong>Номер відділення /
                          поштомату:</strong> {order.userInfo.departmentNumber}</p>
                      </div>
                    )}
                    {order.userInfo.receiveMethod.id === 2 && (
                      <div>
                        <p className='card-text mb-1'><strong>Місто:</strong> {order.userInfo.city}</p>
                        <p className='card-text mb-1'><strong>Вулиця:</strong> {order.userInfo.street}</p>
                        <p className='card-text mb-1'><strong>Номер будинку:</strong> {order.userInfo.buildingNumber}
                        </p>
                        <p className='card-text mb-1'><strong>Номер квартири:</strong> {order.userInfo.flatNumber}</p>
                      </div>
                    )}
                    <p className='card-text mb-1'><strong>ID
                      користувача:</strong> {order.userInfo.userId ? order.userInfo.userId : 'Замовлення без авторизації'}
                    </p>
                    {!order.trackingNumber && order.userInfo.receiveMethod.id !== 3 && (
                      <div className='d-flex justify-content-center'>
                        <button
                          className='btn btn-dark btn-block mt-2'
                          data-toggle='modal'
                          data-target='#selectWeightModal'
                          onClick={() => {
                            setErrorAPIMessage('');
                            setSuccessAPIMessage('');
                          }}
                        >
                          Створити накладну
                        </button>
                      </div>
                    )}
                    {successAPIMessage &&
                      <div className='alert alert-success mb-0 mt-2' role='alert'>{successAPIMessage}</div>}
                    {errorAPIMessage &&
                      <div className='alert alert-danger mb-0 mt-2' role='alert'>{errorAPIMessage}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='content-mobile d-lg-none font-size-15px'>
        {order === null ? (
          <h5 className='text-center font-weight-bold pt-2 text-danger'>Замовлення не знайдено</h5>
        ) : (
          <div className='row'>
            <div className='col-12 p-0'>
            <div className='card'>
              <div className='card-header p-2'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='font-weight-bold'>#{order.orderId}
                    {renderGenerateMessageToClipboardIcon()}
                  </div>
                  <div className='dropdown'>
                    <div className={defineOrderStatusClassName(order.orderStatus)}
                         data-toggle='dropdown'
                         aria-expanded='false'>
                      {order.orderStatus.adminValue}
                    </div>
                    <div className='dropdown-menu order-status dropdown-menu-right'>
                      {orderStatusesEnum
                        .filter(orderStatus => orderStatus.adminValue !== order.orderStatus.adminValue)
                        .map((status, index) => (
                          <a
                            key={index}
                            className='dropdown-item'
                            onClick={() => handleOrderStatusChange(order, status, getOrderInfo)}>
                            {status.adminValue}
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='dropdown'>
                    <div className='dropdown-toggle'
                         data-toggle='dropdown'
                         aria-expanded='false'>
                      {order.paymentType.databaseValue === 'CREDIT_CARD' ? (
                        <i className='bi bi-credit-card pr-1' />
                      ) : order.paymentType.databaseValue === 'CASH_ON_DELIVERY' ? (
                        <i className='bi bi-cash-coin pr-1' />
                      ) : null}
                      {order.paymentType.adminValue}
                    </div>
                    <div className='dropdown-menu payment-type'>
                      {paymentTypesEnum
                        .filter(paymentType => paymentType.adminValue !== order.paymentType.adminValue)
                        .map((paymentType, index) => (
                          <a
                            key={index}
                            className='dropdown-item'
                            onClick={() => handlePaymentTypeChange(order, paymentType, getOrderInfo)}>
                            {paymentType.adminValue}
                          </a>
                        ))}
                    </div>
                  </div>
                  <div className='dropdown'>
                    <div className={definePaymentStatusClassName(order.paymentStatus)}
                         data-toggle='dropdown'
                         aria-expanded='false'>
                      {order.paymentStatus.adminValue}
                    </div>
                    <div className='dropdown-menu payment-statuses dropdown-menu-right'>
                      {paymentStatusesEnum
                        .filter(paymentStatus => paymentStatus.adminValue !== order.paymentStatus.adminValue)
                        .map((paymentStatus, index) => (
                          <a
                            key={index}
                            className='dropdown-item'
                            onClick={() => handlePaymentStatusChange(order, paymentStatus, getOrderInfo)}>
                            {paymentStatus.adminValue}
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <span className='text-muted' style={{ fontSize: '0.8rem' }}>{order.createTime}</span>
                </div>
              </div>
              <div className='card-header d-flex justify-content-between align-items-center'>
                <h5 className='card-title font-weight-bold m-0'>Товари в замовленні: </h5>
              </div>
              {order.orderItems !== null && (
                <div className='card-body p-2'>
                  {order.orderItems.map((item, index) => (
                    <OrderItemMobile
                      key={index}
                      item={item}
                      setModalImage={setModalImage}
                      handleEditClick={handleEditProductOrderButtonClick}
                      isLast={index === order.orderItems.length - 1}
                    />
                  ))}
                </div>
              )}
              <div className='card-footer d-flex justify-content-end'>
                <div className='mr-2'>Загальна сума:</div>
                {renderPrice(order)}
              </div>
            </div>

            <div className='card pt-3'>
              <div className='card-header d-flex justify-content-between align-items-center'>
                <h5 className='card-title font-weight-bold m-0'>Дані для доставки: </h5>
                <i className='bi bi-pencil-fill cursor-pointer' data-toggle='modal' data-target='#editDeliveryModal'
                   onClick={() => handleEditDeliveryInfoButtonClick()} />
              </div>
              <div className='card-body'>
                {order.trackingNumber && (
                  <p className='card-text mb-3' style={{ fontSize: '1.25rem' }}>
                    <strong>Номер накладної:</strong>
                    <span className=''>
                          {' '}{order.trackingNumber.slice(0, -4)}
                      <span className='font-weight-bold'>{order.trackingNumber.slice(-4)}</span>
                        </span>
                  </p>
                )}
                <p className='card-text mb-1'><strong>Тип доставки:</strong> {order.userInfo.receiveMethod.description}
                </p>
                <p className='card-text mb-1'><strong>Ім'я:</strong> {order.userInfo.name}</p>
                <p className='card-text mb-1'><strong>Прізвище:</strong> {order.userInfo.surname}</p>
                <p className='card-text mb-1' style={{ cursor: 'pointer' }}
                   onClick={() => copyToClipboard(order.userInfo.phone)}>
                  <strong>Телефон:</strong> {order.userInfo.phone}
                </p>
                {order.userInfo.receiveMethod.id === 1 && (
                  <div>
                    <p className='card-text mb-1'><strong>Місто:</strong> {order.userInfo.city}</p>
                    <p className='card-text mb-1'><strong>Номер відділення /
                      поштомату:</strong> {order.userInfo.departmentNumber}</p>
                  </div>
                )}
                {order.userInfo.receiveMethod.id === 2 && (
                  <div>
                    <p className='card-text mb-1'><strong>Вулиця:</strong> {order.userInfo.street}</p>
                    <p className='card-text mb-1'><strong>Номер будинку:</strong> {order.userInfo.buildingNumber}</p>
                    <p className='card-text mb-1'><strong>Номер квартири:</strong> {order.userInfo.flatNumber}</p>
                  </div>
                )}
                <p className='card-text mb-1'><strong>ID
                  користувача:</strong> {order.userInfo.userId ? order.userInfo.userId : 'Замовлення без авторизації'}
                </p>
                {!order.trackingNumber && order.userInfo.receiveMethod.id !== 3 && (
                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn btn-dark btn-block mt-2'
                      data-toggle='modal'
                      data-target='#selectWeightModal'
                      onClick={() => {
                        setErrorAPIMessage('');
                        setSuccessAPIMessage('');
                      }}
                    >
                      Створити накладну
                    </button>
                  </div>
                )}
                <div className='pt-3'>
                  {renderUserComment()}
                  {renderAdminComment()}
                </div>
                {successAPIMessage &&
                  <div className='alert alert-success mb-0 mt-2' role='alert'>{successAPIMessage}</div>}
                {errorAPIMessage &&
                  <div className='alert alert-danger mb-0 mt-2' role='alert'>{errorAPIMessage}</div>}
              </div>
            </div>
            </div>
          </div>
        )}
      </div>

      <div className='row'>
        <div className='col p-0'>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AdminOrderPage;

import React, { useEffect, useState, useRef } from "react";

import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import Storage from "../Storage";
import { calculatePrice } from "../../utils/utils";
import { validateAll } from "indicative";
import { renderSum } from "../../utils/utils.js";
import "../../styles/common.css";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import {Tooltip, OverlayTrigger} from "react-bootstrap";

import { useBasketStore } from '../../store/Basket';
import { Helmet } from 'react-helmet';
import NovaPostCityInput from '../new/NovaPostCityInput';
import NovaPostDepartmentInput from '../new/NovaPostDepartmentInput';
import NovaPostAddressInput from '../new/NovaPostAddressInput';
import { fetchReceiveMethods } from '../../utils/apiUtils';

const CheckoutPage = props => {
  const products = useBasketStore(state => state.products);
  const clearBasket = useBasketStore(state => state.clearBasket);

  let [token, setToken] = useState("");
  let [isCheckout, setCheckout] = useState(false);
  let [targetOrderId, setTargetOrderId] = useState("");
  let [userId, setUserId] = useState(null);
  let [user, setUser] = useState(null);
  let [name, setName] = useState("");
  let [surname, setSurname] = useState("");
  const [departmentNumber, setDepartmentNumber] = useState("");
  const [novaPostAddressRef, setNovaPostAddressRef] = useState("");

  let [city, setCity] = useState(null);
  const [novaPostDeliveryCityRef, setNovaPostDeliveryCityRef] = useState(""); //uses to search streets
  const [novaPostCityRef, setNovaPostCityRef] = useState("");
  const [street, setStreet] = useState(null);
  const [novaPostStreetRef, setNovaPostStreetRef] = useState("");
  const [buildingNumber, setBuildingNumber] = useState(null);
  const [flatNumber, setFlatNumber] = useState(null);
  let [phone, setPhone] = useState("");
  let [payment, setPayment] = useState("CREDIT_CARD");
  let [userComment, setUserComment] = useState("");
  let [doNotCall, setDoNotCall] = useState(true);
  let [errorMessage, setErrorMessage] = useState("");
  let [checkLength, setCheckLength] = useState(false);
  let [totalPrice, setTotalPrice] = useState(0);
  let [discountedPrice, setDiscountedPrice] = useState(0);
  let [additionalDiscountPercent, setAdditionalDiscountPercent] = useState(0);
  let [priceWithAdditionalDiscount, setPriceWithAdditionalDiscount] = useState(
    0
  );
  let [orderId, setOrderId] = useState(0);
  let [disabled, setDisabled] = useState(false);
  let [adminInformMessage, setAdminInformMessage] = useState("");
  let [adminInformErrorMessage, setAdminInformErrorMessage] = useState("");
  let [discountPercent, setDiscountPercent] = useState("");
  let [userIdentityForDiscount, setUserIdentityForDiscount] = useState("");
  let [promCommissions, setPromCommissions] = useState(Array(products.length).fill(''));
  let [isPromOrder, setIsPromOrder] = useState(false);
  let [forceDiscount, setForceDiscount] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);

  const [receiveMethods, setReceiveMethods] = useState([]);
  const [selectedReceiveMethod, setSelectedReceiveMethod] = useState(null);

  let [errors, setErrors] = useState({
    name: false,
    surname: false,
    city: false,
    phone: false,
    departmentNumber: false,
    street: false,
    buildingNumber: false,
    novaPostStreetRef: false
  });

  let nameRef = useRef();
  let surNameRef = useRef();
  let commentRef = useRef();
  let targetOrderIdRef = useRef();
  let discountPercentRef = useRef();
  let phoneNumberForDiscountRef = useRef();

  let storage = new Storage();

  useEffect(() => {
    localStorage.setItem("currentUrl", window.location.pathname);
    token = storage.get("token");
    setToken(token);
    if (token) {
      axios
        .get("/api/v1/auth/current-user", {
          headers: {
            Authorization: token
          }
        })
        .then(function(res) {
          const user = res.data.data;
          setUser(user);
          setUserId(user.id);
          if (!user.userInfo) {
            return;
          }
          setName(user.userInfo.name);
          setSurname(user.userInfo.surname);
          setPhone(user.userInfo.phone);
          if (user.userInfo.novaPostCityRef) {
            setCity(user.userInfo.city);
            setNovaPostCityRef(user.userInfo.novaPostCityRef);
          }
          if (user.userInfo.novaPostAddressRef) {
            setDepartmentNumber(user.userInfo.departmentNumber);
            setNovaPostAddressRef(user.userInfo.novaPostAddressRef);
          }
        }).catch(function (error) {
        });
    }

    fetchReceiveMethods(token, setReceiveMethods);
  }, []);

  const onChangePhone = e => {
    const phoneFormat = "38" + e;
    const regex = /^\+?3?8?(0\d{9})$/;
    setPhone(e);
    if (regex.test(phoneFormat)) {
      setErrors({ ...errors, phone: false });
    } else {
      setErrors({ ...errors, phone: true });
    }
  };

  const onChangePayment = e => {
    setPayment(e.target.value);
  };

  const handleDoNotCallCheckbox = () => {
    setDoNotCall(!doNotCall);
  }

  const checkout = e => {
    setDisabled(true);
    e.preventDefault();

    const messages = {
      required: "Заповніть, будь ласка, всі обов'язкові поля",
      "phone.min": "Введіть коректний номер телефону",
      "phone.max": "Введіть коректний номер телефону"
    };

    if (!products || products.length === 0) {
      setErrorMessage("Ваш кошик пустий");
      setDisabled(false);
      setCheckLength(true);
      return;
    }

    if (!selectedReceiveMethod) {
      setErrorMessage("Оберіть спосіб отримання");
      setDisabled(false);
      return;
    }

    let rules = {
      name: "required ",
      surname: "required",
      phone: "required | min:10 | max:10",
    };
    if (selectedReceiveMethod.id === 1) {
      rules = {
        ... rules,
        city: "required ",
        departmentNumber: "required"
      };
    } else if (selectedReceiveMethod.id === 2) {
      rules = {
        ... rules,
        city: "required ",
        street: "required ",
        buildingNumber: "required"
      };
      if (!novaPostStreetRef) {
        setErrorMessage("Оберіть вулицю");
        setErrors({ ...errors, novaPostStreetRef: true });
        setDisabled(false);
        return;
      }
    }

    const { totalPrice, discountedPrice } = calculatePrice({products});
    validateAll(
      { name, surname, phone, city, departmentNumber, street, buildingNumber },
      rules,
      messages
    )
      .then(() => {
        if (errors.phone) {
          setErrorMessage("Введіть номер телефону у коректному форматі, наприклад 0953332211");
          setDisabled(false);
          return;
        }
        axios
          .post(
            "/api/v1/order/process",
            {
              receiveMethodId: selectedReceiveMethod.id,
              userId: userId,
              name: name,
              surname: surname,
              city: city || null,
              phone: phone,
              totalPrice: totalPrice,
              discountedPrice: discountedPrice,
              products: products,
              departmentNumber: departmentNumber || null,
              payment: payment,
              userComment: userComment || null,
              doNotCall: doNotCall,
              novaPostCityRef: novaPostCityRef || null,
              novaPostAddressRef: novaPostAddressRef || null,
              street: street || null,
              novaPostStreetRef: novaPostStreetRef || null,
              buildingNumber: buildingNumber || null,
              flatNumber: flatNumber || null
            },
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then(function(res) {
            setCheckout(true);
            clearBasket();
            const processOrderResponse = res.data.data;
            setOrderId(processOrderResponse.orderId);
            let additionalDiscountPercent = processOrderResponse.additionalDiscountPercent;
            if (additionalDiscountPercent > 0) {
              setAdditionalDiscountPercent(processOrderResponse.additionalDiscountPercent);
              setTotalPrice(processOrderResponse.totalPrice);
              setDiscountedPrice(processOrderResponse.discountedPrice);
              setPriceWithAdditionalDiscount(processOrderResponse.priceWithAdditionalDiscount);
            }
            setDisabled(false);
          })
          .catch(function(error) {
            setDisabled(false);
          });
      })
      .catch(errors => {
        console.log("errors = ", errors);
        const formattedErrors = {};
        errors.forEach(error => {
          formattedErrors[error.field] = true;
        });
        setErrors(formattedErrors);
        setErrorMessage(errors[0].message);
        setCheckLength(true);
        setDisabled(false);
      });
  };

  const setDiscount = () => {
    setDisabled(true);
    if (discountPercent === "" && userIdentityForDiscount === "") {
      setAdminInformMessage("");
      setAdminInformErrorMessage("Введіть знижку у відсотках або номер телефону користувача");
      setDisabled(false);
      return;
    }
    if (discountPercent < 0 || discountPercent > 100) {
      setDisabled(false);
      setAdminInformErrorMessage("Знижка повинна бути в діапазоні від 0 до 100");
      setDiscountPercent("");
      return;
    }
    if (discountPercent !== "") {
      const productsWithDiscount = products.map(product =>
        applyDiscount(product, discountPercent)
      );
      useBasketStore.getState().setProducts(productsWithDiscount);

      setAdminInformMessage("Встановлено знижку " + discountPercent + "%");
      setAdminInformErrorMessage("");
      setUserIdentityForDiscount("");
      setDiscountPercent("");
      setDisabled(false);
      return;
    }

    let headers = {};
    if (token) {
      headers = {
        Authorization: token
      };
    }

    axios
      .post('/api/v1/user/apply-discount?userIdentity=' + userIdentityForDiscount,
        products,
        {
          headers: headers,
        })
      .then(function(res) {
        setDisabled(false);
        setAdminInformMessage('Враховано ' + res.data.data.discountInfoDto.level + ' рівень знижки. Вартість товарів успішно перерахована.');
        setUserIdentityForDiscount("");
        setDiscountPercent("");
        setAdminInformErrorMessage("");
        useBasketStore.getState().setProducts(res.data.data.products);
      })
      .catch(function(error) {
        setAdminInformErrorMessage(error.response.data.errorMessage);
        setAdminInformMessage("");
        setDisabled(false);
      });
  };

  const handlePromCommissionChange = (index, value) => {
    const updatedPromCommissions = [...promCommissions];
    updatedPromCommissions[index] = value;
    setPromCommissions(updatedPromCommissions);

    useBasketStore.setState(state => {
      const updatedProducts = [...state.products];
      updatedProducts[index] = { ...updatedProducts[index], promCommission: value };
      return { products: updatedProducts };
    });
  };

  const applyDiscount = (product, discount) => {
    if (discount === "100") {
      const discountedPrice = 1;
      return { ...product, discountedPrice };
    }
    const priceWithDiscount = Math.ceil(
      (product.price * (100 - discount)) / 100
    );
    const discountedPrice = priceWithDiscount < product.discountedPrice ? priceWithDiscount
      : forceDiscount ? priceWithDiscount : product.discountedPrice;
    return { ...product, discountedPrice };
  };

  const addProductsToOrder = () => {
    setDisabled(true);
    if (targetOrderId === "") {
      setAdminInformErrorMessage("Вкажіть номер замовлення");
      setAdminInformMessage("");
      setDisabled(false);
      return;
    }

    let headers = {};
    if (token) {
      headers = {
        Authorization: token
      };
    }

    axios
      .post('/api/v1/order/add-products?targetOrderId=' + targetOrderId,
          products,
        {
          headers: headers,
        })
      .then(function(res) {
        setDisabled(false);
        clearBasket();
        setTargetOrderId('');
        setAdminInformMessage('Товари було успішно додано до замовлення');
        setAdminInformErrorMessage('');
      })
      .catch(function(error) {
        setAdminInformErrorMessage(error.response.data.errorMessage);
        setDisabled(false);
    });
  };

  const handleReceiveMethodChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    const selectedMethod = receiveMethods.find(method => method.id === selectedValue);
    setSelectedReceiveMethod(selectedMethod);
    if (selectedMethod) {
      if (selectedValue === 1) {
        setEmptyAddress();
      } else if (selectedValue === 2) {
        setEmptyCityAndDepartmentNumber();
      } else if (selectedValue === 3) {
        setEmptyCityAndDepartmentNumber();
        setEmptyAddress();
      }
    }
  };

  const setEmptyCityAndDepartmentNumber = () => {
    setCity('');
    setNovaPostCityRef('');
    setNovaPostDeliveryCityRef('');
    setDepartmentNumber('');
    setNovaPostAddressRef('');
  }

  const setEmptyAddress = () => {
    setStreet('');
    setNovaPostStreetRef('');
    setBuildingNumber('');
    setFlatNumber('');
  }

  const doNotCallTooltip = (
      <Tooltip id="tooltip">
        <strong>Замовлення буде відправлено автоматично без додаткового дзвінка. Якщо обрано спосіб оплати "за реквізитами на картку" - реквізити будуть відправлені в SMS.</strong>
      </Tooltip>
  );

  const renderPromCommissionBlock = (index) => {
    if (user !== undefined && user !== null && user.type === 'ADMIN' && isPromOrder) {
      return (
        <div className='row'>
          <div className='col-6'>
            <input
              type='text'
              className='form-control mb-2'
              name={`promCommission_${index}`}
              placeholder='Комісія прому'
              onChange={(e) => handlePromCommissionChange(index, e.target.value)}
              value={promCommissions[index] || ''}
            />
          </div>
        </div>
      );
    }
  };

  const renderDiscountBlock = () => {
    if (user !== undefined && user !== null && user.type === 'ADMIN') {
      return (
        <div className="pt-4">
          <h6 className="font-weight-bold">Застосувати знижку до всього замовлення:</h6>
          <div className="row">
            <div className="col-12">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="forceDiscountCheckbox"
                  checked={forceDiscount}
                  onChange={() => setForceDiscount(!forceDiscount)}
                />
                <label className="form-check-label" htmlFor="forceDiscountCheckbox">
                  Дозволити збільшити вартість (при перерахунку вартість зі знижкою може бути збільшена)
                </label>
              </div>
            </div>
            <div className="col-3">
              <input
                ref={discountPercentRef}
                type="text"
                className="form-control"
                name="discountPercent"
                placeholder="% знижки"
                onChange={() => setDiscountPercent(discountPercentRef.current.value)}
                value={discountPercent}
              />
            </div>
            <div className="col-5">
              <input
                ref={phoneNumberForDiscountRef}
                type="text"
                className="form-control"
                name="phoneNumberForDiscount"
                placeholder="Телефон клієнта"
                onChange={() => setUserIdentityForDiscount(phoneNumberForDiscountRef.current.value)}
                value={userIdentityForDiscount}
              />
            </div>
            <div className="col-4">
              <button
                className="btn btn-success btn-block"
                disabled={disabled}
                onClick={setDiscount}
              >
                Застосувати
              </button>
            </div>
          </div>
        </div>
      )
    }
  };

  const renderAddToOrderBlock = () => {
    if (user !== undefined && user !== null && user.type === 'ADMIN') {
      return (
        <div className="py-2">
          <h6 className="font-weight-bold">Додати до замовлення:</h6>
          <div className="row">
            <div className="col-8">
              <input
                ref={targetOrderIdRef}
                type="text"
                className="form-control"
                name="targetOrderId"
                placeholder="Номер замовлення"
                onChange={() => setTargetOrderId(targetOrderIdRef.current.value)}
                value={targetOrderId}
              />
            </div>
            <div className="col-4">
              <button
                className="btn btn-success btn-block"
                onClick={addProductsToOrder}
                disabled={disabled}
              >
                Додати
              </button>
            </div>
          </div>
        </div>
      )
    }
  };

  const renderPrice = product => {
    if (product.discountedPrice !== product.price) {
      return (
        <span className="price text-muted">
          <strike style={{ "text-decoration": "line-through", color: "red" }}>
            {product.price}
          </strike>{" "}
          {product.discountedPrice}грн
        </span>
      );
    } else {
      return <span className="price text-muted">{product.price}грн</span>;
    }
  };

  const renderMessage = () => {
    if (additionalDiscountPercent > 0) {
      return (
        <div>
          <p className="lead">
            Дякуємо за замовлення.
            <br />
            <b>
              В якості бонусу даруємо{" "}
              <text style={{ color: "red" }}>
                Вам додаткову знижку - {additionalDiscountPercent}%!{" "}
              </text>
            </b>
          </p>

          <div className="row">
            <div className="col"/>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 table pt-2">
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Сума замовлення</th>
                    <th scope="col">Сума зі знижкою</th>
                    <th scope="col">Сума з додатковою знижкою</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{totalPrice}</td>
                    <td>{discountedPrice}</td>
                    <td style={{ color: "red" }}>
                      <b>{priceWithAdditionalDiscount}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col" />
          </div>
          <p>
            <br />
            <b>Номер замовлення - {orderId}</b>. Очікуйте на дзвінок /
            повідомлення від менеджера.
          </p>
        </div>
      );
    } else {
      return (
        <p className="lead">
          Дякуємо за замовлення. Очікуйте на дзвінок / повідомлення від менеджера.
          <br /> <b>Номер замовлення - {orderId}</b>
        </p>
      );
    }
  };

  if (isCheckout) {
    return (
      <div className="container">
        <Helmet>
          <title>Оформлення замовлення</title>
        </Helmet>
        <div className="row">
          <div className="col p-0">
            <Header />
          </div>
        </div>
        <div className="py-5 text-center">
          {renderMessage()}
        </div>
        <button className="btn mr-3">
          <Link className="btn btn-dark" to="/user/personal-cabinet">
            Переглянути мої замовлення
          </Link>
        </button>
        <button className="btn">
          <Link className="btn btn-success" to="/">
            Повернутись на головну
          </Link>
        </button>
      </div>
    );
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col p-0">
            <Header />
          </div>
        </div>
        <div className="pt-3 text-center">
        </div>

        <div className="row">
          <div className="col-md-5 order-md-2 mb-4">
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <span className="text-muted">Кошик товарів</span>

              <span className="badge badge-secondary badge-pill">
                {products.length}
              </span>
            </h4>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="isPromOrderCheckbox"
                checked={isPromOrder}
                onChange={() => setIsPromOrder(!isPromOrder)}
              />
              {(user !== undefined && user !== null && user.type === 'ADMIN') ?
                <label className='form-check-label font-weight-bold' htmlFor='isPromOrderCheckbox'>
                  Пром замовлення
                </label> : null}
            </div>

            <ul className="list-group">
              {products.map((product, index) => {
                return (
                  <div>
                  <li
                    key={index}
                    className="list-group-item d-flex justify-content-between lh-condensed"
                  >
                    <div className="col-6 p-0">
                      <h6 className="my-0">{product.name}</h6>
                    </div>
                    <div className="col-2">
                      <span className="text-muted">{product.quantity}</span>
                    </div>
                    <div className="col-4 text-center">
                      {renderPrice(product)}
                    </div>
                  </li>
                    {renderPromCommissionBlock(index)}
                  </div>
                );
              })}
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div className="col-6 p-0">
                  <span>
                    <strong>Загальна вартість:</strong>
                  </span>
                </div>
                <div className="col-2" />
                <div className="col-4 text-center">{renderSum({products})}</div>
              </li>
            </ul>
            {renderDiscountBlock()}
            {renderAddToOrderBlock()}
            {adminInformMessage !== undefined && adminInformMessage !== "" ? (
              <div className="col-12 alert alert-success text-center pt-2" role="alert">
                {adminInformMessage}
              </div> ) : null }
            {adminInformErrorMessage !== undefined && adminInformErrorMessage !== "" ? (
              <div className="col-12 alert alert-danger text-center pt-2" role="alert">
                {adminInformErrorMessage}
              </div> ) : null }
          </div>

          <div className="col-md-7 order-md-1">
            <h4 className="mb-3">Вкажіть дані для доставки</h4>
            <div className='row'>
              <div className='col-md-12 mb-3'>
                <div className='alert alert-info m-0 p-1' role='alert'>
                  <span className='font-weight-bold'>Спосіб доставки:</span> Наразі доставка здійснюється лише
                  компанією <span className='font-weight-bold'>"Нова Пошта"</span>
                </div>
              </div>

              <div className='col-md-6 mb-3'>
                <label htmlFor='name'>Ім'я</label>
                <input
                  ref={nameRef}
                  type='text'
                  className={!errors.name || (checkLength && name && name.length > 0) ? 'form-control' : 'form-control form-input-fail'}
                  name='name'
                  placeholder="Введіть ім'я отримувача"
                  onChange={() => setName(nameRef.current.value)}
                  value={name || ''}
                />
              </div>

              <div className='col-md-6 mb-3'>
                <label htmlFor='surname'>Прізвище</label>
                <input
                  ref={surNameRef}
                  type='text'
                  className={!errors.surname || (checkLength && surname && surname.length > 0) ? 'form-control' : 'form-control form-input-fail'}
                  name='surname'
                  placeholder='Введіть прізвище отримувача'
                  onChange={() => setSurname(surNameRef.current.value)}
                  value={surname || ''}
                />
              </div>
            </div>

            <div className='mb-1'>
              <label htmlFor='phone'>Номер телефону</label>
              <div className='space-between'>
                <img
                  className='flag'
                  src="https://waaagh-shop.com.ua/nodeapi/images/ua.svg"
                  alt="Ukraine flag"
                />
                <p className="country_code px-1">+38</p>
                <NumberFormat
                  className={
                    !errors.phone
                      ? "form-control"
                      : "form-control form-input-fail"
                  }
                  name="phone"
                  placeholder="Введіть номер телефону"
                  format="(###) ###-####"
                  mask="_"
                  onValueChange={value => onChangePhone(value.value)}
                  value={phone}
                />
              </div>
            </div>

            <div className='form-check mb-3' style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  className='form-check-input mt-0'
                  type='checkbox'
                  onChange={handleDoNotCallCheckbox}
                  id='doNotCallCheckbox'
                  defaultChecked={true}
                  style={{ width: '1rem', height: '1rem' }}
                />
              </div>
              <label className='form-check-label ml-1 mr-1' htmlFor='doNotCallCheckbox'
                     style={{ flexGrow: 1, fontSize: '0.9rem' }}>
                Не телефонувати для підтвердження замовлення
                <OverlayTrigger placement='top' overlay={doNotCallTooltip}>
                  <i className='bi bi-info-circle px-1' />
                </OverlayTrigger>
              </label>
            </div>

            <div className='form-group mb-3'>
              <label htmlFor='receiveMethod'>Спосіб отримання</label>
              <select
                className='form-control'
                id='receiveMethod'
                onChange={handleReceiveMethodChange}
                value={selectedReceiveMethod ? selectedReceiveMethod.id : ''}
              >
                {!selectedReceiveMethod && (
                  <option value='' disabled hidden>
                    Оберіть спосіб отримання
                  </option>
                )}
                {receiveMethods.map(method => (
                  <option key={method.id} value={method.id}>
                    {method.description}
                  </option>
                ))}
              </select>
            </div>

            {selectedReceiveMethod && (
              <div>

                {selectedReceiveMethod.id !== 3 && (
                  <NovaPostCityInput
                    value={city}
                    onCityChange={(suggestion) => {
                      setCity(suggestion.present);
                      setNovaPostCityRef(suggestion.ref);
                      setNovaPostDeliveryCityRef(suggestion.deliveryCity);
                    }}
                    inputClassName={!errors.city || (checkLength && city && city.length > 0) ? 'form-control' : 'form-control form-input-fail'}
                  />
                )}

                {selectedReceiveMethod.id === 1 && (
                  <NovaPostDepartmentInput
                    value={departmentNumber}
                    onDepartmentChange={(suggestion) => {
                      setDepartmentNumber(suggestion.description);
                      setNovaPostAddressRef(suggestion.ref);
                    }}
                    novaPostCityRef={novaPostCityRef}
                  />
                )}

                {selectedReceiveMethod.id === 2 && (
                  <NovaPostAddressInput
                    street={street}
                    buildingNumber={buildingNumber}
                    flatNumber={flatNumber}
                    onStreetChange={(suggestion) => {
                      setStreet(suggestion.street);
                      setNovaPostStreetRef(suggestion.ref);
                    }}
                    onBuildingNumberChange={(buildingNumber) => {
                      setBuildingNumber(buildingNumber);
                    }}
                    onFlatNumberChange={(flatNumber) => {
                      setFlatNumber(flatNumber);
                    }}
                    novaPostDeliveryCityRef={novaPostDeliveryCityRef}
                    streetInputClassName={(!errors.street && !errors.novaPostStreetRef) || (checkLength && novaPostStreetRef && novaPostStreetRef.length > 0) ? 'form-control' : 'form-control form-input-fail'}
                    buildingNumberInputClassName={!errors.buildingNumber || (checkLength && buildingNumber && buildingNumber.length > 0) ? 'form-control' : 'form-control form-input-fail'}
                  />
                )}
              </div>
            )}

            <span
              className='font-weight-bold cursor-pointer'
              onClick={() => setShowCommentInput(!showCommentInput)}
            >
              <i
                className='bi bi-chat-square-text pr-1' />{!showCommentInput ? 'Додати коментар' : 'Залиште коментар нижче:'}
            </span>

            {showCommentInput && (
              <div className='pt-1'>
                <textarea
                  ref={commentRef}
                  name='comment'
                  className='form-control'
                  placeholder='За необхідності залиште коментар до замовлення'
                  onChange={() => setUserComment(commentRef.current.value)}
                  value={userComment}
                  style={{ height: "100px" }}
                />
              </div>
            )}

            <hr className="mb-4" />
            <h4 className="mb-3">Оплата</h4>
            <div className="d-block my-3">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio1"
                  name="customRadio"
                  value="CREDIT_CARD"
                  className="custom-control-input"
                  checked={payment === "CREDIT_CARD"}
                  onChange={onChangePayment}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadio1"
                >
                  Карткою за IBAN реквізитами
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio2"
                  value="CASH_ON_DELIVERY"
                  className="custom-control-input"
                  checked={payment === "CASH_ON_DELIVERY"}
                  onChange={onChangePayment}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadio2"
                >
                  Оплата після отримання у відділенні
                </label>
              </div>
            </div>

            <hr className="mb-4" />
            <button
              className="btn btn-dark btn-lg btn-block"
              type="submit"
              disabled={disabled}
              onClick={checkout}
            >
              Підтвердити
            </button>
            <div className="form-field-error text-center pt-2 font-weight-bold">
              {errorMessage}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col p-0">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
};

export { CheckoutPage };

import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export const useBasketStore = create(
  persist(
    devtools((set, get) => ({
      products: [],
      patchProduct: payload => {
        const products = get().products.map(product => {
          return product.id === payload.id ? payload : product;
        });

        set(() => ({
          products,
        }));
      },

      addProduct: (product, quantity) => {
        const image = product.images !== undefined && product.images !== null
          ? product.images[0].path
          : product.imagePathList[0];
        const maxQuantity = product.quantity === 0 ? 100 : product.quantity;

        let cartProduct = {
          id: product.id,
          image: image,
          name: product.name,
          price: product.price,
          discountedPrice: product.discountedPrice,
          quantity: quantity,
          maxQuantity: maxQuantity,
          dateAdded: Date.now(),
          state: product.state,
          toOrder: product.quantity === 0 //під замовлення чи ні
        };

        set(() => ({ products: [...get().products, cartProduct] }));
      },

      deleteProductById: id => {
        set(state => ({
          ...state,
          products: state.products.filter(product => product.id !== id),
        }));
      },
      deleteProductsByIds: ids => {
        set(state => ({
          ...state,
          products: state.products.filter(product => !ids.includes(product.id)),
        }));
      },
      clearBasket: () => {
          set(state => ({
              ...state,
              products: []
          }));
      },

      handleStorageChange: event => {
        if (event.key === 'cart') {
          const newValue = JSON.parse(event.newValue);
          set({ products: newValue.state.products });
        }
      },

      setProducts: newProducts => {
        set(() => ({ products: newProducts }));
      },
    })),

    {
      name: 'cart', 
      storage: createJSONStorage(() => localStorage), 
    },
  ),
);

window.addEventListener('storage', useBasketStore.getState().handleStorageChange);

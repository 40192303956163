import React, { useEffect, useState } from "react";
import Header from "../Header";
import axios from "axios";
import { checkIsAdminLoggined } from "../../utils/utils.js";
import Gallery from "react-photo-gallery";
import DragPhoto from "../DragPhoto";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const AdminProductsPage = props => {
  let [products, setProducts] = useState([]);
  let [state, setState] = useState("ACTIVE");
  let [innerProducts, setInnerProducts] = useState([]);
  let [selectedProduct, setSelectedProduct] = useState({
    id: "",
    productCode: "",
    name: "",
    description: "",
    price: "",
    quantity: "",
    discount: 0,
    parentId: null,
    priceInPounds: null,
    priceInUSD: null,
    purchasePrice: "",
    categoryIds: [],
    images: []
  });
  let [categories, setCategories] = useState([]);
  let [alertType, setAlertType] = useState("");
  let [inputRefs] = useState([]);
  let [deletedProduct, setDeletedProduct] = useState();
  let [productWasDeleted, setProductWasDeleted] = useState(false);
  let [isInnerProductAdding, setIsInnerProductAdding] = useState(false);
  let [parseImageUrl, setParseImageUrl] = useState('');
  let [deliveredQuantity, setDeliveredQuantity] = useState();
  let [purchasePrice, setPurchasePrice] = useState();
  let [quantityUpdateAlert, setQuantityUpdateAlert] = useState("");

  const setRef = ref => {
    const indexOfRef = inputRefs.indexOf(ref);
    if (indexOfRef === -1 && ref !== null) {
      inputRefs.push(ref);
    }
  };

  useEffect(() => {
    localStorage.setItem("currentUrl", window.location.pathname);
    checkIsAdminLoggined(props);
    fetchProducts();
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [state]);

  const fetchProducts = () => {
    axios.get("/nodeapi/product?state=" + state).then(res => {
      setProducts(res.data);
    });
  };

  const fetchInnerProducts = productId => {
    axios.get("/nodeapi/product/inner/" + productId).then(res => {
      const innerProducts = res.data;
      setInnerProducts([...innerProducts]);
    });
  };

  const fetchCategories = () => {
    axios.get("/nodeapi/category").then(res => {
      setCategories(res.data);
    });
  };

  const handleClick = categoryId => {
    const indexOfCategory = selectedProduct.categoryIds.indexOf(categoryId);
    indexOfCategory === -1
      ? selectedProduct.categoryIds.push(categoryId)
      : selectedProduct.categoryIds.splice(indexOfCategory, 1);

    setSelectedProduct(selectedProduct);
  };

  const addNewProduct = e => {
    e.preventDefault();
    axios.post("/nodeapi/product", {...selectedProduct}).then(function(res) {
      setSelectedProduct({...selectedProduct, id: res.data.productId });
      setAlertType("alert-success");
      fetchProducts();
    });
  };

  const updateProduct = e => {
    e.preventDefault();

    axios.put('/nodeapi/product', {
      ...selectedProduct,
      images: selectedProduct.images.map(image => image.src),
    }).then(function(res) {
      setAlertType('alert-success');
      fetchProducts();
    });
  };

  const addQuantity = (productId, e) => {
    e.preventDefault();

    axios.put('/api/v1/product/' + selectedProduct.id + '/add-quantity?quantity=' + deliveredQuantity + '&purchasePrice=' + purchasePrice, {})
      .then(function(res) {
        setQuantityUpdateAlert('Кількість успішно оновлено');
        setPurchasePrice("");
        setDeliveredQuantity("");
      fetchProducts();
    });
  }

  const nullableState = parentId => {
    setSelectedProduct({
      id: null,
      productCode: "",
      name: "",
      description: "",
      price: "",
      quantity: "",
      discount: 0,
      parentId: parentId,
      priceInPounds: "",
      priceInUSD: "",
      purchasePrice: "",
      categoryIds: [],
      images: []
    });
    inputRefs.forEach(elem => {
      elem.checked = false;
    });
    setInnerProducts([]);
    setAlertType("");
    setIsInnerProductAdding(false);
    setParseImageUrl("");
    setQuantityUpdateAlert("");
  };

  const editParentProduct = product => {
    initializeParentProduct(product);
  };

  const editInnerProduct = innerProduct => {
    initializeProduct(innerProduct);
  };

  const initializeParentProduct = product => {
    initializeProduct(product);
    fetchInnerProducts(product.id);
  };

  const initializeProduct = product => {
    let categoryIds = product.categories.map(category => category.id);
    let images = product.images.map(image => ({
      'src': image.awsName,
      'width': 1,
      'height': 1,
    }));
    inputRefs.forEach(elem => {
      const indexOfCategory = categoryIds.findIndex(
        categoryId => categoryId.toString() === elem.id
      );
      elem.checked = indexOfCategory !== -1;
    });
    setSelectedProduct({
      id: product.id,
      productCode: product.productCode,
      name: product.name,
      description: product.description,
      price: product.price,
      quantity: product.quantity,
      discount: product.discount,
      parentId: product.parentId,
      priceInPounds: product.priceInPounds,
      priceInUSD: product.priceInUSD,
      purchasePrice: product.purchasePrice,
      categoryIds: categoryIds,
      images: images
    });
  };

  const onChangeHandler = event => {
    const data = new FormData();
    for (let x = 0; x < event.target.files.length; x++) {
      data.append("images", event.target.files[x]);
    }
    axios.put("/api/v1/product/" + selectedProduct.id + "/image", data, {}).then(res => {
      addNewImagesFromUpload(res);
    });
  };

  const removeImage = (e, imagePath) => {
    e.preventDefault();

    axios
      .delete(
        "/api/v1/product/" + selectedProduct.id + "/image?imageName=" + imagePath
      )
      .then(res => {
        let imageIndex = selectedProduct.images.findIndex(image => image.src === imagePath);
        selectedProduct.images.splice(imageIndex, 1);
        setSelectedProduct({ ...selectedProduct });
      });
  };

  const deleteProduct = e => {
    e.preventDefault();
    axios
      .put("/nodeapi/product/delete/" + deletedProduct.id, { product: deletedProduct })
      .then(res => {
        setProductWasDeleted(true);
        fetchProducts();
        if (deletedProduct.parentId !== null) {
          fetchInnerProducts(deletedProduct.parentId);
        }
      });
  };

  const returnToParent = parentId => {
    setIsInnerProductAdding(false);
    setAlertType("");
    let product = products.find(product => product.id === parentId);
    initializeParentProduct(product);
  };

  const handleParseImageUrlChange = event => {
    event.preventDefault();
    setParseImageUrl(event.target.value);
  };

  const callParseImagesApi = (event) => {
    event.preventDefault();
    axios.get('/api/v1/product/' + selectedProduct.id + '/image/parse?url=' + parseImageUrl)
      .then(res => {
        addNewImagesFromUpload(res);
      })
      .catch(error => {
        console.log("error = ", error);
      });
  };

  const addNewImagesFromUpload = (res) => {
    let responseImages = res.data.data.uploadedImagesNames.map(path => ({
      'src': path,
      'width': 1,
      'height': 1,
    }));
    let images = selectedProduct.images.concat(responseImages);
    setSelectedProduct({
      ...selectedProduct,
      images: images
    });
  }

  const renderAlert = () => {
    if (alertType === "alert-success") {
      return (
        <div className="alert alert-success text-center" role="alert">
          Продукт було успішно додано / оновлено!
        </div>
      );
    }
  };

  const renderActionButton = () => {
    if (selectedProduct.id !== null) {
      return (
        <button
          type="submit"
          className="btn btn-primary"
          onClick={e => updateProduct(e)}
        >
          Оновити продукт
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          className="btn btn-success"
          onClick={e => addNewProduct(e)}
        >
          Зберегти зміни
        </button>
      );
    }
  };

  const renderDeleteProductButton = () => {
    if (!productWasDeleted) {
      return (
        <button
          type="button"
          className="btn btn-danger"
          onClick={e => deleteProduct(e)}
        >
          Видалити
        </button>
      );
    }
  };

  const SortablePhoto = SortableElement(item => <DragPhoto item={item} onRemoveItem={removeImage}/>);
  const SortableGallery = SortableContainer(({ items }) => (
    <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />} />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSelectedProduct({
      ...selectedProduct,
      images: arrayMove(selectedProduct.images, oldIndex, newIndex)
    });
  };

  return (
    <div className="container">

      <a id="back-to-top" href="#" className="btn btn-light btn-lg back-to-top" role="button"><i
          className="bi bi-caret-up-square-fill"></i></a>

      <div className="row">
        <div className="col p-0">
          <Header />
        </div>
      </div>
      <div className="row">
        <div className="col-10 p-0">
          <ul className="nav nav-pills mb-3" id="state-tab" role="tablist">
            <li className="nav-item">
              <a
                  className="nav-link active"
                  data-toggle="pill"
                  href="#"
                  role="tab"
                  onClick={() => {
                    setState("ACTIVE");
                  }}
              >
                ACTIVE
              </a>
            </li>
            <li className="nav-item">
              <a
                  className="nav-link"
                  data-toggle="pill"
                  href="#"
                  role="tab"
                  onClick={() => {
                    setState("INACTIVE");
                  }}
              >
                INACTIVE
              </a>
            </li>
          </ul>

        </div>
        <div className="col-2 p-0">
          <button
            className="btn btn-success btn-block"
            data-toggle="modal"
            data-target="#productModal"
            data-backdrop="static"
            data-keyboard="false"
            onClick={() => nullableState(null)}
          >
            Додати продукт
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col p-0">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Назва</th>
                <th scope="col">Ціна</th>
                <th scope="col">Кількість</th>
                <th scope="col" width="7%"></th>
                <th scope="col" width="7%"></th>
              </tr>
            </thead>
            <tbody>
              {products.map(product => {
                return (
                  <tr>
                    <th scope="row">{product.id}</th>
                    <td>{product.name}</td>
                    <td>{product.price}</td>
                    <td>{product.quantity}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#productModal"
                        data-backdrop="static"
                        data-keyboard="false"
                        onClick={() => editParentProduct(product)}
                      >
                        Редагувати
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-toggle="modal"
                        data-target="#confirmDeleteModal"
                        onClick={() => {
                          setDeletedProduct(product);
                          setProductWasDeleted(false);
                        }}
                      >
                        Видалити
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="modal fade" tabIndex="-1" role="dialog" id="productModal">
        <form>
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Додавання/перегляд товару</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => nullableState(null)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="name">Назва</label>
                    <input
                      value={selectedProduct.name}
                      onChange={e =>
                        setSelectedProduct({
                          ...selectedProduct,
                          [e.target.name]: e.target.value
                        })
                      }
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Назва товару"
                      required
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="quantity">Ціна за одиницю</label>
                    <input
                      className="form-control"
                      type="text"
                      name="price"
                      placeholder="Ціна за шт."
                      value={selectedProduct.price}
                      onChange={e =>
                        setSelectedProduct({
                          ...selectedProduct,
                          [e.target.name]: e.target.value
                        })
                      }
                      required
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="quantity">Кількість</label>
                    <input
                      className="form-control"
                      type="text"
                      name="quantity"
                      placeholder="Кількість одиниць в наявності"
                      value={selectedProduct.quantity}
                      onChange={e =>
                        setSelectedProduct({
                          ...selectedProduct,
                          [e.target.name]: e.target.value
                        })
                      }
                      required
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="discount">Знижка, %</label>
                    <input
                      className="form-control"
                      type="text"
                      name="discount"
                      placeholder="Знижка на товар"
                      value={selectedProduct.discount}
                      onChange={e =>
                        setSelectedProduct({
                          ...selectedProduct,
                          [e.target.name]: e.target.value
                        })
                      }
                      required
                    />
                  </div>
                </div>

                <div className="row pt-1">
                  <div className="col-4">
                    <label htmlFor="productCode">Артикул</label>
                    <input
                        value={selectedProduct.productCode}
                        onChange={e =>
                            setSelectedProduct({
                              ...selectedProduct,
                              [e.target.name]: e.target.value
                            })
                        }
                        className="form-control"
                        type="text"
                        name="productCode"
                        placeholder="Артикул"
                        required
                    />
                  </div>
                  <div className="col-2">
                  </div>
                  <div className="col-2">
                    <label htmlFor="purchasePrice">Ціна покупки</label>
                    <input
                        className="form-control"
                        type="text"
                        name="purchasePrice"
                        placeholder="Ціна покупки"
                        value={selectedProduct.purchasePrice}
                        onChange={e =>
                            setSelectedProduct({
                              ...selectedProduct,
                              [e.target.name]: e.target.value
                            })
                        }
                        required
                    />
                  </div>
                  <div className="col-2">
                    <label htmlFor="priceInUSD">Ціна в доларах</label>
                    <input
                        value={selectedProduct.priceInUSD}
                        onChange={e =>
                            setSelectedProduct({
                              ...selectedProduct,
                              [e.target.name]: e.target.value
                            })
                        }
                        className="form-control"
                        type="text"
                        name="priceInUSD"
                        placeholder="Ціна в доларах"
                        required
                    />
                  </div>
                  <div className="col-2">
                    <label htmlFor="priceInPounds">Ціна в фунтах</label>
                    <input
                        value={selectedProduct.priceInPounds}
                        onChange={e =>
                            setSelectedProduct({
                              ...selectedProduct,
                              [e.target.name]: e.target.value
                            })
                        }
                        className="form-control"
                        type="text"
                        name="priceInPounds"
                        placeholder="Ціна в фунтах"
                        required
                    />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                  </div>
                  <div className="col-2">
                    <label htmlFor="deliveredQuantity">Кількість в поставці</label>
                    <input
                      value={deliveredQuantity}
                      onChange={e =>
                        setDeliveredQuantity(e.target.value)
                      }
                      className="form-control"
                      type="text"
                      placeholder="Кількість в поставці"
                    />
                  </div>
                  <div className="col-2">
                    <label htmlFor="deliveredQuantity">Ціна в надходженні</label>
                    <input
                      value={purchasePrice}
                      onChange={e =>
                        setPurchasePrice(e.target.value)
                      }
                      className="form-control"
                      type="text"
                      placeholder="Ціна в надходженні"
                    />
                  </div>
                  <div className="col-2 pt-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={e => addQuantity(selectedProduct.id, e)}
                    >
                      Оновити кількість
                    </button>
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-8">
                  </div>
                  <div className="col-4 text-right pt-3">
                    <button
                      type="button"
                      className="btn btn-secondary mr-2"
                      data-dismiss="modal"
                      onClick={() => nullableState(null)}
                    >
                      Закрити
                    </button>
                    {renderActionButton()}
                  </div>
                </div>

                <div className="row">
                  <div className="col">{renderAlert()}</div>
                </div>

                {quantityUpdateAlert !== undefined && quantityUpdateAlert !== '' ? (
                  <div className='alert alert-success text-center mt-2' role='alert'>
                    {quantityUpdateAlert}
                  </div>) : null
                }

                <div className="row py-2">
                  <div className="col">
                    <label htmlFor="description">Комплектація</label>
                    <textarea
                      className="form-control"
                      name="description"
                      placeholder="Введіть повну комплектацію товару"
                      value={selectedProduct.description}
                      onChange={e =>
                        setSelectedProduct({
                          ...selectedProduct,
                          [e.target.name]: e.target.value
                        })
                      }
                      rows={10}
                      required
                    />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col">
                    <label>Категорії:</label>
                  </div>
                </div>
                <div className="row py-2">
                  {categories.map(category => {
                    return (
                      <div className="list-group col-3">
                        <div key={category.id}>
                          <input
                            type="checkbox"
                            name="categoryCheckbox"
                            id={category.id}
                            ref={setRef}
                            onClick={() => handleClick(category.id)}
                          />
                          <label
                            className="list-group-item"
                            htmlFor={category.id}
                          >
                            {category.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="row pt-2">
                  <div className="col">
                    <label>Картинки:</label>
                  </div>
                </div>

                <SortableGallery items={selectedProduct.images} onSortEnd={onSortEnd} axis={"xy"} />

                <div className="row pt-2">
                  <div className="col">
                    <input
                      type="file"
                      className="form-control"
                      multiple
                      onChange={event => onChangeHandler(event)}
                    />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      value={parseImageUrl}
                      onChange={handleParseImageUrlChange}
                      placeholder="Посилання для парсингу картинок"
                    />
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-primary"
                      onClick={e => callParseImagesApi(e)}
                    >
                      Спарсити
                    </button>
                  </div>
                </div>

                {selectedProduct.parentId === null && !isInnerProductAdding ? (
                  <div>
                    <div className="row pt-4">
                      <div className="col">
                        <h3>Внутрішні продукти:</h3>
                      </div>
                    </div>

                    <div className="row pt-2">
                      <div className="col">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Id</th>
                              <th scope="col">Назва</th>
                              <th scope="col">Ціна</th>
                              <th scope="col">Кількість</th>
                              <th scope="col" width="7%"></th>
                              <th scope="col" width="7%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {innerProducts.map(innerProduct => {
                              return (
                                <tr>
                                  <th scope="row">{innerProduct.id}</th>
                                  <td>{innerProduct.name}</td>
                                  <td>{innerProduct.price}</td>
                                  <td>{innerProduct.quantity}</td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() =>
                                        editInnerProduct(innerProduct)
                                      }
                                    >
                                      Редагувати
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      data-toggle="modal"
                                      data-target="#confirmDeleteModal"
                                      onClick={() => {
                                        setDeletedProduct(innerProduct);
                                        setProductWasDeleted(false);
                                      }}
                                    >
                                      Видалити
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <button
                          className="btn btn-success mr-auto"
                          onClick={() => {
                            nullableState(selectedProduct.id);
                            setIsInnerProductAdding(true);
                          }}
                        >
                          Додати внутрішній продукт
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="row">
                <div className="col">{renderAlert()}</div>
              </div>
              <div className="modal-footer">
                {selectedProduct.parentId !== null ? (
                  <button
                    type="button"
                    className="btn btn-primary mr-auto"
                    onClick={() => returnToParent(selectedProduct.parentId)}
                  >
                    Повернутись назад
                  </button>
                ) : (
                  <div></div>
                )}
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => nullableState(null)}
                >
                  Закрити
                </button>
                {renderActionButton()}
              </div>
            </div>
          </div>
        </form>
      </div>

      <div
        className="modal"
        tabIndex="-1"
        role="dialog"
        id="confirmDeleteModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Видалення продукту</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Ви дійсно хочете видалити цей продукт? Дію неможливо відмінити
              </p>
              {productWasDeleted ? (
                <div className="alert alert-success text-center" role="alert">
                  Продукт було успішно видалено!
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="modal-footer">
              {renderDeleteProductButton()}
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
              >
                Закрити
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProductsPage;

import React, {useEffect, useState} from "react";
import Header from "../Header";
import axios from "axios";
import Basket from "../Basket";

import {useBasketStore} from "../../store/Basket";
import Footer from '../Footer';
import { BASE_URL, incrementProductViewCount } from '../../shared/constants.js';
import Storage from '../Storage';

const DerebanProductPage = props => {
  const [productIdsInCart, setProductIdsInCart] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const Toggle = () => setShowModal(!showModal);
  const [products, setProducts] = useState([]);
  const [mainProduct, setMainProduct] = useState([]);

  const cartProducts = useBasketStore(state => state.products);
  const addProductToCart = useBasketStore(state => state.addProduct);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  useEffect(() => {
    const productIdsInCart = cartProducts.map(product => product.id);
    setProductIdsInCart(productIdsInCart);
  }, [cartProducts]);


  const renderBuyButton = (product) => {
    if (product.quantity === 0) {
      return (
        <button type="button" className="btn btn-warning btn-block" disabled>
          Немає в наявності
        </button>
      );
    }
    if (productIdsInCart == null || !productIdsInCart.includes(product.id)) {
      return (
        <button
          type="button"
          onClick={() => addProductToCart(product, 1)}
          className="btn btn-secondary btn-block"
        >
          Додати в кошик
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-dereban btn-block"
          onClick={() => Toggle()}
        >
          Оформити
        </button>
      );
    }
  }

  const fetchAllProducts= () => {
    const productId = props.match.params.id;
    incrementProductViewCount(productId);
    const token = new Storage().get('token');
    axios.get("/api/v1/product/" + productId + "/inner", {
      headers: {
        Authorization: token,
      },
    }).then(res => {
      const products = res.data.data;
      const mainProduct = products.find(function(product) {
        return product.parentId == null;
      });
      setMainProduct(mainProduct);
      let index = products.indexOf(mainProduct);
      if (index !== -1) {
        products.splice(index, 1);
      }
      setProducts(products);
    });
  }

  const renderInnerImage = (product) => {
    if (product.imagePathList[0]) {
      return (
        <img
          className="inner-product-image"
          src={BASE_URL + "/api/v1/product/image/" + product.imagePathList[0]}
        />
      );
    } else {
      return <div></div>;
    }
  }

  const renderMainProduct = () => {
    if (Object.entries(mainProduct).length !== 0) {
      return (
        <div className="row">
          <div className="col p-0">
            <div className="parent-product pt-3">
              <div className="row">
                <div className="col-12">
                  <h3 className="text-center">
                    {mainProduct.name}
                  </h3>
                </div>
                <div className="col-xl-4 col-lg-4 pt-1">
                  {renderImage(mainProduct)}
                  <div className="hidden-md-sm">
                    {renderPrice(mainProduct)}
                    {renderBuyButton(mainProduct)}
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 pt-1">
                  <i>
                    На цій сторінці Ви можете купити цілу коробку (якщо вона є в
                    наявності) або ж купити окремо будь-яку її частину.
                    Мініатюри продаються на литниках, в не зібраному вигляді.
                    Більш детальна інформація про кожний окремий загін з коробки
                    вказана нижче.
                    <br />
                    <a
                      href="https://t.me/waaaghShop"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Підписуйтесь на наш телеграм канал, щоб бути
                      проінформованим про всі події, знижки, акції, бонусні
                      пропозиції та розіграші:
                      <br/><b>t.me/waaaghShop</b>
                    </a>
                    <br/>
                  </i>
                  {mainProduct.description.length > 0 && (
                    <div>
                      <br />
                      <b>Комплектація:</b> <br />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: mainProduct.description
                        }}
                      />
                    </div>
                  )}
                  <div className="hidden-lx-lg pt-3">
                    {renderPrice(mainProduct)}
                    {renderBuyButton(mainProduct)}
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  const renderImage = (mainProduct) => {
    return (
      <div>
        <img
          className="parentImage"
          src={BASE_URL + "/api/v1/product/image/" +  mainProduct.imagePathList[0]}
        />
      </div>
    );
  }

  const renderPrice = (product) => {
    if (product.discount > 0) {
      let discountedPrice = Math.ceil(
        (product.price * (100 - product.discount)) / 100
      );
      return (
        <h5 className="price text-center">
          <strike style={{ "text-decoration": "line-through", color: "red" }}>
            {product.price}
          </strike>{" "}
          {discountedPrice}грн
        </h5>
      );
    } else {
      return <h5 className="price text-center">{product.price} грн</h5>;
    }
  }

    return (
      <div className="container">
        <div className="row">
          <div className="col p-0">
            <Header />
          </div>
        </div>
        {renderMainProduct()}

        <div className="row">
          <div className="col p-0">
            <div className="inner-products pb-5">
              <div className="row">
                {products.map((product, i) => {
                  return (
                    <div
                      className="col-xl-6 col-lg-6 col-md-12 col-12 pb-4"
                      key={product.id + product.name}
                    >
                      <div className="inner-product">
                        <div className="row">
                          <div className="col p-0">
                            <div className="inner-product-name pb-2">
                              <strong>{product.name}</strong>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-12 col-12 d-flex align-items-center justify-content-center">
                            <div className="inner-product-image-block">
                              {renderInnerImage(product)}
                              {renderPrice(product)}
                            </div>
                          </div>

                          <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                            {product.description.length > 0 && (
                              <div className="inner-product-description">
                                <b>Комплектація:</b>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: product.description
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-12 col-12 pt-2">
                            {renderBuyButton(product)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col p-0">
            <Footer />
          </div>
        </div>

        {<Basket showModal={showModal} close={Toggle} /> }
      </div>
    );
}

export default DerebanProductPage;

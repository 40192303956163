import React, {useState} from "react";
import Header from "../Header";
import GeneralCategoryList from "./GeneralCategoryList";
import GeneralProductList from "./GeneralProductList";
import "../../styles/common.css";
import Footer from '../Footer';

const GeneralProductsPage = props => {
    let [categoryClass, setCategoryClass] = useState("categories col-lx-3 col-lg-3");
    const def = "col-xl-9 col-lg-9 col-md-12 col-12";
    let [visible, setVisible] = useState(def);
    const showCategory = () => {
        let className = visible + " nonVisible";
        setVisible(className);
        setCategoryClass("categories-visible");
    };

    const hideCategory = () => {
        setVisible(def);
        setCategoryClass("categories col-lx-3 col-lg-3");
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col p-0">
                    <Header />
                </div>
            </div>
            <div className="row content">
                <div className={visible}>
                    <GeneralProductList
                        showCategory={() => showCategory()}
                    />
                </div>

                <div className={categoryClass}>
                    <GeneralCategoryList
                        confirm={() => hideCategory()}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col p-0">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default GeneralProductsPage;

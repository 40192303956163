import React, { useState, useEffect } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Header from "../Header";
import { checkIsAdminLoggined } from "../../utils/utils.js";
import axios from "axios";

const AdminUsersPage = props => {
  let [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get("/nodeapi/user")
      .then(res => {
        setUsers(res.data);
      });
    checkIsAdminLoggined(props);
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col p-0">
          <Header />
        </div>
      </div>
      <div className="row">
        <div className="col p-0">
          <BootstrapTable data={users} version="4" pagination search>
            <TableHeaderColumn dataField="id" dataSort={true} isKey>
              Id
            </TableHeaderColumn>
            <TableHeaderColumn dataField="login" dataSort={true}>
              Логін
            </TableHeaderColumn>
            <TableHeaderColumn dataField="email" dataSort={true}>
              Імейл адрес
            </TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataSort={true}>
              Ім'я
            </TableHeaderColumn>
            <TableHeaderColumn dataField="surname" dataSort={true}>
              Прізвище
            </TableHeaderColumn>
            <TableHeaderColumn dataField="registration_date" dataSort={true}>
              Дата реєстрації
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    </div>
  );
};

export default AdminUsersPage;

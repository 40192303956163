import React, { useState } from 'react';
import axios from 'axios';

const NovaPostAddressInput = ({
                                street,
                                buildingNumber, flatNumber,
                                onStreetChange,
                                onBuildingNumberChange,
                                onFlatNumberChange,
                                novaPostDeliveryCityRef,
                                streetInputClassName,
                                buildingNumberInputClassName
                              }) => {
  const [streetSuggestions, setStreetSuggestions] = useState([]);
  const [showStreetSuggestions, setShowStreetSuggestions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const searchStreets = (query) => {
    axios
      .post('https://api.novaposhta.ua/v2.0/json/', {
        apiKey: 'bea79947492a4e12621ac03cba5d8893',
        modelName: 'AddressGeneral',
        calledMethod: 'getStreet',
        methodProperties: {
          FindByString: query,
          CityRef: novaPostDeliveryCityRef,
        },
      })
      .then((response) => {
        if (response.data.success) {
          const streets = response.data.data.map((street) => ({
            present: `${street.StreetsType} ${street.Description}`,
            ref: street.Ref,
          }));
          setStreetSuggestions(streets);
        }
      })
      .catch((error) => {
        console.error('Error fetching streets:', error);
      });
  };

  const handleStreetChange = (e) => {
    const inputValue = e.target.value;
    onStreetChange({ street: inputValue });
    if (inputValue.length >= 2) {
      searchStreets(inputValue);
      setShowStreetSuggestions(true);
    } else {
      setShowStreetSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    onStreetChange({ street: suggestion.present, ref: suggestion.ref});
    setShowStreetSuggestions(false);
  };

  const handleBuildingNumberChange = (e) => {
    const buildingNumber = e.target.value;
    onBuildingNumberChange(buildingNumber);
  };

  const handleFlatNumberChange = (e) => {
    const flatNumber = e.target.value;
    onFlatNumberChange(flatNumber);
  };

  return (
    <div className='mb-3'>
      <div className='row'>
        <div className='col-8 pr-1'>
          <input
            type='search'
            className={streetInputClassName ? streetInputClassName : 'form-control'}
            name='street'
            placeholder={!novaPostDeliveryCityRef ? 'Спочатку оберіть місто' : 'Введіть назву вулиці'}
            onChange={handleStreetChange}
            value={street || ''}
            autoComplete='new-password'
            disabled={!novaPostDeliveryCityRef}
          />
          {showStreetSuggestions && (
            <ul className='list-group' style={{ maxHeight: '200px', overflowY: 'auto' }}>
              {streetSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className='list-group-item'
                  onClick={() => handleSuggestionClick(suggestion)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e9ecef'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#fff'}
                >
                  {suggestion.present}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className='col-2 px-1'>
          <input
            type='text'
            className={buildingNumberInputClassName ? buildingNumberInputClassName : 'form-control'}
            name='house'
            placeholder='Будинок'
            onChange={handleBuildingNumberChange}
            value={buildingNumber || ''}
            autoComplete='new-password'
            disabled={!novaPostDeliveryCityRef}
          />
        </div>
        <div className='col-2 pl-1'>
          <input
            type='text'
            className='form-control'
            name='apartment'
            placeholder='Квартира'
            onChange={handleFlatNumberChange}
            value={flatNumber || ''}
            autoComplete='new-password'
            disabled={!novaPostDeliveryCityRef}
          />
        </div>
      </div>
    </div>
  );
};

export default NovaPostAddressInput;

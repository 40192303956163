import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const NovaPostDepartmentInput = ({ value, onDepartmentChange: onStreetChange, novaPostCityRef }) => {
  const [departmentSuggestions, setDepartmentSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const departmentRef = useRef();
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (novaPostCityRef) {
      setIsLoading(true);
      searchDepartments('');
    }
  }, [novaPostCityRef]);

  useEffect(() => {
    if (departmentSuggestions.length > 0) {
      const isThisCityDepartment = departmentSuggestions.find(department => department.description === value);
      if (!isThisCityDepartment) {
        onStreetChange(departmentSuggestions[0]);
      }
    }
  }, [departmentSuggestions]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowInput(false);
    }
  };

  const searchDepartments = (query) => {
    axios
      .post('https://api.novaposhta.ua/v2.0/json/', {
        apiKey: 'bea79947492a4e12621ac03cba5d8893',
        modelName: 'Address',
        calledMethod: 'getWarehouses',
        methodProperties: {
          SettlementRef: novaPostCityRef,
          FindByString: query,
        },
      })
      .then((response) => {
        if (response.data.success) {
          const departments = response.data.data.map((department) => ({
            description: department.Description,
            ref: department.Ref,
          }));
          setDepartmentSuggestions(departments);
          setFilteredSuggestions(departments);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
        setIsLoading(false);
      });
  };

  const handleDepartmentChange = (e) => {
    const inputValue = e.target.value;
    const filteredSuggestions = departmentSuggestions.filter(department =>
      department.description.toLowerCase().includes(inputValue.toLowerCase()),
    );
    setFilteredSuggestions(filteredSuggestions);
  };

  const handleDepartmentSuggestionClick = (suggestion) => {
    onStreetChange(suggestion);
    setShowInput(false);
  };

  return (
    <div className='mb-3' ref={wrapperRef}>
      <label htmlFor='departmentNumber'>Номер відділення / поштомату</label>
      <div className='dropdown' onClick={() => !isLoading && setShowInput(!showInput && novaPostCityRef)}>
        <button
          className='form-control text-left overflow-auto'
          type='button'
          style={{
            cursor: 'pointer',
            backgroundColor: novaPostCityRef ? '#fff' : '#e9ecef',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            height: 'auto'
          }}
          disabled={!novaPostCityRef || isLoading}
        >
          {isLoading ? 'Завантаження...' : (value || 'Спочатку оберіть місто')}
          <span className='float-right'>
            <i className='bi bi-chevron-down'></i>
          </span>
        </button>
      </div>
      {showInput && (
        <div>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <span className='input-group-text'><i className='bi bi-search'></i></span>
            </div>
            <input
              ref={departmentRef}
              type='search'
              className='form-control'
              placeholder='Пошук відділення чи поштомату'
              onChange={handleDepartmentChange}
              autoComplete='new-password'
            />
          </div>

          <ul className='list-group' style={{ maxHeight: '200px', overflowY: 'auto', fontSize: '0.9rem' }}>
            {filteredSuggestions.map((suggestion, index) => (
              <li
                key={index}
                className='list-group-item'
                onClick={() => handleDepartmentSuggestionClick(suggestion)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e9ecef'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#fff'}
              >
                {suggestion.description}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NovaPostDepartmentInput;

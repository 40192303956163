import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { BsDashCircleFill, BsPlusCircleFill } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import '../../styles/common.css';
import queryString from 'query-string'
import { scrollPageToTop } from '../../shared/constants';

function GeneralCategoryList(props) {
  const history = useHistory();
  let inpRef = useRef([]);
  let [openedCategoryIds, setOpenedCategoryIds] = useState([]);
  let [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  let [categories, setCategories] = useState([]);

  const { search }  = useLocation();
  const queryParams = queryString.parse(search);

  useEffect(() => {
    setSelectedCategoryIds([]);
    axios.get('/nodeapi/category').then(res => {
      const categories = res.data;
      setCategories(categories);

      if (queryParams.categoryIds === undefined || queryParams.categoryIds === '') {
        return;
      }
      let categoryIds = queryParams.categoryIds.split(',');
      initializeCategories(categoryIds);
      inpRef.current.forEach(elem => {
        elem.checked = categoryIds.includes(elem.id);
      });
    });
  }, []);

  useEffect(() => {
    if (queryParams.categoryIds === undefined || queryParams.categoryIds === '') {
      setSelectedCategoryIds([]);
      inpRef.current.forEach(elem => {
        elem.checked = false;
      });
      return;
    }
    let categoryIds = queryParams.categoryIds.split(',');
    initializeCategories(categoryIds);
    inpRef.current.forEach(elem => {
      elem.checked = categoryIds.includes(elem.id);
    });
  }, [queryParams.categoryIds]);

  const initializeCategories = categoryIds => {
    selectedCategoryIds = [];
    categoryIds.forEach(categoryId => {
      let categoryIdInt = parseInt(categoryId);
      const indexOfCategory = selectedCategoryIds.indexOf(categoryIdInt);
      if (indexOfCategory === -1) {
        selectedCategoryIds.push(categoryIdInt);
      }
    });
    setSelectedCategoryIds(selectedCategoryIds);
  };

  const addCategory = category => {
    const indexOfCategory = selectedCategoryIds.indexOf(category.id);
    indexOfCategory === -1
      ? selectedCategoryIds.push(category.id)
      : selectedCategoryIds.splice(indexOfCategory, 1);

    const parentId = category.parentId;
    const parrentIdIdex = selectedCategoryIds.indexOf(parentId);
    if (parrentIdIdex !== -1) selectedCategoryIds.splice(parrentIdIdex, 1);
  };

  const changeOpenedCategories = categoryId => {
    const indexOfCategory = openedCategoryIds.indexOf(categoryId);
    const newArray = [...openedCategoryIds];
    indexOfCategory === -1 ? newArray.push(categoryId) : newArray.splice(indexOfCategory, 1);
    setOpenedCategoryIds(newArray);
  };

  const handleClick = categoryId => {
    addCategory(categoryId);
    history.push("/boxes?categoryIds=" + selectedCategoryIds);
  };

  const clearFilters = () => {
    inpRef.current.forEach(elem => {
      elem.checked = false;
    });
    setSelectedCategoryIds([]);
    history.push('./');
  };

  const confirm = () => {
    scrollPageToTop();
    props.confirm();
  };

  const renderCategoryWithInner = category => {
    return (
      <div key={category.id} className="pb-1">
        <div className='plusMinusIcon pl-2'>{defineIcon(category.id)}</div>
        <div>
          <input
            type='checkbox'
            name='categoryCheckbox'
            ref={element => (inpRef.current[category.id] = element)}
            id={category.id}
            onClick={() => handleClick(category)}
          />
          <label
            className='list-group-item mb-0'
            htmlFor={category.id}
            dangerouslySetInnerHTML={{ __html: category.name }}
          ></label>
        </div>

        <Collapse isOpened={openedCategoryIds.includes(category.id)}>
          {categories
            .filter(innerCategory => innerCategory.parentId === category.id)
            .map(innerCategory => {
              return (
                <div className='pl-2 pr-2' key={innerCategory.id}>
                  <input
                    type='checkbox'
                    name='categoryCheckbox'
                    ref={element => (inpRef.current[innerCategory.id] = element)}
                    id={innerCategory.id}
                    onClick={() => handleClick(innerCategory)}
                  />
                  <label className='list-group-item mb-0' htmlFor={innerCategory.id}>
                    {innerCategory.name}
                  </label>
                </div>
              );
            })}
        </Collapse>
      </div>
    );
  };

  const defineIcon = categoryId => {
    if (openedCategoryIds.includes(categoryId)) {
      return <BsDashCircleFill onClick={() => changeOpenedCategories(categoryId)} />;
    } else {
      return <BsPlusCircleFill onClick={() => changeOpenedCategories(categoryId)} />;
    }
  };

  const renderCategoryWithoutInner = category => {
    return (
      <div key={category.id} className="pb-1">
        <input
          type='checkbox'
          name='categoryCheckbox'
          ref={element => (inpRef.current[category.id] = element)}
          id={category.id}
          onClick={() => handleClick(category)}
        />
        <label
          className='list-group-item mb-0'
          htmlFor={category.id}
          dangerouslySetInnerHTML={{ __html: category.name }}
        ></label>
      </div>
    );
  };

  return (
    <div className='category'>
      <div className='category-name text-center py-3'>Пошук по категоріях:</div>
      <div className='list-group'>
        {categories
          .filter(category => category.parentId === null)
          .map((category, i) => {
            if (categories.some(innerCategory => innerCategory.parentId === category.id)) {
              return renderCategoryWithInner(category);
            } else {
              return renderCategoryWithoutInner(category);
            }
          })}
      </div>

      <button className='btn btn-secondary btn-block mt-1' onClick={confirm}>
        Підтвердити
      </button>

      <button className='btn btn-secondary btn-block filter' onClick={clearFilters}>
        Скинути фільтр
      </button>
    </div>
  );
}

export default GeneralCategoryList;

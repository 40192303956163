import React from 'react';
import { Link } from 'react-router-dom';
import { scrollPageToTop } from '../shared/constants';

const Footer = () => {

  return (
    <footer className='py-5 py-md-4 mt-3 bg-light'>
      <div className='container py-4 py-md-4 px-3 px-md-3'>
        <div className='row'>
          <div className='col-6 col-lg-3 mb-3'>
            <h6 className='pb-3'><strong>Інформація для клієнтів</strong></h6>
            <ul className='list-unstyled'>
              <li className='mb-2'><Link className='text-secondary' to="/info/discount-system">Накопичувальна система знижок</Link></li>
              <li className='mb-2'><Link className='text-secondary' to="/info/payment-delivery">Оплата і доставка</Link></li>
              <li className='mb-2'><Link className='text-secondary' to="/info/orders-return">Повернення товару</Link></li>
            </ul>
          </div>
          <div className='col-6 col-lg-3 mb-3'>
            <h6 className='pb-3'><strong>Актуальний асортимент</strong></h6>
            <ul className='list-unstyled'>
              <li className='mb-2'>
                <Link className='text-secondary' to='/pre-order'>
                  Передзамовлення
                </Link>
              </li>
              <li className='mb-2'>
                <Link className='text-secondary' onClick={scrollPageToTop} to='/boxes/60'>
                  Стартові набори
                </Link>
              </li>
              <li className='mb-2'>
                <Link className='text-secondary' onClick={scrollPageToTop} to='/boxes/53'>
                  Warhammer 40 000
                </Link>
              </li>
              <li className='mb-2'>
                <Link className='text-secondary' onClick={scrollPageToTop} to='/boxes/54'>
                  Age of Sigmar
                </Link>
              </li>
              <li className='mb-2'>
                <Link className='text-secondary' onClick={scrollPageToTop} to='/boxes/62'>
                  Фарби та аксесуари
                </Link>
              </li>
              <li className='mb-2'>
                <Link className='text-secondary' onClick={scrollPageToTop} to='/boxes/63'>
                  Webstore Exclusive
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-12 col-lg-3 mb-3'>
            <h6 className='pb-3'><strong>Контактна інформація</strong></h6>
            <ul className='list-unstyled'>
              <li className='mb-1'>
                <span className='small'>
                  <i className='bi bi-envelope pr-1'></i>
                  waaagh.shop@gmail.com
                </span>
              </li>
              <li className='mb-1'>
                <span>
                  <i className='bi bi-telephone-fill pr-1'></i>
                  093-553-5116
                </span>
              </li>
              <li className='mb-1'>
                <a
                  href='https://t.me/waaaghShopAdmin' target='_blank' rel='noopener noreferrer'>
                  <i className='bi bi-telegram pr-1'></i>
                  @waaaghShopAdmin
                </a>
              </li>
              <li className='mb-1'>
                <a
                  href='https://t.me/waaaghShop' target='_blank' rel='noopener noreferrer'>
                  <i className='bi bi-telegram pr-1'></i>
                  Канал магазину в телеграм
                </a>
              </li>
            </ul>
          </div>

          <div className='col-lg-3 mb-3'>
            <h4>
              <Link className='link-black' onClick={scrollPageToTop} to='/boxes/'>
                <strong>Waaagh Shop </strong>
              </Link>
            </h4>
            <ul className='list-unstyled small text-muted'>
              <li className='mb-2'>Працюємо щоденно, щоб наші клієнти завжди залишались задоволені
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { checkIfUserIsLoggined } from "../../utils/utils.js";
import Header from "../Header";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

const UserPersonalCabinetPage = props => {
  let [orders, setOrders] = useState([]);
  let [selectedOrder, setSelectedOrder] = useState({});
  let [discountLevelsInfo, setDiscountLevelsInfo] = useState(undefined);
  let [user, setUser] = useState({});
  let [isDiscountInfoLoaded, setIsDiscountInfoLoaded] = useState(false);

  useEffect(() => {
    localStorage.setItem('currentUrl', window.location.pathname);
    checkIfUserIsLoggined(props);
    const token = localStorage.getItem("token");
    axios
      .get("/api/v1/user/order", {
        headers: {
          Authorization: token
        }
      })
      .then(res => {
        setOrders(res.data.data);
      })
      .catch(() => {
        props.history.push("/login");
      });
  }, []);

  const loadDiscounts = () => {
    if (isDiscountInfoLoaded) {
      return;
    }
    axios
      .get('/api/v1/user/discount-level')
      .then(res => {
        const discountLevelsInfo = res.data.data;
        setDiscountLevelsInfo(discountLevelsInfo);

        const token = localStorage.getItem("token");
        if (!token) {
          props.history.push("/login");
          return;
        }
        axios
          .get("/api/v1/auth/current-user?getAdditionalDiscountInfo=true", {
            headers: {
              Authorization: token
            }
          })
          .then(res => {
            setUser(res.data.data);
            setIsDiscountInfoLoaded(true);
          })
          .catch(() => {
            props.history.push("/login");
          });
      });
  };

  const defineClassName = (discountLevelInfo) => {
    if (discountLevelInfo.level <= user.discountLevel) {
      return "bi bi-check-circle-fill green-check-circle";
    } else {
      return "bi bi-lock-fill";
    }
  };

  const onClickExpand = (event, order) => {
    event.preventDefault();
    setSelectedOrder(order);
  };

  const defineOrderStatusColumnClassName = orderStatus => {
    if (orderStatus === "Очікує на обробку") {
      return "bg-info font-weight-bold";
    } else if (
      orderStatus === "Підтверджено, упаковується" ||
      orderStatus === "Передзамовлення" ||
      orderStatus === "Відкладено"
    ) {
      return "bg-success font-weight-bold";
    } else if (orderStatus === "Очікує на відправлення") {
      return "bg-warning";
    }
  };

  const defineOrderPaymentStatusColumnClassName = paymentStatus => {
    return paymentStatus === "Оплачений" ||
      paymentStatus === "Оплата при отриманні"
      ? "bg-success"
      : "bg-warning";
  };

  const renderProgressBar = (discountLevelInfo) => {
    if (user.additionalDiscountInfo === undefined) {
      return;
    }
    if (discountLevelInfo.level === user.additionalDiscountInfo.maxDiscountLevel.level && user.discountLevel === discountLevelInfo.level) {
      return (
        <p>Не зупиняйтесь, наступні рівні в процесі розробки :)</p>
      )
    } else if (discountLevelInfo.level < user.discountLevel) {
      return (
        <div className='progress'>
          <div
            className='progress-bar bg-success'
            role='progressbar'
            style={{
              width: '100%',
            }}>
            100%
          </div>
        </div>);
    } else if (discountLevelInfo.level === user.discountLevel) {
      return (
        <div>
          <h6><b>До наступного рівня: {user.additionalDiscountInfo.amountLeftToNextLevel}грн</b></h6>
          <div className='progress'>
            <div
              className='progress-bar bg-dark'
              role='progressbar'
              style={{
                width: user.additionalDiscountInfo.earnedPercentOnThisLevel + '%',
              }}>
              {user.additionalDiscountInfo.totalOrdersAmount} / {user.additionalDiscountInfo.totalOrdersAmount + user.additionalDiscountInfo.amountLeftToNextLevel} ({user.additionalDiscountInfo.earnedPercentOnThisLevel + '%'})
            </div>
          </div>
        </div>);
    }
  }

  const renderModal = () => {
    if (selectedOrder.orderId) {
      return (
        <div
          className="modal"
          id="orderDetailModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Деталі замовлення #{selectedOrder.orderId}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Назва</th>
                        <th scope="col">Кількість</th>
                        <th scope="col">Ціна</th>
                        <th scope="col">Ціна зі знижкою</th>
                      </tr>
                    </thead>

                    {selectedOrder.orderItems.map(orderItem => {
                      return (
                        <tbody>
                          <td>{orderItem.name}</td>
                          <td>{orderItem.quantity}</td>
                          <td>{orderItem.price}</td>
                          <td>{orderItem.discountedPrice}</td>
                        </tbody>
                      );
                    })}
                  </table>
                  {selectedOrder.userInfo && selectedOrder.userInfo.receiveMethod && (
                    <div>
                      <div>
                        <strong>Тип доставки:</strong> <span>{selectedOrder.userInfo.receiveMethod.description}</span>
                      </div>
                      {selectedOrder.userInfo.receiveMethod.id !== 3 && (
                        <div>
                          {selectedOrder.userInfo.receiveMethod.id === 1 && (
                            <div>
                              <strong>Дані для доставки:</strong> {selectedOrder.userInfo.city}, відділення / поштомат: {selectedOrder.userInfo.departmentNumber}
                            </div>
                          )}
                          {selectedOrder.userInfo.receiveMethod.id === 2 && (
                            <div>
                              <strong>Дані для доставки:</strong> {selectedOrder.userInfo.city}, вулиця: {selectedOrder.userInfo.street},
                              номер будинку: {selectedOrder.userInfo.buildingNumber}
                              {selectedOrder.userInfo.flatNumber && (
                                <span>, номер квартири: {selectedOrder.userInfo.flatNumber}</span>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div><strong>Отримувач:</strong> {selectedOrder.userInfo.name} {selectedOrder.userInfo.surname}, {selectedOrder.userInfo.phone}</div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>Персональний кабінет</title>
      </Helmet>
      <div className="row">
        <div className="col p-0 pb-3">
          <Header />
        </div>
      </div>

      <div className='row'>
        <div className='col p-0 pb-3'>
          <Tab.Container id='left-tabs-example' defaultActiveKey='first'>
            <Row>
              <Col sm={2}>
                <Nav variant='pills' className='flex-column black-text'>
                  <Nav.Item>
                    <Nav.Link eventKey='first'>Мої замовлення</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='second' onClick={event => loadDiscounts()}>Персональна знижка</Nav.Link>
                  </Nav.Item>
                </Nav>
                <hr className="h-divider pb-3"/>
              </Col>
              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey='first'>
                    <div className='table-responsive'>
                      <table className='table table-bordered'>
                        <thead className='thead-dark'>
                        <tr>
                          <th scope='col'></th>
                          <th scope='col'>№</th>
                          <th scope='col'>Сума</th>
                          <th scope='col'>Сума зі знижкою</th>
                          <th scope='col'>Статус замовлення</th>
                          <th scope='col'>Тип оплати</th>
                          <th scope='col'>Статус оплати</th>
                          <th scope='col'>Час створення</th>
                        </tr>
                        </thead>

                        {orders.map(order => {
                          return (
                            <tbody key={order.orderId}>
                            <tr>
                              <th scope='row'>
                                <a
                                  href='#'
                                  onClick={event => onClickExpand(event, order)}
                                  data-toggle='modal'
                                  data-target='#orderDetailModal'
                                >
                                  <div> (+)</div>
                                </a>
                              </th>
                              <th scope='row'>{order.orderId}</th>
                              <td>{order.totalPrice}</td>
                              <td>{order.discountedPrice}</td>
                              <td
                                className={defineOrderStatusColumnClassName(
                                  order.orderStatus,
                                )}
                              >
                                {order.orderStatus}
                              </td>
                              <td>{order.paymentType}</td>
                              <td
                                className={defineOrderPaymentStatusColumnClassName(
                                  order.paymentStatus,
                                )}
                              >
                                {order.paymentStatus}
                              </td>
                              <td>{order.createTime}</td>
                            </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey='second'>
                    <div className="row pl-2">
                      <div className="col">
                        <h5 className='pb-1'><b>Персональна система знижок:</b></h5>
                        {user.additionalDiscountInfo !== undefined ?
                          <p>Ваш рівень - <b>{user.additionalDiscountInfo.currentDiscountLevel.levelName}</b>. Ваша загальна
                            сума
                            успішних замовлень - <b>{user.additionalDiscountInfo.totalOrdersAmount}грн</b></p>
                          : null}

                        {user.discountLevel !== undefined ?
                          <Tabs
                            defaultActiveKey={user.discountLevel}
                            id='uncontrolled-tab-example'
                            className='mb-3 discount-levels'
                          >
                            {discountLevelsInfo !== undefined
                              ? discountLevelsInfo.map((discountLevelInfo, i) => {
                                return (
                                  <Tab eventKey={discountLevelInfo.level}
                                       title={<span><i
                                         className={defineClassName(discountLevelInfo)}></i> {discountLevelInfo.levelName} </span>}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: discountLevelInfo.userDescription,
                                      }}
                                    />
                                    {renderProgressBar(discountLevelInfo)}
                                  </Tab>
                                );
                              }) : null}
                          </Tabs> : null}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          {renderModal()}
        </div>
      </div>

      <div className="row">
        <div className="col p-0">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default UserPersonalCabinetPage;

import React, { useState } from 'react';
import { BASE_URL } from '../../shared/constants';
import {
  getClassNameForProductOrderType,
  getClassNameForProductStatus,
  renderPrice,
} from '../../utils/adminOrderService';
import { Link } from 'react-router-dom';

const OrderItemDesktop = ({ item, setModalImage, handleEditClick }) => {
  const handleImageClick = (imagePath) => {
    setModalImage(imagePath);
  };
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className='row pt-1 align-items-center'>
      <div className='col-1 d-flex align-items-center'>
        {item.images && item.images.length > 0 && (
          (() => {
            const image = item.images.find(image => image.sortOrder === 0);
            if (image) {
              return (
                <img
                  src={BASE_URL + '/api/v1/product/image/' + image.imagePath}
                  alt={item.name}
                  className='img-fluid'
                  onClick={() => handleImageClick(image.imagePath)}
                  style={{ cursor: 'pointer' }}
                  data-toggle='modal'
                  data-target='#imageModal'
                />
              );
            }
            return null; // Якщо зображення з sortOrder 0 не знайдено, нічого не відображати
          })()
        )}
      </div>

      <div className='col-5'
           onMouseEnter={() => setIsHovered(true)}
           onMouseLeave={() => setIsHovered(false)}>
        <div>
          {item.name}
          {item.state && item.state.databaseValue && item.state.databaseValue === 'PREORDER' && (
            (<span className="font-weight-bold"> [В передзамовленні]</span>)
          )}
          {item.state && item.state.databaseValue && item.state.databaseValue !== 'PREORDER' && item.parentProductName && (
            (<span className="font-weight-bold"> [{item.parentProductName}]</span>)
          )}
          {isHovered && (
            <Link to={'/product/' + item.productId} style={{ color: 'black', marginLeft: '2px' }}>
              <i className='bi bi-forward-fill'></i>
            </Link>
          )}
        </div>
        {item.productCode && (
          <div className='text-muted'>Артикул: {item.productCode}</div>
        )}
        {item.promCommission != null && item.promCommission > 0 && (
          <p className='text-muted'>Комісія прому: {item.promCommission}</p>
        )}
      </div>

      <div className='col-1 d-flex'>
        <span className={`mx-2 ${item.quantity > 1 ? 'text-danger font-weight-bold' : ''}`}>{item.quantity} шт</span>
        <a
          className='cursor-pointer text-dark'
          onClick={() => handleEditClick(item)}
          data-toggle='modal'
          data-target='#editQuantityModal'>
          <i className='bi bi-pencil-fill'></i>
        </a>
      </div>

      <div className='cursor-pointer col-2 d-flex'
           onClick={() => handleEditClick(item)}
           data-toggle='modal'
           data-target='#changeDiscountModal'>
        {renderPrice(item)}
        <a
          className='cursor-pointer pl-1 font-weight-bold text-dark'
          onClick={() => handleEditClick(item)}
          data-toggle='modal'
          data-target='#changeDiscountModal'>
          <i className='bi bi-percent'></i>
        </a>
      </div>

      <div className='col-2 d-flex flex-column align-items-center text-center'>
        <span className={item.productOrderType ? getClassNameForProductOrderType(item.productOrderType.databaseValue) : ''}>
          {item.productOrderType ? item.productOrderType.clientValue : 'Не визначено'}
        </span>
        <span className={`text-truncate ${item.productStatus ? getClassNameForProductStatus(item.productStatus.databaseValue) : ''}`}>
          {item.productStatus ? item.productStatus.shortValue : 'Не визначено'}
        </span>
      </div>

      <div className='col-1 d-flex justify-content-center'>
        <a
          className='cursor-pointer text-dark pr-2'
          onClick={() => handleEditClick(item)}
          data-toggle='modal'
          data-target='#editProductOrderModal'>
          <i className='bi bi-pencil-fill'></i>
        </a>
        <a
          className='cursor-pointer text-danger'
          onClick={() => handleEditClick(item)}
          data-toggle='modal'
          data-target='#confirmDeleteModal'>
          <i className='bi bi-trash-fill'></i>
        </a>
      </div>
      <div className="w-100 border-bottom mt-2"></div>
    </div>
  );
};

export default OrderItemDesktop;

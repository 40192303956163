import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useBannerStore } from '../../store/Banner.js';
import { useBasketStore } from '../../store/Basket.js';
import { useProductStore } from '../../store/Products.js';
import '../../styles/common.css';
import '../../styles/reset.css';
import Banner from '../Banner.js';
import Basket from '../Basket.js';
import {useSearchStringStore} from "../../store/SearchString";
import {Tooltip, OverlayTrigger} from "react-bootstrap";
import queryString from 'query-string'
import { Helmet } from 'react-helmet';
import { BASE_URL } from '../../shared/constants';

const numberPerPage = 21;

const GeneralProductList = props => {
  const [productIdsInCart, setProductIdsInCart] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const Toggle = () => setShowModal(!showModal);

  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const filteredProductsList = useProductStore(state => state.products);

  const fetch = useProductStore(state => state.fetchAllProducts);
  const pageCount = useProductStore(state => state.pageCount);

  const fetchBannerPath = useBannerStore(state => state.fetchBannerPath);

  const cartProducts = useBasketStore(state => state.products);
  const addProductToCart = useBasketStore(state => state.addProduct);

  const setSearchString = useSearchStringStore(state => state.setSearchString);

  const [showPreorderProducts, setShowPreorderProducts] = useState(() => {
    const savedValue = localStorage.getItem('showPreorderProducts');
    return savedValue !== null ? JSON.parse(savedValue) : false;
  });
  const [loadedImages, setLoadedImages] = useState({});

  const { search }  = useLocation();
  const queryParams = queryString.parse(search);
  const history = useHistory();

  const renderPersonalDiscountTooltip = (product) => (
    <Tooltip id="tooltip">
      <strong>Накопичувальна знижка: {product.personalDiscount}% для даної категорії товару</strong>
    </Tooltip>
  );

  useEffect(() => {
    const productIdsInCart = cartProducts.map(product => product.id);
    setProductIdsInCart(productIdsInCart);
  }, [cartProducts]);

  useEffect(() => {
    let page = 1;
    let search = '';
    let categoryIds = '';
    if (queryParams.categoryIds !== undefined && queryParams.categoryIds !== '') {
      setSearchString('');
      categoryIds = queryParams.categoryIds.split(',');
    }

    if (queryParams.searchString !== undefined) {
      setSearchString(queryParams.searchString);
      search = queryParams.searchString;
    } else {
      setSearchString('');
    }

    if (queryParams.page !== undefined) {
      setCurrentPage(queryParams.page - 1);
      page = queryParams.page;
    } else {
      setCurrentPage(0);
    }
    searchProducts(page, search, categoryIds);
  }, [queryParams.searchString, queryParams.page, queryParams.categoryIds, showPreorderProducts]);

  useEffect(() => {
    window.scrollTo(0,0);
    localStorage.setItem("currentUrl", window.location.pathname);
    const token = localStorage.getItem('token');
    fetchBannerPath();

    if (!token) {
      return;
    }
    axios
      .get('/api/v1/auth/current-user', {
        headers: {
          Authorization: token,
        },
      })
      .then(res => {
        if (res.status === 200) {
          setUser(res.data.data);
          setIsLoggedIn(true);
        }
      }).catch(function (error) {
      console.log(error.toJSON());
    });
  }, []);

  const searchProducts = (page, searchString, categoryIds) => {
    let baseUrl = '/api/v1/product?numberPerPage=' + numberPerPage + '&page=' + page;
    const categoryPath =
      searchString === '' && !!categoryIds && categoryIds?.length > 0
        ? '&categoryIds=' + categoryIds
        : '';
    let searchPath = searchString !== null ? '&search=' + searchString : '';
    const showPreorderPath = `&showPreorderProducts=${showPreorderProducts}`;
    let url = baseUrl + categoryPath + searchPath + showPreorderPath;

    fetch(url, numberPerPage);
  };

  const handleCheckboxChange = () => {
    const newValue = !showPreorderProducts;
    setShowPreorderProducts(newValue);
    localStorage.setItem('showPreorderProducts', JSON.stringify(newValue));
  };

  const handleImageLoad = (productId) => {
    setLoadedImages(prev => ({
      ...prev,
      [productId]: true
    }));
  };

  const renderStatus = product => {
    if (product.state === 'SOLD_OUT' && product.quantity === 0) {
      return (
        <h5 className="text-center font-weight-bold">Знято з продажу</h5>
      )
    }
    if (product.state === 'OUT_OF_STOCK' && product.quantity === 0) {
      return (
        <h5 className="text-center font-weight-bold">Тимчасово недоступно</h5>
      )
    }
    if (product.quantity === 0) {
      return (
        <h5 className="text-center font-weight-bold">Немає в наявності</h5>
      )
    }
  }

  const renderBuyButton = product => {
    if (productIdsInCart.includes(product.id) && product.quantity > 0) {
      return (
        <button type='button' className='btn btn-dark' onClick={() => Toggle()}>
          Оформити
        </button>
      );
    }
    if (productIdsInCart.includes(product.id) && product.quantity === 0) {
      return (
        <button type='button' className='btn btn-secondary' onClick={() => Toggle()}>
          Оформити
        </button>
      );
    }
    if (product.quantity === 0 && product.state !== 'SOLD_OUT' && product.state !== 'OUT_OF_STOCK') {
      return (
        <div>
          {/*<button type='button'
                  className='btn btn-outline-secondary'
                  onClick={() => addProductToCart(product)}>
            Під замовлення
          </button>*/}
        </div>
      );
    }
    if (product.quantity > 0 && !productIdsInCart.includes(product.id)) {
      return (
        <button
          type='button'
          onClick={() => addProductToCart(product, 1)}
          className='btn btn-outline-dark'
        >
          В кошик
        </button>
      );
    }
  };

  const renderDerebanButton = (user, isLoggedIn, product) => {
    if (isLoggedIn && (user.type === 'SUPER_USER' || user.type === 'ADMIN')) {
      return (
        <button className='btn btn-dereban'>
          <Link className='btn-block reset-a' to={'/dereban-product/' + product.id}>
            Деребанити
          </Link>
        </button>
      );
    }
  };

  const renderPrice = product => {
    if (product.state === 'SOLD_OUT' && product.quantity === 0) {
      return;
    }
    if (product.discountedPrice < product.price) {
      return (
        <h5 className='price text-center'>
          <strike style={{ textDecoration: 'line-through', color: 'red' }}>{product.price}</strike>{' '}
          {product.discountedPrice}грн
        </h5>
      );
    } else {
      return <h5 className='price text-center'>{product.price} грн</h5>;
    }
  };

  const renderDiscountBadge = product => {
    if (product.personalDiscount > 0 && product.personalDiscount >= product.productDiscount) {
      return (
        <OverlayTrigger placement='top' overlay={renderPersonalDiscountTooltip(product)}>
                        <span
                          className='badge-pill badge-dark discount-badge'>-{product.personalDiscount}%
                        </span>
        </OverlayTrigger>
      )
    } else if (product.productDiscount > 0) {
      let text = product.state === 'PREORDER' ? 'Знижка за передзамовленням' : 'Акційна знижка';
      return (
        <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip">
          <strong>{text}</strong>
        </Tooltip>}>
        <span
          className='badge-pill badge-danger discount-badge'>-{product.productDiscount}%
        </span>
        </OverlayTrigger>
      );
    }
  }

  const showCategory = () => {
    props.showCategory();
  };

  const onClickPage = data => {
    let currentPage = data.selected;
    setCurrentPage(currentPage);
    let params;
    if (queryParams.searchString !== undefined && queryParams.searchString !== '') {
      params = queryString.stringify({searchString: queryParams.searchString, page: currentPage + 1});
    } else if (queryParams.categoryIds !== undefined && queryParams.categoryIds !== '') {
      params = queryString.stringify({categoryIds: queryParams.categoryIds, page: currentPage + 1});
    } else  {
      params = queryString.stringify({page: currentPage + 1})
    }
    window.scrollTo(0, 0);
    history.push("/boxes?" + params);
  };

  return (
    <div>
      <Helmet>
        <title>Waaagh Shop - магазин мініатюр та аксесуарів для Warhammer, Vallejo, Forge World, Age of Sigmar, Kill Team, Warcry та інших систем.</title>
        <meta name='description'
              content='Придбати мініатюри Warhammer, Age of Sigmar, Forge World, Horus Heresy, Kill Team, фарби та
              аксесуари Citadel, Vallejo та інше. Працюємо щоденно, щоб наші клієнти завжди залишались задоволені!' />
      </Helmet>
      <div className='row'>
        <div className='center'>
          <button
            onClick={() => showCategory()}
            type='button'
            className='btn btn-secondary d-lg-none'
          >
            Пошук по категоріях
          </button>
        </div>

        <Banner />

        <div className="col-12">
        <div className='d-flex justify-content-center font-weight-bold mt-2'>
          <div className='form-check'>
            <input
              className='form-check-input mt-0'
              type='checkbox'
              onChange={handleCheckboxChange}
              id='showPreorderProductsCheckbox'
              style={{ width: '1rem', height: '1rem' }}
              checked={showPreorderProducts}
            />
            <label className='form-check-label' htmlFor='showPreorderProductsCheckbox'>
              Показувати товари в передзамовленні
            </label>
          </div>
        </div>
        </div>

        {
          filteredProductsList !== undefined && filteredProductsList !== null && filteredProductsList.length === 0 ?
            <div className="center"><h3>Нічого не знайдено</h3></div> : null}
        {filteredProductsList
          ? filteredProductsList.map((product, i) => {
              return (
                <div
                  className='col-xl-4 col-lg-4 col-md-4 col-12 p-2'
                  key={product.id + product.name}
                >
                  <div className='product'>
                      {product.imagePathList.length > 0 && (
                        <div className='product-image-block'>
                          {renderDiscountBadge(product)}
                          <Link className='btn-block reset-a' to={'/product/' + product.id}>
                            <img
                              className='product_image'
                              src={BASE_URL + '/api/v1/product/image/' + product.imagePathList[0] + '?height=600&width=600'}
                              alt='img'
                              onLoad={() => handleImageLoad(product.id)}
                            />
                          </Link>
                          {loadedImages[product.id] && product.state === 'PREORDER' && (
                            <span className='badge badge-success preorder-badge'>Передзамовлення</span>
                          )}
                          {loadedImages[product.id] && product.discountCategoryName === 'Webstore Exclusive GW' && (
                            <span className='badge badge-secondary online-only-badge'>Online Only</span>
                          )}
                        </div>
                      )}

                    {product.name.length > 0 && (
                      <div className='product-name-block'>
                        <Link className='btn-block reset-a' to={'/product/' + product.id}>
                          <div className='product-name'>{product.name}</div>
                        </Link>
                      </div>
                    )}

                    {renderPrice(product)}
                    {renderStatus(product)}
                    <div className='text-center justify-content-center align-items-center row'>
                      {renderDerebanButton(user, isLoggedIn, product)}
                      {renderBuyButton(product)}
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <Basket showModal={showModal} close={Toggle} />
      <div className='center'>
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={onClickPage}
          containerClassName={'pagination'}
          activeClassName={'active'}
          forcePage={currentPage}
        />
      </div>
    </div>
  );
};

export default GeneralProductList;

import React, {Component, useEffect, useState} from "react";
import Storage from "../Storage";
import axios from "axios";
import Header from "../Header";
import Basket from "../Basket";
import {useBasketStore} from "../../store/Basket";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import { BASE_URL } from '../../shared/constants';

const PreOrderPage = props => {
    const [productIdsInCart, setProductIdsInCart] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const Toggle = () => setShowModal(!showModal);
    const [products, setProducts] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const token = localStorage.getItem('token');

    const cartProducts = useBasketStore(state => state.products);
    const addProductToCart = useBasketStore(state => state.addProduct);

    useEffect(() => {
        window.scrollTo(0, 0);
        localStorage.setItem("currentUrl", window.location.pathname);
        fetchPreOrderProducts();
        if (!token) {
            return;
        }
        axios
          .get('/api/v1/auth/current-user', {
              headers: {
                  Authorization: token,
              },
          })
          .then(res => {
              if (res.status === 200) {
                  setIsLoggedIn(true);
              }
          }).catch(function(error) {
        });
    }, []);

    useEffect(() => {
        const productIdsInCart = cartProducts.map(product => product.id);
        setProductIdsInCart(productIdsInCart);
    }, [cartProducts]);

    const fetchPreOrderProducts = () => {
        const token = new Storage().get('token');
        axios.get("/api/v1/product/1/inner", {
            headers: {
                Authorization: token,
            },
        }).then(res => {
            const products = res.data.data;
            setProducts(products);
        });
    };

    const renderContentHeader = () => {
            return (
                <div className="row">
                    <Helmet>
                        <title>Передзамовлення Warhammer 40000, Age of Sigmar, Kill Team, Warcry та інше</title>
                        <meta name='description'
                              content='Оформити попереднє замовлення зі знижкою на набори Warhammer 40000, Age of Sigmar, Kill Team, Warcry' />
                    </Helmet>
                    <div className="col">
                        <div className="parent-product pt-3">
                            <div className="row">
                                <div className="col-12">
                                    <h3 className="text-center">
                                        <b> Передзамовлення </b>
                                    </h3>
                                </div>
                                <div className="col-xl-4 col-lg-4 pt-1">
                                    {renderImage()}
                                </div>
                                <div className="col-xl-8 col-lg-8 pt-1">
                                    <h5><b>Бонуси і умови передзамовлення:</b> <br/></h5>
                                    <ul>
                                        <li>Знижка на попереднє замовлення - 10% (або ж персональна знижка, якщо вона більша)</li>
                                        <li>Безкоштовна доставка у випадку якщо сума замовлення
                                            більша 2000грн (за умови передоплати на карту). У випадку оплати після
                                            отримання на Новій Пошті - вартість доставки сплачує отримувач
                                        </li>
                                        <li>Ціна наборів буде перерахована у випадку зниження курсу фунта</li>
                                    </ul>
                                    <h5><b>Чому варто робити передзамовлення?</b></h5>
                                    <ul>
                                        <li>
                                            <b>Гарантія отримання.</b> Ми викладаємо на передзамовлення лише ту
                                            кількість наборів, яка точно повинна приїхати. Винятком може бути ситуація,
                                            коли GW зовсім не відправили набори в Україну
                                        </li>
                                        <li>
                                            <b>Найшвидші терміни.</b> Оскільки новинки завжди користуються високим
                                            попитом - тільки передзамовлення дає можливість отримати бажану коробку з
                                            першої партії товару.
                                        </li>
                                    </ul>

                                    <br/><h5><b>
                                    Залишились питання або бажаєте щось уточнити? - </b><a
                                    href="https://t.me/waaaghShopAdmin"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                > t.me/waaaghShopAdmin
                                </a></h5>

                                    <br/><h5>Нижче повний список всіх наборів, які наразі доступні для
                                    передзамовлення</h5>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                </div>
            );
    }

    const renderImage = () => {
        return (
            <div>
                <img
                    className="parentImage"
                    src={"https://waaagh-shop.com.ua/nodeapi/images/1604173005525-preorder.png"}
                />
            </div>
        );
    }

    const renderPrice = (product) => {
        if (product.discountedPrice < product.price) {
            return (
                <h3 className="price text-center">
                    <strike style={{"text-decoration": "line-through", color: "red"}}>
                        {product.price}
                    </strike>{" "}
                    {product.discountedPrice}грн
                </h3>
            );
        } else {
            return <h5 className="price text-center">{product.price} грн</h5>;
        }
    }

    const renderDiscountBadge = product => {
        if (product.personalDiscount >= product.productDiscount && isLoggedIn) {
            let discountCategoryText = product.discountCategoryName ? 'Категорія для знижки - ' + product.discountCategoryName : '';
            let tooltipText = '';
            if (isLoggedIn && product.personalDiscount === 0) {
                tooltipText = 'Для цього товару накопичувальна знижка не застосовується. ' + discountCategoryText;
            } else {
                tooltipText = 'На товар дія ваша накопичувальна знижка. ' + discountCategoryText ;
            }
            return (
              <p className='small font-weight-bold mb-1'>
                  <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>
                      <strong>{tooltipText}</strong>
                  </Tooltip>}>
                      <span className='badge-pill badge-dark pre-order-discount-badge cursor-pointer'>
                          {product.personalDiscount}%
                       </span>
                  </OverlayTrigger>
              </p>
            );
        } else if (product.productDiscount > 0) {
            return (
              <p className='small font-weight-bold mb-1'>
                  <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>
                      <strong>На товар діє знижка за передзамовленням</strong>
                  </Tooltip>}>
                      <span className='badge-pill badge-danger pre-order-discount-badge cursor-pointer'>
                          {product.productDiscount}%
                       </span>
                  </OverlayTrigger>
              </p>
            );
        }
    }

    const renderBuyButton = product => {
        if (product.quantity === 0) {
            return (
                <button type="button" className="btn btn-warning btn-block" disabled>
                    Немає в наявності
                </button>
            );
        }
        if (productIdsInCart == null || !productIdsInCart.includes(product.id)) {
            return (
                <button
                    type="button"
                    onClick={() => addProductToCart(product, 1)}
                    className="btn btn-secondary btn-block"
                >
                    Передзамовити
                </button>
            );
        } else {
            return (
                <button type='button' className='btn btn-dereban btn-block' onClick={() => Toggle()}>
                    Оформити
                </button>
            );
        }
    }

    const renderInnerImage = (product) => {
        if (product.imagePathList[0]) {
            return (
                <img
                    className="inner-product-image"
                    src={BASE_URL + "/api/v1/product/image/"  + product.imagePathList[0] + "?height=250&width=250"}
                />
            );
        } else {
            return <div></div>;
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col p-0">
                    <Header/>
                </div>
            </div>

            {renderContentHeader()}

            <div className="row">
                <div className="col">
                    <div className="inner-products pb-5">
                        <div className="row">
                            {products.map((product, i) => {
                                return (
                                    <div
                                        className="col-xl-6 col-lg-6 col-md-12 col-12 pb-4"
                                        key={product.id + product.name}
                                    >
                                        <div className="inner-product">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="inner-product-name pb-2">
                                                        <strong>{product.name}</strong>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div
                                                    className="col-xl-5 col-lg-5 col-md-12 col-12 d-flex align-items-center justify-content-center">
                                                    <div className="inner-product-image-block">
                                                        {renderDiscountBadge(product)}
                                                        {renderInnerImage(product)}
                                                        {renderPrice(product)}
                                                    </div>
                                                </div>

                                                <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                                                    {product.description.length > 0 && (
                                                        <div className="inner-product-description">
                                                            <b>Комплектація:</b>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: product.description
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-xl-5 col-lg-5 col-md-12 col-12 pt-2">
                                                    {renderBuyButton(product)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col p-0">
                    <Footer />
                </div>
            </div>
            <Basket showModal={showModal} close={Toggle} />
        </div>
    );
}

export default PreOrderPage;
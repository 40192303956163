import axios from 'axios';
import React from 'react';

export const definePaymentStatusClassName = paymentStatus => {
  switch (paymentStatus.adminValue) {
    case 'Оплачений':
      return 'badge badge-success dropdown-toggle p-1 mt-2';
    case 'Не оплачений':
      return 'badge badge-warning dropdown-toggle p-1 mt-2';
    default:
      return 'badge dropdown-toggle mt-2';
  }
};

export const defineOrderStatusClassName = orderStatus => {
  switch (orderStatus.databaseValue) {
    case 'NEW':
      return 'badge badge-info dropdown-toggle p-2';
    case 'WAITING_FOR_PACKAGING':
    case 'PREORDER':
      return 'badge badge-success dropdown-toggle p-2';
    case 'WAITING_FOR_SENDING':
      return 'badge badge-warning dropdown-toggle p-2';
    case 'HOLD':
      return 'badge badge-primary dropdown-toggle p-2';
    case 'CANCELLED':
      return 'badge badge-danger dropdown-toggle p-2';
    case 'SENT':
      return 'badge badge-light dropdown-toggle p-2';
    case 'RETURNED':
      return 'badge badge-secondary dropdown-toggle p-2';
    default:
      return '';
  }
};

export const defineProductStateClassName = productState => {
  switch (productState) {
    case 'ACTIVE':
    case 'PREORDER':
      return 'badge badge-success dropdown-toggle';
    case 'OUT_OF_STOCK':
      return 'badge badge-warning dropdown-toggle';
    case 'SOLD_OUT':
    case 'INACTIVE':
      return 'badge badge-danger dropdown-toggle';
    default:
      return '';
  }
};

export const getClassNameForProductStatus = (databaseValue) => {
  switch (databaseValue) {
    case 'IN_STOCK':
      return 'text-success';
    case 'ORDERED':
      return 'text-success';
    case 'WILL_BE_ORDERED':
      return 'text-warning';
    case 'OUT_OF_STOCK':
      return 'text-warning';
    case 'DO_NOT_DELIVERED':
      return 'text-danger';
    case 'NOT_SHIPPED':
      return 'text-danger';
    case 'RETURNED':
      return 'text-secondary';
    default:
      return '';
  }
};

export const getClassNameForProductOrderType = (databaseValue) => {
  switch (databaseValue) {
    case 'PREORDER':
      return 'text-success';
    case 'TO_ORDER':
      return 'text-info';
    case 'FROM_STOCK':
      return 'text-success';
    default:
      return '';
  }
};

export const renderPrice = item => {
  if (item.price === item.discountedPrice) {
    return (
      <span className='p-0 m-0 font-weight-bold'>{item.discountedPrice}грн</span>
    );
  } else {
    return (
      <div>
        <span className='p-0 m-0 font-weight-bold'>{item.discountedPrice}грн </span>
        <span className='p-0 m-0 font-weight-bold text-muted text-decoration-line-through'>{item.price}грн</span>
      </div>
    );
  }
};

export const updateOrder = (orderId, price, discountedPrice, paymentStatus, orderStatus, paymentType,
                            deliveryCost, adminComment) => {
  const token = localStorage.getItem('token');
  return axios.put('/api/v1/order', {
    orderId: orderId,
    price: price,
    discountedPrice: discountedPrice,
    paymentStatus: paymentStatus,
    orderStatus: orderStatus,
    paymentType: paymentType,
    deliveryCost: deliveryCost,
    adminComment: adminComment
  }, {
    headers: {
      Authorization: token,
    },
  });
};

export const handlePaymentStatusChange = (order, newPaymentStatus, callback) => {
  updateOrder(order.orderId, null, null, newPaymentStatus.adminValue, null, null, null, null)
    .then(() => {
      callback();
    })
    .catch(error => {
      console.error("Failed to update order:", error);
    });
};

export const handleOrderStatusChange = (order, newStatus, callback) => {
  updateOrder(order.orderId, null, null, null, newStatus.adminValue, null, null, null)
    .then(() => {
      callback();
    })
    .catch(error => {
      console.error("Failed to update order:", error);
    });
};

export const handlePaymentTypeChange = (order, newPaymentType, callback) => {
  updateOrder(order.orderId, null, null, order.paymentStatus.adminValue, null, newPaymentType.adminValue, null, null)
    .then(() => {
      callback();
    })
    .catch(error => {
      console.error("Failed to update order:", error);
    });
};

export const generateOrderText = (order) => {
  let deliveryText = '';
  if (order.userInfo.receiveMethod.id === 1) {
    deliveryText = '\nВідправка в ' + order.userInfo.city + ', ' + order.userInfo.departmentNumber + ', '
      + order.userInfo.phone + ', ' + order.userInfo.name + ' ' + order.userInfo.surname;
  } else if (order.userInfo.receiveMethod.id === 2) {
    let flatNumberText = order.userInfo.flatNumber ? ', номер квартири: ' + order.userInfo.flatNumber : '';
    deliveryText = '\nВідправка в ' + order.userInfo.city + ', ' + order.userInfo.street + ', номер будинку: '
      + order.userInfo.buildingNumber + flatNumberText + ', ' + order.userInfo.name + ' ' + order.userInfo.surname;
  }
  let text = 'Вітаємо' +
    '\nОтримали ваше замовлення' +
    '\nТип доставки: ' + order.userInfo.receiveMethod.description +
    deliveryText +
    '\nСпосіб оплати: ' + order.paymentType.clientValue +
    '\nВсе вірно?'
  return text;
}
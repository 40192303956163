import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { HEADER_LINKS, HEADER_OTHER_LINKS, MAX_HOUR_INFO_MESSAGE_DISABLE } from '../shared/constants.js';
import { useSearchStringStore } from '../store/SearchString.js';
import Basket from './Basket.js';

const Header = () => {
  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showNavbar, setShowNavbar] = useState("");
  const Toggle = () => setShowModal(!showModal);

  const [alertMessage, setAlertMessage] = useState('');
  const [showInfoMessage, setShowInfoMessage] = useState({
    value: true,
    dateAdded: null,
  });

  const searchString = useSearchStringStore(state => state.searchString);
  const setSearchString = useSearchStringStore(state => state.setSearchString);

  const history = useHistory();
   
  useEffect(() => {
    const token = localStorage.getItem('token');
    const showInfoMessage = JSON.parse(localStorage.getItem('showInfoMessage'));
    if (showInfoMessage && !showInfoMessage.value) {
      if (Math.abs(Date.now() - showInfoMessage.dateAdded) / 36e5 > MAX_HOUR_INFO_MESSAGE_DISABLE) {
        localStorage.removeItem('showInfoMessage');
      } else {
        setShowInfoMessage({
          value: false,
        });
      }
    }

    axios.get('/nodeapi/setting?name=alert_message').then(res => {
      if (res.status === 200 && res.data[0]) {
        setAlertMessage(res.data[0].value);
      }
    });

    if (!token) {
      return;
    }
    axios
      .get('/api/v1/auth/current-user', {
        headers: {
          Authorization: token,
        },
      })
      .then(res => {
        if (res.status === 200) {
          setUser(res.data.data);
          setIsLoggedIn(true);
        }
      }).catch(function(error) {
    });
  }, []);

  const handleChange = searchString => {
    if (searchString.target.value === '') {
      setSearchString('');
      history.push("/boxes");
    }

    setSearchString(searchString.target.value);
  };

  const searchByName = event => {
    if (event !== null) {
      event.preventDefault();
    }
    hideNavbar();
    history.push("/boxes?searchString=" + searchString);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      searchByName(null);
    }
  };
  
  const hideInfoMessage = () => {
    const showInfoMessage = {
      value: false,
      dateAdded: Date.now(),
    };
    localStorage.setItem('showInfoMessage', JSON.stringify(showInfoMessage));
  };

  const logoutUser = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  const hideNavbar = () => {
    setShowNavbar("");
  }

  const clickNavbarMenu = () => {
    if (showNavbar === 'show') {
      setShowNavbar("");
    } else {
      setShowNavbar("show");
    }
  }

  const renderAuthorizationLink = (withIcon=false) => {
    if (isLoggedIn) {
      return (
          <div className='col-1 d-none d-lg-block'>
            <li className='nav-item dropdown list-style-none'>
              <a
                  className='nav-link dropdown-toggle link-black'
                  href='#'
                  id='navbarDropdown'
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
              >
                <i className='bi bi-person-check-fill fa-lg'></i>
              </a>
              <div className='dropdown-menu dropdown-menu-right' aria-labelledby='navbarDropdown'>
                <Link className='dropdown-item' to='/user/personal-cabinet'>
                  Персональний кабінет
                </Link>
                <button className='dropdown-item' onClick={() => logoutUser()}>
                  Вийти з аккаунту
                </button>
              </div>
            </li>
          </div>
      );
    }
    return (
        <div className='col-1'>
          <li className='nav-item dropdown list-style-none'>
            <a
                className='nav-link dropdown-toggle link-black'
                href='#'
                id='navbarDropdown'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
            >
              {withIcon ? <i className='bi bi-person-circle fa-lg'></i> : <>Авторизуватись</>}
            </a>
            <div className='dropdown-menu dropdown-menu-right' aria-labelledby='navbarDropdown'>
              <Link className='dropdown-item' to='/login'>
                Увійти
              </Link>
              <Link className='dropdown-item' to='/signup'>
                Зареєструватись
              </Link>
            </div>
          </li>
        </div>
    );
  };

  const renderMobileAdminUrls = () => {
    if (isLoggedIn && user.type === 'ADMIN') {
      return (
        <div className='d-lg-none'>
          <div>
            <li className='nav-item dropdown list-style-none'>
              <a
                className='nav-link dropdown-toggle'
                href='#'
                id='navbarDropdown'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <i className='bi bi-gear-wide-connected fa-lg pr-1'></i>
                Адмінка
              </a>
              <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <Link className='dropdown-item' to='/admin/users'>
                  Користувачі
                </Link>
                <Link className='dropdown-item' to='/admin/orders/new'>
                  Замовлення
                </Link>
                <Link className='dropdown-item' to='/admin/products'>
                  Товари
                </Link>
              </div>
            </li>
          </div>

          <hr className='dropdown-divider' />
        </div>
      );
    }
  };

  const renderAuthorizationLinkMobile = () => {
    if (isLoggedIn) {
      return (
          <li className='nav-item dropdown list-style-none'>
            <a
                className='nav-link dropdown-toggle'
                href='#'
                id='navbarDropdown'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
            >
              <i className='bi bi-person-check-fill fa-lg pr-1'></i>
              Ви авторизовані
            </a>
            <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
              <Link className='dropdown-item' to='/user/personal-cabinet'>
                Персональний кабінет
              </Link>
              <button className='dropdown-item' onClick={() => logoutUser()}>
                Вийти з аккаунту
              </button>
            </div>
          </li>
      );
    }
    return (
        <li className='nav-item dropdown list-style-none'>
          <a
              className='nav-link dropdown-toggle'
              href='#'
              id='navbarDropdown'
              role='button'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
          >
            <i className='bi bi-person-circle fa-lg pr-1'></i>
            Авторизуватись
          </a>
          <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
            <Link className='dropdown-item' to='/login'>
              Увійти
            </Link>
            <Link className='dropdown-item' to='/signup'>
              Зареєструватись
            </Link>
          </div>
        </li>
    );
  };

  const renderAdminUrls = () => {
    if (isLoggedIn && user.type === 'ADMIN') {
      return (
        <div className='collapse navbar-collapse'>
          <div className='dropdown'>
            <button
              className='btn btn-secondary dropdown-toggle'
              type='button'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              Адмінка
            </button>
            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
              <Link className='nav-link' to='/admin/users'>
                Користувачі
              </Link>
              <Link className='nav-link' to='/admin/orders/new'>
                Замовлення
              </Link>
              <Link className='nav-link' to='/admin/products'>
                Товари
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  const returnBasket = () => { 
    setShowModal(true);
  };

  return (
    <div>

      {alertMessage && showInfoMessage.value ? (
        <div className='alert alert-secondary alert-server py-1 text-center' role='alert'>
          <button
            type='button'
            className='close'
            data-dismiss='alert'
            onClick={() => hideInfoMessage()}
          >
            ×
          </button>
          <div
            dangerouslySetInnerHTML={{
              __html: alertMessage,
            }}
          />
        </div>
      ) : null}

      <header className='pt-2 pb-2 bg-light d-none d-lg-block'>
        <div className='d-flex flex-row align-items-center'>
          <div className='col-md-2 col-lg-2 d-none d-sm-block'>
            <Link className='navbar-brand link-black' to='/boxes/'>
              <strong>Waaagh Shop </strong>
            </Link>
          </div>

          <div className='col-xl-5 col-md-4 row place-content-center d-none d-md-block'>
            <div className='d-flex flex-row align-items-center'>
              <input
                className='form-control'
                type='search'
                value={searchString}
                onChange={event => handleChange(event)}
                placeholder='Пошук по назві'
                onKeyDown={(event) => handleKeyDown(event)}
              />
              <button className='btn btn-dark ml-2 mr-4 d-none d-lg-block' onClick={event => searchByName(event)}>
                Шукати
              </button>
              <i onClick={event => searchByName(event)} className="bi bi-search fa-lg ml-2 d-block d-lg-none" style={{color: 'white'}}></i>
            </div>
          </div>


          <div className='d-flex flex-column col-xl-2 col-md-3 pl-0i'>
            <div><i className="bi bi-clock-fill"></i> Працюємо щоденно:</div>
            <div className="pl-4 d-flex"><strong>Пн-Пт:</strong> <div className="pl-2 fs-6">9:00 – 19:00</div></div>
            <div className="pl-4 d-flex"><strong>Сб-Нд:</strong> <div className="pl-2">9:00 – 15:00</div></div>
          </div>

          <a
              className='nav-link'
              href="https://t.me/waaaghShopAdmin" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-telegram fa-lg"></i>
          </a>
          <div className='col col-sm-3 col-md-2 align-items-end pl-1 ml-0'>
            <i className="bi bi-telephone-fill fa-lg"></i> 093-553-5116
          </div>

          {renderAuthorizationLink(true)}

        </div>
      </header>

      <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
        <button
          className='navbar-toggler'
          type='button'
          onClick={() => clickNavbarMenu()}
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <Link className='col text-center navbar-brand d-block d-lg-none' to='/'>Waaagh Shop</Link>

        <button className='btn d-block d-lg-none' onClick={() => returnBasket()}>
          <img src='https://waaagh-shop.com.ua/nodeapi/images/shopping_cart.png' className='basket' alt='basket' />
        </button>

        <div className={"collapse navbar-collapse " + showNavbar} id='navbarTogglerDemo01'>

          <div className='col row place-content-center d-block d-lg-none mt-4 mb-4'>
            <div className='d-flex flex-row align-items-center'>
            <input
              className='form-control'
              type='search'
              value={searchString}
              onChange={event => handleChange(event)}
              placeholder='Пошук по назві'
              onKeyDown={(event) => handleKeyDown(event)}
            />
            <button className='btn btn-dark ml-2 mr-4 d-none d-lg-block' onClick={event => searchByName(event)}>
              Шукати
            </button>
            <i onClick={event => searchByName(event)} className="bi bi-search fa-lg ml-2 d-block d-lg-none" style={{color: 'white'}}></i>
          </div>
          </div>

          <hr className="dropdown-divider"/>

          <ul className='nav-item dropdown navbar-nav mr-auto'>
            {HEADER_LINKS.map(link => (
              <li key={link.url} className='nav-item active'>
                <Link className='nav-link' to={link.url} onClick={() => hideNavbar()}>
                  {link.text}
                </Link>
              </li>
            ))}

            <li className='nav-item active dropdown'>
              <a
                className='nav-link dropdown-toggle'
                href='#'
                id='navbarDropdownMenuLink'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                Інше
              </a>
              <div className='dropdown-menu' aria-labelledby='navbarDropdownMenuLink'>
                {HEADER_OTHER_LINKS.map((button, index) => (
                    <Link className="dropdown-item" key={index} to={button.link} onClick={() => hideNavbar()}> {button.text} </Link>
                ))}
              </div>
            </li>

            <div className='d-lg-none'>
              <hr className='dropdown-divider' />
              {renderAuthorizationLinkMobile()}
              <hr className='dropdown-divider' />
            </div>

            {renderMobileAdminUrls()}

            <h5 className='d-lg-none nav-link py-1 my-0'>
              Контакти:
            </h5>
            <div className='d-lg-none'>
              <a
                className='nav-link'
                href="http://t.me/waaaghShopAdmin" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-telegram fa-lg pr-1"></i>
                Ми в Telegram
                </a>
            </div>

            <div className='d-lg-none'>
              <span className='nav-link'>
                <i className="bi bi-telephone-fill fa-lg pr-1"></i>
                093-553-5116
              </span>
            </div>
          </ul>
        </div>

        <button className='btn d-none d-lg-block' onClick={() => returnBasket()}>  
          <img src='https://waaagh-shop.com.ua/nodeapi/images/shopping_cart.png' className='basket' alt='basket' />
        </button>

        {renderAdminUrls()}

      </nav>
      

       <Basket showModal={showModal} close={Toggle} />
    </div>
  );
};

export default Header;

import React from "react";
import { BASE_URL } from '../shared/constants';

const imgWithClick = { cursor: "pointer" };

const DragPhoto = props => {
  const imgStyle = { margin: props.item.margin,  };

  const handleClick = event => {
    props.item.onClick(event, props.item.photo, props.item.index);
  };

  return (
    <div className="sortableHelper">
      <img
        className="admin-product-image"
        style={props.item.onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
        src={BASE_URL + "/api/v1/product/image/" + props.item.photo.src}
        onClick={props.item.onClick ? handleClick : null}
        alt='img'
      />
      <button
        className='btn btn-sm btn-danger'
        onClick={e => props.onRemoveItem(e, props.item.photo.src)}
      >
        Видалити
      </button>
    </div>
  );
};

export default DragPhoto;

import Header from '../Header';
import React, { useEffect, useState } from "react";
import Footer from '../Footer';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const ClientInfoPage = props => {
  const history = useHistory();
  const [eventKey, setEventKey] = useState();
  let [discountLevelsInfo, setDiscountLevelsInfo] = useState(undefined);

  const handleClick = eventKey => {
    setEventKey(eventKey);
    history.push("./" + eventKey);
  };

  useEffect(() => {
    localStorage.setItem("currentUrl", window.location.pathname);
    axios
      .get('/api/v1/user/discount-level')
      .then(res => {
        const discountLevelsInfo = res.data.data;
        setDiscountLevelsInfo(discountLevelsInfo);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
    if (props.match.params.eventKey !== undefined) {
      setEventKey(props.match.params.eventKey);
    }
  }, [props.match.params.eventKey]);

  return (
    <div className="container">
      <Helmet>
        <title>Інформація з приводу доставки, оплати та повернення наборів</title>
      </Helmet>
      <div className="row">
        <div className="col p-0 pb-3">
          <Header/>
        </div>
      </div>

      <div className="row">
        <div className="col p-0 pb-3">
          <Tab.Container id='left-tabs-example' activeKey={eventKey}>
            <Row>
              <Col sm={3}>
                <Nav variant='pills' className='flex-column black-text'>
                  <Nav.Item>
                    <Nav.Link  eventKey='discount-system' onClick={() => handleClick("discount-system")}>
                      Накопичувальна система знижок
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='payment-delivery' onClick={() => handleClick("payment-delivery")}>Оплата і доставка</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='orders-return' onClick={() => handleClick("orders-return")}>Повернення товару</Nav.Link>
                  </Nav.Item>
                </Nav>
                <hr className="h-divider pb-3"/>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey='discount-system'>
                    <div className="row pl-2">
                      <div className="col">
                        <h3 className="text-center">Накопичувальна система знижок</h3>
                        <ul className='px-0 mx-0'>
                          <li>Ми цінуємо наших клієнтів, тому в якості бонусу за лояльність пропонуємо скористатись
                            накопичувальною системою знижок. Для того, щоб стати учасником і отримати персональну знижку
                            потрібно <strong>зареєструватись на сайті.</strong> Після цього ви зможете переглянути ваш
                            рівень персональної знижки в особистому кабінеті користувача
                          </li>
                          <li><strong>Одразу після реєстрації ви отримаєте персональну знижку, яка буде збільшуватись
                            після досягнення кожного наступного рівня</strong></li>
                        </ul>
                        <h4 className="text-center">Розмір знижки для кожного рівня:</h4>
                        {discountLevelsInfo !== undefined
                          ? discountLevelsInfo.map((discountLevelInfo, i) => {
                            return (
                              <div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: discountLevelInfo.userDescription,
                                }}
                              /></div>
                            )
                          }) : null}


                        <ul className='px-0 mx-0'>
                          <li><strong>Якщо ви робили до цього замовлення без реєстрації на сайті</strong> - сміливо
                            реєструйтесь і вони підтягнуться автоматично за вашим номером телефону. Якщо ви робили
                            замовлення на інший номер телефону - напишіть нам в телеграмі і ми прикріпимо попередні
                            замовлення до нового акаунту
                          </li>
                          <li><strong>Персональна знижка відображається лише після входу на сайт і не сумується з
                            акційними пропозиціями</strong></li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey='payment-delivery'>
                    <div className="row pl-2">
                      <div className="col">
                        <h3 className="text-center">Оплата і доставка</h3>
                        <p><strong>Оплата</strong> може бути здійснена наступними способами:</p>
                        <ul>
                          <li><strong>Оплата на банківський рахунок.</strong> У цьому випадку реквізити будуть надані
                            менеджером після обробки вашого замовлення
                          </li>
                          <li><strong>Післяплата на пошті.</strong> Оплата відбувається при отриманні у відділенні Нової
                            Пошти / Укрпошти. Службою доставка стягується додатковка комісія до 2%
                          </li>
                        </ul>
                        <p><strong>Доставка:</strong></p>
                        <ol>
                          <li>
                            Доставка до відділення чи поштомату Нової Пошти.
                          </li>
                          <li>
                            Кур'єрська доставка Новою Поштою. При оформленні замовлення вкажіть адресу в полі "Коментар
                            до замовлення"
                          </li>
                          <li>
                            Доставка Укрпоштою. Здійснюється з затримкою в 1 робочий день
                          </li>
                        </ol>
                        <p><strong>Доставка є безкоштовною для покупця за умови покупки на суму більшу за 2000грн і повної передплати</strong></p>
                        <p><strong>Відправка замовлення</strong> здійснюється в той же день (для відправок Новою Поштою), якщо воно
                          зроблено не пізніше, ніж за 4год до кінця робочого дня. Актуальний графік роботи магазину
                          можна переглянути в шапці сайту. Замовлення укрпоштою відправляються з затримкою в 1 день</p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey='orders-return'>
                    <div className="row pl-2">
                      <div className="col">
                        <h3 className="text-center">Обмін та повернення товару</h3>
                        <p>Згідно закону <strong>«Закон про захист прав споживача»</strong> ви можете повернути або
                          обміняти товар протягом 14 днів з моменту покупки за умов:</p>
                        <ul>
                          <li>товар не був у вжитку і не має слідів експлуатації</li>
                          <li>збережений товарний вигляд та комплектація</li>
                        </ul>
                        <p>Для повернення товару зконтактуйте з нами в телеграмі або за номером телефону, який вказано в шапці сайту</p>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>

      <div className="row">
        <div className="col p-0">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default ClientInfoPage;
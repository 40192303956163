import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { checkIsAdminLoggined } from '../../utils/utils';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Alert from './Alert';
import { fetchOrderStatuses, fetchPaymentStatuses, fetchPaymentTypes } from '../../utils/apiUtils';
import {
  defineOrderStatusClassName,
  definePaymentStatusClassName, generateOrderText, handleOrderStatusChange,
  handlePaymentStatusChange, handlePaymentTypeChange,
  updateOrder,
} from '../../utils/adminOrderService';
import AdminCommentModal from './AdminCommentModal';

const GeneralAdminOrdersPage = props => {

    const token = localStorage.getItem('token');
    const LONG_TAP_DURATION = 500;

    //enums
    const [orderStatusesEnum, setOrderStatusesEnum] = useState([]);
    const [paymentTypesEnum, setPaymentTypesEnum] = useState([]);
    const [paymentStatusesEnum, setPaymentStatusesEnum] = useState([]);

    //filters
    const [orderStatusFilterList, setOrderStatusFilterList] = useState([
      {
        'adminValue': 'Підтверджено',
        'clientValue': 'Підтверджено, очікує на пакування',
        'databaseValue': 'WAITING_FOR_PACKAGING',
      },
    ]);
    const [paymentStatusStatusFilterList, setPaymentStatusStatusFilterList] = useState([]);
    const [phoneNumberFilter, setPhoneNumberFilter] = useState('');
    const [clientSurnameFilter, setClientSurnameFilter] = useState('');
    const [orderIdFilter, setOrderIdFilter] = useState('');
    const [productIdFilter, setProductIdFilter] = useState('');
    const [orderDiscountedPriceFilter, setOrderDiscountedPriceFilter] = useState('');

    // State variable for product search
    const [productSearchQuery, setProductSearchQuery] = useState('');
    const [productSearchResults, setProductSearchResults] = useState([]);

    const [orders, setOrders] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [numberPerPage, setNumberPerPage] = useState(10);
    const [ordersCount, setOrdersCount] = useState();
    const [pageCount, setPageCount] = useState();
    const [expandedOrders, setExpandedOrders] = useState([]);
    const [clickedOrderIds, setClickedOrderIds] = useState([]);
    const [isBlockVisible, setIsBlockVisible] = useState(false);
    const [showFiltersMobile, setShowFiltersMobile] = useState(false);
    const [deliveryCost, setDeliveryCost] = useState('');
    const [selectedOrderId, setSelectedOrderId] = useState();
    const [hoveredOrderId, setHoveredOrderId] = useState();
    const [generatedToClipboardTextOrderId, setGeneratedToClipboardTextOrderId] = useState('');

    const [orderIdListToShowUserComment, setOrderIdListToShowUserComment] = useState([]);
    const [orderIdListToShowAdminComment, setOrderIdListToShowAdminComment] = useState([]);
    const [adminComment, setAdminComment] = useState('');
    const [copiedToClipboardText, setCopiedToClipboardText] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [longTapTimeout, setLongTapTimeout] = useState(null);

    useEffect(() => {
      checkIsAdminLoggined(props);
      window.scrollTo(0, 0);
      localStorage.setItem('currentUrl', window.location.pathname);
      getOrders();
      fetchOrderStatuses(token, setOrderStatusesEnum);
      fetchPaymentTypes(token, setPaymentTypesEnum);
      fetchPaymentStatuses(token, setPaymentStatusesEnum);
    }, []);

    useEffect(() => {
      setCurrentPage(0);
      getOrders(0);
    }, [orderStatusFilterList, paymentStatusStatusFilterList, productIdFilter]);

    useEffect(() => {
      getOrders(currentPage);
    }, [currentPage, numberPerPage]);

    useEffect(() => {
      if (orderIdFilter === '') {
        searchByFilters();
      }
    }, [orderIdFilter]);

    useEffect(() => {
      if (clientSurnameFilter === '') {
        searchByFilters();
      }
    }, [clientSurnameFilter]);

    useEffect(() => {
      if (phoneNumberFilter === '') {
        searchByFilters();
      }
    }, [phoneNumberFilter]);

    useEffect(() => {
      if (orderDiscountedPriceFilter === '') {
        searchByFilters();
      }
    }, [orderDiscountedPriceFilter]);

    useEffect(() => {
      if (productSearchQuery === '') {
        setProductIdFilter('');
      }
    }, [productSearchQuery]);

    const handleProductSearch = (productSearchQuery) => {
      if (productSearchQuery.length < 2) return;
      axios
        .get(`/api/v1/product/search-info?searchParam=${productSearchQuery}`, {
          headers: {
            Authorization: token,
          },
        })
        .then(res => {
          if (res.status === 200) {
            setProductSearchResults(res.data.data.slice(0, 5));
          }
        }).catch(function(error) {
      });
    };

    const getOrders = (selectedPage) => {
      const page = (selectedPage !== undefined ? selectedPage : currentPage) + 1;
      const orderStatusList = orderStatusFilterList.length === 0 ? '' : orderStatusFilterList.map((status) => status.databaseValue).join(', ');
      const orderPaymentStatusList = paymentStatusStatusFilterList.length === 0 ? '' : paymentStatusStatusFilterList.map((status) => status.databaseValue).join(', ');
      axios
        .get('/api/v1/order?numberPerPage=' + numberPerPage + '&page=' + page
          + '&orderStatusList=' + orderStatusList + '&orderPaymentStatusList=' + orderPaymentStatusList
          + '&clientPhone=' + phoneNumberFilter + '&clientSurname=' + clientSurnameFilter
          + '&orderId=' + orderIdFilter + '&productId=' + productIdFilter + '&discountedPrice='
          + orderDiscountedPriceFilter, {
          headers: {
            Authorization: token,
          },
        })
        .then(res => {
          if (res.status === 200) {
            setOrders(res.data.data);
            setOrdersCount(res.data.totalNumber);
            setPageCount(Math.ceil(res.data.totalNumber / numberPerPage));
          }
        }).catch(function(error) {
      });
    };

  const mergeOrders = () => {
    const token = localStorage.getItem('token');
    axios.get('/api/v1/order/merge?orderIds=' + clickedOrderIds,{
      headers: {
        Authorization: token,
      },
    }).then(res => {
      getOrders();
    }).catch(function(error) {
      console.log(error);
    });
  };

    const updateDeliveryCost = () => {
      if (deliveryCost.trim() !== '') {
        updateOrder(selectedOrderId, null, null, null, null, null, deliveryCost, null)
          .then(() => {
            getOrders();
          })
          .catch(error => {
            console.error("Failed to update order:", error);
          });
      }
    };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedToClipboardText(text);
      setGeneratedToClipboardTextOrderId('');
      setAlertMessage("Додано в буфер обміну");
      setShowAlert(true);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const generateOrderTextToClipboard = (order) => {
    let text = generateOrderText(order);

    navigator.clipboard.writeText(text);
    setGeneratedToClipboardTextOrderId(order.orderId);
    setCopiedToClipboardText('');

    setAlertMessage('Повідомлення успішно згенеровано і додано в буфер обміну');
    setShowAlert(true);
  };

  const handleConfirmMerge = () => {
    mergeOrders();
    setClickedOrderIds([]);
  };

    const handleDeliveryCostChange = (e) => {
      setDeliveryCost(e.target.value);
    };

    const handleProductSearchInputChange = event => {
      setProductSearchQuery(event.target.value);
      handleProductSearch(event.target.value);
    };

    const handleDeliveryCostModalKeyDown = (e) => {
      const mobileActionKeys = ['Enter', 'Done', 'Go', 'Next', 'Search'];
      if (e.key === 'Enter' || mobileActionKeys.includes(e.key)) {
        updateDeliveryCost();
        document.getElementById('deliveryModal').click();
      }
    };

    const handleProductSelection = product => {
      setProductIdFilter(product.id);
      setProductSearchResults([]);
      setProductSearchQuery(product.name);
    };

    const handleOrderSelect = (orderId) => {
      if (clickedOrderIds.includes(orderId)) {
        setClickedOrderIds(clickedOrderIds.filter(id => id !== orderId));
      } else {
        setClickedOrderIds([...clickedOrderIds, orderId]);
      }
    };

    const handleOrderContentTouchStart = (event, orderId) => {
      const timeout = setTimeout(() => {
        handleOrderSelect(orderId);
      }, LONG_TAP_DURATION);

      setLongTapTimeout(timeout);
    };

    const handleOrderContentTouchEnd = () => {
      clearTimeout(longTapTimeout);
    };

    const handleOrderContentTouchMove = () => {
      clearTimeout(longTapTimeout);
    };

    const clearFilters = () => {
      setOrderStatusFilterList([]);
      setPaymentStatusStatusFilterList([]);
      setPhoneNumberFilter('');
      setClientSurnameFilter('');
      setOrderIdFilter('');
      setProductIdFilter('');
      setProductSearchQuery('');
      setOrderDiscountedPriceFilter('');
    };

    const onClickPage = data => {
      window.scrollTo(0,0);
      setCurrentPage(data.selected);
    };

    const searchByFilters = () => {
      setCurrentPage(0);
      getOrders();
    };

    const toggleExpand = (orderId) => {
      if (expandedOrders.includes(orderId)) {
        setExpandedOrders(expandedOrders.filter(id => id !== orderId));
      } else {
        setExpandedOrders([...expandedOrders, orderId]);
      }
    };

    const addOrderIdToShowUserCommentsList = (orderId) => {
      if (orderIdListToShowUserComment.includes(orderId)) {
        setOrderIdListToShowUserComment(orderIdListToShowUserComment.filter(id => id !== orderId));
      } else {
        setOrderIdListToShowUserComment([...orderIdListToShowUserComment, orderId]);
      }
    };

    const addOrderIdToShowAdminCommentsList = (orderId) => {
      if (orderIdListToShowAdminComment.includes(orderId)) {
        setOrderIdListToShowAdminComment(orderIdListToShowAdminComment.filter(id => id !== orderId));
      } else {
        setOrderIdListToShowAdminComment([...orderIdListToShowAdminComment, orderId]);
      }
    };

    const handleOrderStatusFilterChange = (status) => {
      if (orderStatusFilterList.some((filter) => filter.databaseValue === status.databaseValue)) {
        setOrderStatusFilterList(orderStatusFilterList.filter((filter) => filter.databaseValue !== status.databaseValue));
      } else {
        setOrderStatusFilterList([...orderStatusFilterList, status]);
      }
    };

    const handlePaymentStatusFilterChange = (status) => {
      if (paymentStatusStatusFilterList.some((filter) => filter.databaseValue === status.databaseValue)) {
        setPaymentStatusStatusFilterList(paymentStatusStatusFilterList.filter((filter) => filter.databaseValue !== status.databaseValue));
      } else {
        setPaymentStatusStatusFilterList([...paymentStatusStatusFilterList, status]);
      }
    };

    const handlePhoneNumberChange = (event) => {
      let input = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
      if (input.startsWith('38')) {
        input = input.slice(2); // Remove '38' if it's the prefix
      }
      if (input.startsWith('+38')) {
        input = input.slice(2); // Remove '38' if it's the prefix
      }
      input = input.slice(0, 10); // Limit to 10 digits
      setPhoneNumberFilter(input);
    };

    const handleFiltersKeyDown = (event) => {
      if (event.key === 'Enter') {
        searchByFilters();
      }
    };

    const generateCaption = (filterList) => {
      if (filterList.length === 0) {
        return 'Не вибрано';
      } else {
        return filterList.map((status) => status.adminValue).join(', ');
      }
    };

    const defineDropdownFiltersStyle = (filterList) => {
      if (filterList.length === 0) {
        return 'btn btn-sm btn-light dropdown-toggle';
      } else {
        return 'btn btn-sm btn-info dropdown-toggle';
      }
    };

  const renderClipBoard = (text, order) => {
    if (text === copiedToClipboardText) {
      return (
        <i
          className='bi bi-clipboard-check text-success ml-1'
          style={{ cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        />
      )
    } else if (hoveredOrderId === order.orderId) {
      return (
        <i
          className='bi bi-clipboard ml-1'
          style={{ cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        />
      )
    }
  }

  const renderGenerateMessageToClipboardIcon = (order, checkHover) => {
    if (generatedToClipboardTextOrderId !== order.orderId && (!checkHover || hoveredOrderId === order.orderId)) {
      return (
        <i
          className='bi bi-clipboard-data pl-1'
          style={{ cursor: 'pointer' }}
          onClick={() => generateOrderTextToClipboard(order)}
        />
      )
    } else if (generatedToClipboardTextOrderId === order.orderId) {
      return (
        <i
          className='bi bi-clipboard-check text-success pl-1'
          style={{ cursor: 'pointer' }}
        />
      )
    }
  }

  const renderPrice = order => {
    if (order.price === order.discountedPrice) {
      return (
        <p className='p-0 m-0 font-weight-bold'>{order.discountedPrice}грн</p>
      )
    } else {
      return (
        <div>
          <span className='p-0 m-0 font-weight-bold'>{order.discountedPrice}грн </span>
          <span className='p-0 m-0 font-weight-bold text-muted text-decoration-line-through'>{order.price}грн</span>
        </div>
      )
    }
  };

  const renderPaymentStatusCloseButton = () => {
    if (paymentStatusStatusFilterList.length > 0) {
      return (
        <span
          className='clear-filter'
          onClick={(e) => {
            setPaymentStatusStatusFilterList([]);
          }}
        >
          &times;
        </span>
      );
    }
  };

  const renderOrderStatusCloseButton = () => {
    if (orderStatusFilterList.length > 0) {
      return (
        <span
          className='clear-filter'
          onClick={(e) => {
            setOrderStatusFilterList([]);
          }}
        >
          &times;
        </span>
      )
    }
  };

  const renderProductPrice = product => {
    if (product.price === product.discountedPrice) {
      return (
        <p className='p-0 m-0 font-weight-bold'>{product.discountedPrice}грн</p>
      );
    } else {
      return (
        <div>
          <span className='p-0 m-0 font-weight-bold'>{product.discountedPrice}грн </span>
          <span className='p-0 m-0 font-weight-bold text-muted text-decoration-line-through'>{product.price}грн</span>
        </div>
      )
    }
  };

  const renderOrderIdFilter = () => {
    return (
      <div>
        <p className='mb-1 font-weight-bold'>ID:</p>
        <input
          type='search'
          className='form-control'
          value={orderIdFilter}
          onChange={(event) => setOrderIdFilter(event.target.value)}
          onKeyDown={handleFiltersKeyDown}
          placeholder='Номер замовлення'
        />
      </div>
    )
  }
  const renderNumberPerPageDropdown = () => {
    return (
      <div>
        <div className='dropdown pt-3 pr-1'>
          <button
            className='btn btn-sm btn-secondary dropdown-toggle'
            type='button'
            id='dropdownMenuButton'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            {numberPerPage}
          </button>
          <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
            <button className='dropdown-item' onClick={() => setNumberPerPage(5)}>5</button>
            <button className='dropdown-item' onClick={() => setNumberPerPage(10)}>10</button>
            <button className='dropdown-item' onClick={() => setNumberPerPage(20)}>20</button>
            <button className='dropdown-item' onClick={() => setNumberPerPage(50)}>50</button>
          </div>
        </div>
      </div>
    )
  }

  const renderPriceFilter = () => {
    return (
      <div>
        <p className='mb-1 font-weight-bold'>Сума:</p>
        <input
          type='search'
          className='form-control'
          value={orderDiscountedPriceFilter}
          onChange={(event) => setOrderDiscountedPriceFilter(event.target.value)}
          onKeyDown={handleFiltersKeyDown}
          placeholder='Сума зі знижкою'
        />
      </div>
    )
  }
  const renderSurnameFilter = () => {
    return (
      <div>
        <p className='mb-1 font-weight-bold'>Прізвище клієнта:</p>
        <input
          type='search'
          className='form-control'
          value={clientSurnameFilter}
          onChange={(event) => setClientSurnameFilter(event.target.value)}
          onKeyDown={handleFiltersKeyDown}
          placeholder='Прізвище клієнта'
        />
      </div>
    )
  }
  const renderPhoneFilter = () => {
    return (
      <div>
        <p className='mb-1 font-weight-bold'>Телефон клієнта:</p>
        <input
          type='search'
          className='form-control'
          value={phoneNumberFilter}
          placeholder='Номер телефону клієнта'
          onKeyDown={handleFiltersKeyDown}
          onChange={handlePhoneNumberChange}
        />
      </div>
    )
  }
  const renderPaymentStatusFilter = () => {
    return (
      <div className='filter-block pr-2'>
        <p className='mb-1 font-weight-bold'>Статус оплати:</p>
        <div className='custom-dropdown-wrapper'>
          <button
            className={`custom-dropdown-button ${defineDropdownFiltersStyle(paymentStatusStatusFilterList)}`}
            type='button'
            id='dropdown-payment-status'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            {generateCaption(paymentStatusStatusFilterList)}
          </button>
          {renderPaymentStatusCloseButton()}
          <div className='dropdown-menu' aria-labelledby='dropdown-payment-status'>
            {paymentStatusesEnum.map((status, index) => (
              <button
                key={index}
                className={`dropdown-item ${paymentStatusStatusFilterList.some((filter) => filter.databaseValue === status.databaseValue) ? 'active' : ''}`}
                type='button'
                onClick={() => handlePaymentStatusFilterChange(status)}
              >
                {status.adminValue}
              </button>
            ))}
          </div>
        </div>
      </div>
    )
  }

  const renderOrderStatusFilter = () => {
    return (
      <div className='filter-block pl-2'>
        <p className='mb-1 font-weight-bold'>Статус замовлення:</p>
        <div className='custom-dropdown-wrapper'>
          <button
            className={`custom-dropdown-button ${defineDropdownFiltersStyle(orderStatusFilterList)}`}
            type='button'
            id='dropdown-order-status'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            {generateCaption(orderStatusFilterList)}
          </button>
          {renderOrderStatusCloseButton()}
          <div className='dropdown-menu' aria-labelledby='dropdown-order-status'>
            {orderStatusesEnum.map((status, index) => (
              <button
                key={index}
                className={`dropdown-item ${orderStatusFilterList.some((filter) => filter.databaseValue === status.databaseValue) ? 'active' : ''}`}
                type='button'
                onClick={() => handleOrderStatusFilterChange(status)}
              >
                {status.adminValue}
              </button>
            ))}
          </div>
        </div>
      </div>
    )
  }
  const renderSearchByProduct = () => {
    return (
      <div>
        <p className='mb-1 font-weight-bold px-1'>Пошук за продуктом:</p>
        <input
          type='search'
          className='form-control'
          value={productSearchQuery}
          placeholder='Введіть назву, код або айді продукту'
          onChange={handleProductSearchInputChange}
        />
        {productSearchResults.length > 0 && (
          <div className='search-results'>
            {productSearchResults.map(product => (
              <div key={product.id} className='search-result card'
                   onClick={() => handleProductSelection(product)}>
                <div className='card-body p-2'>
                  <div className='row'>
                    <div className='col'>
                      <h6 className='card-title mb-1'
                          style={{ fontSize: '0.9rem' }}>ID: {product.id} - <strong>{product.name}</strong>
                      </h6>
                      <p className='card-text mb-0' style={{ fontSize: '0.8rem' }}>Code: {product.code}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  const renderCommentsBlock = order => {
    return (
      <div className='row pt-2'>
        <div className='col-lg-6 col-md-12'>
          {order.userComment && !orderIdListToShowUserComment.includes(order.orderId) && (
            <span className='cursor-pointer font-weight-bold text-success'
                  onClick={() => addOrderIdToShowUserCommentsList(order.orderId)}>
            <i className='bi bi-chat-square-text pr-1' />
            Коментар клієнта
          </span>
          )}

          {order.userComment && orderIdListToShowUserComment.includes(order.orderId) && (
            <div className='card bg-light mt-1'>
              <div className='card-header d-flex justify-content-between align-items-center'>
                <div className='font-weight-bold text-success'>
                  <i className='bi bi-chat-square-text pr-1' />
                  Коментар клієнта
                </div>
                <span
                  className='cursor-pointer'
                  onClick={() => addOrderIdToShowUserCommentsList(order.orderId)}
                >
                  &times;
                </span>
              </div>
              <div className='card-body p-2'>
                <span className='card-text' style={{ whiteSpace: 'pre-line' }}>{order.userComment}</span>
              </div>
            </div>
          )}
        </div>

        <div className='col-lg-6 col-md-12'>
          {order.adminComment && !orderIdListToShowAdminComment.includes(order.orderId) && (
            <span className='cursor-pointer font-weight-bold text-danger'
                  onClick={() => addOrderIdToShowAdminCommentsList(order.orderId)}>
            <i className='bi bi-chat-square-text pr-1' />
            Коментар адміністратора
          </span>
          )}

          {order.adminComment && orderIdListToShowAdminComment.includes(order.orderId) && (
            <div className='card bg-light mt-1'>
              <div className='card-header d-flex justify-content-between align-items-center'>
                <div className='font-weight-bold text-danger'>
                  <i className='bi bi-chat-square-text pr-1' />
                  Коментар адміністратора
                </div>
                <span
                  className='cursor-pointer'
                  onClick={() => addOrderIdToShowAdminCommentsList(order.orderId)}
                >
                  &times;
                </span>
              </div>
              <div className='card-body p-2'>
                <span className='card-text' style={{ whiteSpace: 'pre-line' }}>
                  {order.adminComment} {' '}
                  <i className='bi bi-pencil-fill cursor-pointer' data-toggle='modal' data-target='#adminCommentModal'
                     onClick={() => {
                       setSelectedOrderId(order.orderId);
                       setAdminComment(order.adminComment);
                     }} />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

    return (
      <div className='container'>
        <Helmet>
          <title>Адміністрування замовлень</title>
        </Helmet>

        <Alert show={showAlert} setShow={setShowAlert} message={alertMessage} timeout={1500} />

        <div className='row'>
          <div className='col p-0'>
            <Header />
          </div>
        </div>

        <div className="modal fade" id="mergeOrdersConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="mergeOrdersModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <p>Ви дійсно хочете об'єднати наступні замовлення?</p>
                {orders !== undefined && orders !== null && (
                  <ul>
                    {orders
                      .filter((order) => clickedOrderIds.includes(order.orderId))
                      .map((order) => (
                        <li key={order.orderId}>
                          ID: <strong>{order.orderId}.</strong> Клієнт: <strong>{order.userInfo.name} {order.userInfo.surname}</strong>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
              <div className="modal-footer">
                <button type='button' className='btn btn-secondary' data-dismiss='modal'>Скасувати</button>
                <button type='button' className='btn btn-success' data-dismiss='modal' onClick={handleConfirmMerge}>
                  Підтвердити
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="deliveryModal" tabIndex="-1" role="dialog" aria-labelledby="deliveryModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <input
                  type="number"
                  className="form-control"
                  value={deliveryCost}
                  onChange={handleDeliveryCostChange}
                  onKeyDown={handleDeliveryCostModalKeyDown}
                  placeholder="Введіть вартість доставки"
                />
              </div>
            </div>
          </div>
        </div>

        <AdminCommentModal
          selectedOrderId={selectedOrderId}
          adminComment={adminComment}
          setAdminComment={setAdminComment}
          refreshOrders={getOrders}
        />

        <div className='content d-none d-lg-block'>
          <div className='row filters'>
            <div className='col-1 py-2 px-1'>
              {renderOrderIdFilter()}
            </div>

            <div className='col-4 py-2 d-flex justify-content-end'>
              <div className='pr-1'>
                {renderSurnameFilter()}
              </div>
              <div className='pl-1'>
                {renderPriceFilter()}
              </div>
            </div>

            <div className='col-1 py-2'>
            </div>

            <div className='col-4 py-2 d-flex justify-content-end'>
              {renderPaymentStatusFilter()}
              {renderOrderStatusFilter()}
            </div>


            <div className='col-2 py-2 d-flex justify-content-end'>
              {renderNumberPerPageDropdown()}
              <div className='pt-3 pl-1'>
                <button className='btn btn-sm btn-success' onClick={() => setIsBlockVisible(!isBlockVisible)}>+</button>
              </div>
            </div>
          </div>


          {isBlockVisible && (
            <div className='row'>
              <div className='col-7 px-1'>
                {renderSearchByProduct()}
              </div>
              <div className='col-2'>
                <div className='pl-1'>
                  {renderPhoneFilter()}
                </div>
              </div>
            </div>
          )}

          <div className='row orders pt-2'>
            <div className='col-6'>
              {clickedOrderIds.length > 0 && (
                <div className='d-flex justify-content-start'>
                  <div className='dropdown pr-2'>
                    <button
                      className='btn btn-dark dropdown-toggle'
                      type='button'
                      id='dropdownMenuButton'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Вибрано {clickedOrderIds.length} замовлень
                    </button>
                    <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                      {clickedOrderIds.length > 1 && (
                        <button className='dropdown-item' data-toggle='modal'
                                data-target='#mergeOrdersConfirmationModal'>
                          Об'єднати
                        </button>
                      )}
                    </div>
                  </div>
                  <button className='btn btn-danger' onClick={() => setClickedOrderIds([])}>
                    Відмінити вибір
                  </button>
                </div>
              )}
            </div>
            <div className='col-6'>
              <div className='d-flex justify-content-end'>
                <button className='btn btn-dark mr-2' onClick={searchByFilters}>Оновити
                </button>
                <button className='btn btn-danger' onClick={clearFilters}>Очистити фільтр
                </button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <hr />
            </div>
          </div>

          <h5 className='text-center font-weight-bold'>Всього замовлень: {ordersCount}</h5>
          <div className='row py-3'>
            <div className='col-3 pl-0 font-weight-bold'>Замовлення</div>
            <div className='col-2 font-weight-bold'>Загальна сума</div>
            <div className='col-2 font-weight-bold'>Клієнт</div>
            <div className='col-3 font-weight-bold'>Доставка та спосіб оплати</div>
            <div className='col-2 pr-0 font-weight-bold'>Статус</div>
            <div className='col-12 p-0 pt-2'>
              <hr className='h-divider' />
            </div>
          </div>
          {orders !== undefined && orders !== null
            ? orders.map((order, i) => {
              return (
                <div key={i}
                     className={`order-content ${clickedOrderIds.includes(order.orderId) ? 'clicked' : ''}`}
                     onDoubleClick={() => handleOrderSelect(order.orderId)}
                     onMouseEnter={() => setHoveredOrderId(order.orderId)}
                     onMouseLeave={() => setHoveredOrderId('')}>
                  <div className="row">
                    <div className='col-3 pl-0'>
                      <p className='p-0 m-0 font-weight-bold'>
                        <Link to={'/admin/order/' + order.orderId}>
                          #{order.orderId}
                        </Link>
                        <small>
                          {renderGenerateMessageToClipboardIcon(order, true)}
                        </small>
                      </p>
                      <p className='p-0 m-0'><small>{order.createTime}</small></p>
                      <div className='dropdown dropdown-toggle font-weight-bold'
                           onClick={() => toggleExpand(order.orderId)}>
                        Товари в замовленні
                      </div>
                    </div>
                    <div className='col-2'>
                      {renderPrice(order)}
                      <p className='p-0 m-0'><small>{order.orderItems.reduce((acc, item) => acc + item.quantity, 0)}шт.</small></p>
                    </div>
                    <div className='col-2'>
                      <p className='p-0 m-0'>
                        <i className='bi bi-person-fill' />
                        <span style={{ cursor: 'pointer' }}
                              onClick={() => copyToClipboard(order.userInfo.name + ' ' + order.userInfo.surname)}>
                          {order.userInfo.name}{' '}{order.userInfo.surname}
                        </span>
                        {order.userInfo.userId && (
                          <span className='font-weight-bold small'>{' '}(#{order.userInfo.userId})</span>
                        )}
                      </p>
                      <p className='p-0 m-0'><small><i className='bi bi-telephone-fill pr-1' />
                        <span style={{ cursor: 'pointer' }}
                              onClick={() => copyToClipboard(order.userInfo.phone)}>
                          {order.userInfo.phone}
                        </span>
                      </small></p>
                      {order.doNotCall && (
                        <span className='text-success small font-weight-bold'>
                          <i className='bi bi-x-circle-fill pr-1' />
                          Не дзвонити
                        </span>
                      )}
                    </div>

                    <div className='col-3'>
                      <p className='p-0 m-0'>
                        <a href="#" data-toggle="modal" data-target="#deliveryModal"
                           className={order.deliveryCost && order.deliveryCost > 0 ? "text-success" : ""}
                           onClick={() => {
                          setSelectedOrderId(order.orderId);
                          setDeliveryCost(order.deliveryCost);
                        }}>
                          <i className='bi bi-truck pr-1'/>
                        </a>
                          {order.userInfo.receiveMethod.description}
                      </p>
                      <div className='dropdown'>
                        <div className='dropdown-toggle'
                             data-toggle='dropdown'
                             aria-expanded='false'>
                          {order.paymentType.databaseValue === 'CREDIT_CARD' ? (
                            <i className='bi bi-credit-card pr-1' />
                          ) : order.paymentType.databaseValue === 'CASH_ON_DELIVERY' ? (
                            <i className='bi bi-cash-coin pr-1' />
                          ) : null}
                          {order.paymentType.adminValue}
                        </div>
                        <div className='dropdown-menu payment-type'>
                          {paymentTypesEnum
                            .filter(paymentType => paymentType.adminValue !== order.paymentType.adminValue)
                            .map((paymentType, index) => (
                              <a
                                key={index}
                                className='dropdown-item'
                                onClick={() => handlePaymentTypeChange(order, paymentType, getOrders)}>
                                {paymentType.adminValue}
                              </a>
                            ))}
                        </div>
                      </div>
                      {!order.adminComment && (
                        <span className='font-weight-bold cursor-pointer' data-toggle='modal'
                              data-target='#adminCommentModal'
                              onClick={() => {
                                setSelectedOrderId(order.orderId);
                                setAdminComment('');
                              }}>
                          <i className='bi bi-chat-square-text pr-1' />Додати коментар
                        </span>
                      )}
                    </div>

                    <div className='col-2 pr-0'>
                      <div className='dropdown font-size-20px'>
                        <div className={defineOrderStatusClassName(order.orderStatus)}
                             data-toggle='dropdown'
                             aria-expanded='false'>
                          {order.orderStatus.adminValue}
                        </div>
                        <div className='dropdown-menu order-status'>
                          {orderStatusesEnum
                            .filter(orderStatus => orderStatus.adminValue !== order.orderStatus.adminValue)
                            .map((status, index) => (
                              <a
                                key={index}
                                className='dropdown-item'
                                onClick={() => handleOrderStatusChange(order, status, getOrders)}>
                                {status.adminValue}
                              </a>
                            ))}
                        </div>
                      </div>

                      <div className='dropdown'>
                        <div className={definePaymentStatusClassName(order.paymentStatus)}
                             data-toggle='dropdown'
                             aria-expanded='false'>
                          {order.paymentStatus.adminValue}
                        </div>
                        <div className='dropdown-menu payment-statuses'>
                          {paymentStatusesEnum
                            .filter(paymentStatus => paymentStatus.adminValue !== order.paymentStatus.adminValue)
                            .map((paymentStatus, index) => (
                              <a
                                key={index}
                                className='dropdown-item'
                                onClick={() => handlePaymentStatusChange(order, paymentStatus, getOrders)}>
                                {paymentStatus.adminValue}
                              </a>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  {renderCommentsBlock(order)}

                  <div className='pt-2'>
                    {expandedOrders.includes(order.orderId) && order.orderItems && order.orderItems.map((item, i) => (
                      <div className='row product-items bg-light pt-2' key={i}>
                        <div className='col-1 pr-0'><span className="font-weight-bold text-muted mr-2">#{item.productId}</span></div>
                        <div className='col-5 pl-0'  style={{ cursor: 'pointer' }}
                             onClick={() => copyToClipboard(item.name)}>
                          {item.name}
                          {item.state && item.state.databaseValue && item.state.databaseValue === 'PREORDER' && (
                            (<span className="font-weight-bold"> [В передзамовленні]</span>)
                          )}
                          {item.state && item.state.databaseValue && item.state.databaseValue !== 'PREORDER' && item.parentProductName && (
                            (<span className="font-weight-bold"> [{item.parentProductName}]</span>)
                          )}
                          <small>
                            {renderClipBoard(item.name, order)}
                          </small>
                        </div>
                        <div className='col-2'>
                          {renderProductPrice(item)}
                        </div>
                        <div className='col-1'>
                          <p>{item.quantity}шт</p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className='col-12 p-0'>
                    <hr className='p-0' />
                  </div>
                </div>
              );
            }) : null}
        </div>

        <div className='content-mobile d-lg-none'>
          <div className='filters'>
            {showFiltersMobile && (
              <div>
                <div className='row py-2'>
                  <div className='col-5'>
                    {renderOrderIdFilter()}
                  </div>
                  <div className='col-5'>
                    {renderPriceFilter()}
                  </div>
                  <div className='col-2'>
                    <div className='d-flex justify-content-end'>
                      {renderNumberPerPageDropdown()}
                    </div>
                  </div>
                </div>

                <div className='row py-2'>
                  <div className='col-12 d-flex justify-content-end'>
                    <div>
                      {renderSurnameFilter()}
                    </div>
                    <div className='pl-1'>
                      {renderPhoneFilter()}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    {renderSearchByProduct()}
                  </div>
                  <div className='col-5'>
                  </div>
                </div>
              </div>
            )}


            <div className='row py-2'>
              <div className='col-12 d-flex justify-content-between'>
                {renderPaymentStatusFilter()}
                {renderOrderStatusFilter()}
              </div>
            </div>

            <div className='row pt-4'>
              <div className='col-12'>
                <div className='d-flex justify-content-between'>
                  <button className='btn btn-sm btn-dark mr-3 ml-0' onClick={() => setShowFiltersMobile(!showFiltersMobile)}>
                    {showFiltersMobile ? 'Менше' : 'Більше'}
                  </button>
                  <div>
                    <button className='btn btn-sm btn-dark mr-2' onClick={searchByFilters}>Оновити</button>
                    <button className='btn btn-sm btn-danger' onClick={clearFilters}>Очистити фільтр</button>
                  </div>
                </div>
              </div>
              <div className='col-12 pt-2'>
                {clickedOrderIds.length > 0 && (
                  <div className='d-flex justify-content-between'>
                    <div className='dropdown pr-2'>
                      <button
                        className='btn btn-sm btn-dark dropdown-toggle'
                        type='button'
                        id='dropdownMenuButton'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        Вибрано {clickedOrderIds.length} замовлень
                      </button>
                      <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                        {clickedOrderIds.length > 1 && (
                          <button className='dropdown-item' data-toggle='modal'
                                  data-target='#mergeOrdersConfirmationModal'>
                            Об'єднати
                          </button>
                        )}
                      </div>
                    </div>
                    <button className='btn btn-sm btn-danger' onClick={() => setClickedOrderIds([])}>
                      Відмінити вибір
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <hr />
              </div>
            </div>
          </div>

          <div className='orders'>
            <div className='text-center font-weight-bold'>Всього замовлень: {ordersCount}</div>
            {orders !== undefined && orders !== null
              ? orders.map((order, i) => {
                return (
                  <div key={i}
                       onTouchStart={(event) => handleOrderContentTouchStart(event, order.orderId)}
                       onTouchEnd={(event) => handleOrderContentTouchEnd(event)}
                       onTouchMove={(event) => handleOrderContentTouchMove(event)}>
                    <div className='row pt-1'>
                      <div className='col-12 p-0'>
                        <div className='card'>
                          <div className='card-header'>
                            <div className='col-12 d-flex justify-content-between p-0'>
                              <div>
                                <p className='p-0 m-0 font-weight-bold'>
                                  <Link to={'/admin/order/' + order.orderId}>
                                    #{order.orderId}
                                  </Link>
                                  {renderGenerateMessageToClipboardIcon(order, false)}
                                </p>
                                <p className='p-0 m-0 text-muted'><small>{order.createTime}</small></p>
                              </div>

                              <div>
                                <div className='dropdown'>
                                  <div className={defineOrderStatusClassName(order.orderStatus)}
                                       data-toggle='dropdown'
                                       aria-expanded='false'>
                                    {order.orderStatus.adminValue}
                                  </div>
                                  <div className='dropdown-menu dropdown-menu-right order-status'>
                                    {orderStatusesEnum
                                      .filter(orderStatus => orderStatus.adminValue !== order.orderStatus.adminValue)
                                      .map((status, index) => (
                                        <a
                                          key={index}
                                          className='dropdown-item'
                                          onClick={() => handleOrderStatusChange(order, status, getOrders)}>
                                          {status.adminValue}
                                        </a>
                                      ))}
                                  </div>
                                </div>

                                <div className='dropdown'>
                                  <div className={definePaymentStatusClassName(order.paymentStatus)}
                                       data-toggle='dropdown'
                                       aria-expanded='false'>
                                    {order.paymentStatus.adminValue}
                                  </div>
                                  <div className='dropdown-menu dropdown-menu-right payment-statuses'>
                                    {paymentStatusesEnum
                                      .filter(paymentStatus => paymentStatus.adminValue !== order.paymentStatus.adminValue)
                                      .map((paymentStatus, index) => (
                                        <a
                                          key={index}
                                          className='dropdown-item'
                                          onClick={() => handlePaymentStatusChange(order, paymentStatus, getOrders)}>
                                          {paymentStatus.adminValue}
                                        </a>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={`card-body ${clickedOrderIds.includes(order.orderId)  ? 'clicked' : ''}`}>
                            <p className='p-0 m-0'>
                              <i className='bi bi-person-fill pr-1' />
                              <span>{order.userInfo.name}{' '}
                                <span onClick={() => copyToClipboard(order.userInfo.surname)}>
                                {order.userInfo.surname},{' '}
                                </span>
                                <span onClick={() => copyToClipboard(order.userInfo.phone)}>
                                  {order.userInfo.phone}
                              </span>
                                {order.userInfo.userId && (
                                  <span className='font-weight-bold'>{' '}(#{order.userInfo.userId})</span>
                                )}
                              </span>
                            </p>
                            <p className='p-0 m-0'>
                              <a href="#" data-toggle="modal" data-target="#deliveryModal"
                                 className={order.deliveryCost && order.deliveryCost > 0 ? "text-success" : ""}
                                 onClick={() => {
                                setSelectedOrderId(order.orderId);
                                setDeliveryCost(order.deliveryCost);
                              }}>
                              <i className='bi bi-truck pr-1' />
                              </a>
                              <span>{order.userInfo.receiveMethod.description}</span>
                            </p>

                            <div className='dropdown'>
                              <div className='dropdown-toggle'
                                   data-toggle='dropdown'
                                   aria-expanded='false'>
                                {order.paymentType.databaseValue === 'CREDIT_CARD' ? (
                                  <i className='bi bi-credit-card pr-1' />
                                ) : order.paymentType.databaseValue === 'CASH_ON_DELIVERY' ? (
                                  <i className='bi bi-cash-coin pr-1' />
                                ) : null}
                                Тип оплати: {order.paymentType.adminValue}
                              </div>
                              <div className='dropdown-menu payment-type'>
                                {paymentTypesEnum
                                  .filter(paymentType => paymentType.adminValue !== order.paymentType.adminValue)
                                  .map((paymentType, index) => (
                                    <a
                                      key={index}
                                      className='dropdown-item'
                                      onClick={() => handlePaymentTypeChange(order, paymentType, getOrders)}>
                                      {paymentType.adminValue}
                                    </a>
                                  ))}
                              </div>
                            </div>

                            {order.doNotCall && (
                              <span className='text-success font-weight-bold'>
                                <i className='bi bi-x-circle-fill pr-1' />
                                Не дзвонити
                              </span>
                            )}

                            {renderPrice(order)}

                            {renderCommentsBlock(order)}
                            {!order.adminComment && (
                              <span className='font-weight-bold cursor-pointer' data-toggle='modal'
                                    data-target='#adminCommentModal'
                                    onClick={() => {
                                      setSelectedOrderId(order.orderId);
                                      setAdminComment('');
                                    }}>
                                <i className='bi bi-chat-square-text pr-1' />Додати коментар адміністратора
                              </span>
                            )}

                            <div className='dropdown dropdown-toggle font-weight-bold pt-2'
                                 onClick={() => toggleExpand(order.orderId)}>
                              Товари в замовленні
                            </div>

                            {expandedOrders.includes(order.orderId) && order.orderItems && order.orderItems.map((item, i) => (
                              <div className='row product-items bg-light pt-3' key={i}>
                                <div className='col-12 d-flex justify-content-between'>
                                  <div><span className="font-weight-bold text-muted">#{item.productId}</span>
                                    <span onClick={() => copyToClipboard(item.name)}> {item.name}</span>
                                    {item.state && item.state.databaseValue && item.state.databaseValue === 'PREORDER' && (
                                      (<span className="font-weight-bold"> [В передзамовленні]</span>)
                                    )}
                                    {item.state && item.state.databaseValue && item.state.databaseValue !== 'PREORDER' && item.parentProductName && (
                                      (<span className="font-weight-bold"> [{item.parentProductName}]</span>)
                                    )}
                                    , {item.quantity}шт</div>
                                  <div className="font-weight-bold">{item.discountedPrice}грн</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }) : null}
          </div>

        </div>

        <div className='center pt-0 mt-0'>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={onClickPage}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={currentPage}
          />
        </div>

        <div className='row'>
          <div className='col p-0'>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
;

export default GeneralAdminOrdersPage;
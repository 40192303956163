import React, { useState } from 'react';
import axios from 'axios';

const NovaPostCityInput = ({ value, onCityChange, inputClassName }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const searchSettlements = (query) => {
    axios
      .post("https://api.novaposhta.ua/v2.0/json/", {
        apiKey: "bea79947492a4e12621ac03cba5d8893",
        modelName: "AddressGeneral",
        calledMethod: "searchSettlements",
        methodProperties: {
          CityName: query,
          Limit: "50",
          Page: "1",
        },
      })
      .then((response) => {
        if (response.data.success) {
          const addresses = response.data.data[0].Addresses.map((address) => ({
            present: address.Present,
            ref: address.Ref,
            deliveryCity: address.DeliveryCity
          }));
          setSuggestions(addresses);
        }
      })
      .catch((error) => {
        console.error("Error fetching settlements:", error);
      });
  };

  const handleCityChange = (e) => {
    const inputValue = e.target.value;
    onCityChange({ present: inputValue, ref: null });
    if (inputValue.length > 0) {
      searchSettlements(inputValue);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    onCityChange(suggestion);
    setShowSuggestions(false);
  };

  return (
    <div className='mb-3'>
      <label htmlFor='city'>Місто</label>
      <input
        type='search'
        className={inputClassName ? inputClassName : 'form-control'}
        name='city'
        placeholder='Введіть місто'
        onChange={handleCityChange}
        value={value}
        autoComplete='new-password'
      />
      {showSuggestions && (
        <ul className='list-group' style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="list-group-item"
              onClick={() => handleSuggestionClick(suggestion)}
              style={{ cursor: 'pointer' }}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e9ecef'}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#fff'}
            >
              {suggestion.present}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NovaPostCityInput;

import { create } from 'zustand';
import axios from 'axios';

export const useBannerStore = create(set => ({
  bannerPath: '',

  fetchBannerPath: async () => {
    await axios.get('/nodeapi/setting?name=banner_path').then(res => {
      if (res.status === 200) {
        const bannerPath = res.data[0].value;
        set({ bannerPath });
      }
    });
  },
}));

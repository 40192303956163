class Storage {
  get = item => {
    return localStorage.getItem(item);
  };

  set = (key, value) => {
    localStorage.setItem(key, value);
  };

  remove = key => {
    localStorage.removeItem(key);
  };

  clear = () => {
    localStorage.clear();
  };
}

export default Storage;

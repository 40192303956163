import React, { useEffect } from 'react';

const Alert = ({ show, setShow, message, timeout }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setShow(false);
      }, timeout);

      return () => clearTimeout(timer);
    }
  }, [show, setShow, timeout]);

  if (!show) return null;

  return (
    <div
      className='custom-alert alert alert-dark alert-dismissible fade show slide-in-animation text-center font-weight-bold'
      role='alert'
      style={{
           position: 'fixed',
           left: '50%',
           transform: 'translate(-50%, 100%)',
           width: 'auto',
           zIndex: 1050,
           animation: 'slideInFromTop 0.5s ease-in-out forwards',
         }}
    >
      {message}
      <button type="button" className="close" aria-label="Close" onClick={() => setShow(false)}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default Alert;
import axios from 'axios';

export const fetchOrderStatuses = (token, setOrderStatusesEnum) => {
  axios
    .get('/api/v1/order/statuses', {
      headers: {
        Authorization: token,
      },
    })
    .then(res => {
      if (res.status === 200) {
        setOrderStatusesEnum(res.data.data);
      }
    }).catch(error => {
    console.error('Failed to fetch order statuses:', error);
  });
};

export const fetchPaymentTypes = (token, setPaymentTypesEnum) => {
  axios
    .get('/api/v1/order/payment-types', {
      headers: {
        Authorization: token,
      },
    })
    .then(res => {
      if (res.status === 200) {
        setPaymentTypesEnum(res.data.data);
      }
    }).catch(error => {
    console.error('Failed to fetch payment types:', error);
  });
};

export const fetchPaymentStatuses = (token, setPaymentStatusesEnum) => {
  axios
    .get('/api/v1/order/payment-statuses', {
      headers: {
        Authorization: token,
      },
    })
    .then(res => {
      if (res.status === 200) {
        setPaymentStatusesEnum(res.data.data);
      }
    }).catch(error => {
    console.error('Failed to fetch payment statuses:', error);
  });
};

export const fetchProductOrderTypes = (token, setPoProductOrderType) => {
  axios
    .get('/api/v1/order/po-order-types', {
      headers: {
        Authorization: token,
      },
    })
    .then(res => {
      if (res.status === 200) {
        setPoProductOrderType(res.data.data);
      }
    }).catch(error => {
    console.error('Failed to fetch product order types:', error);
  });
};

export const fetchProductOrderStatuses = (token, setPoProductStatus) => {
  axios
    .get('/api/v1/order/po-product-statuses', {
      headers: {
        Authorization: token,
      },
    })
    .then(res => {
      if (res.status === 200) {
        setPoProductStatus(res.data.data);
      }
    }).catch(error => {
    console.error('Failed to fetch product order statuses', error);
  });
};

export const fetchReceiveMethods = (token, setReceiveMethods) => {
  axios
    .get('/api/v1/receive-methods?isActive=true')
    .then(response => {
      setReceiveMethods(response.data.data);
    })
    .catch(error => {
      console.error('Error fetching delivery methods:', error);
    });
};

export const fetchProductStates = (token, setProductStates) => {
  axios
    .get('/api/v1/product/states', {
      headers: {
        Authorization: token,
      },
    })
    .then(res => {
      if (res.status === 200) {
        setProductStates(res.data.data);
      }
    }).catch(error => {
    console.error('Failed to fetch order statuses:', error);
  });
};
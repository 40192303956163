import axios from 'axios';
import { create } from 'zustand';
import Storage from '../components/Storage';

export const useProductStore = create((set, get) => ({
  products: null,
  fetchAllProducts: async (url, numberPerPage) => {
    const token = new Storage().get('token');
    await axios.get(url, {
      headers: {
        Authorization: token,
      },
    }).then(res => {
      const products = res.data.data;
      set({ products: products });
      const totalCount = res.data.totalNumber;
      const pageCount = Math.ceil(totalCount / numberPerPage);
      set({ pageCount: pageCount });
    });
  }
}));

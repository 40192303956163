import axios from 'axios';
import { useBasketStore } from '../store/Basket';

export const MAX_HOUR_INFO_MESSAGE_DISABLE = 24;

export const HEADER_LINKS = [
  { text: 'Стартові набори', url: '/boxes?categoryIds=60' },
  { text: 'Фарби', url: '/boxes?categoryIds=62' },
  { text: 'Underworlds', url: '/boxes?categoryIds=61' },
  { text: 'Kill Team', url: '/boxes?categoryIds=48' },
  { text: 'Warcry', url: '/boxes?categoryIds=49' },
  { text: 'WH40k', url: '/boxes?categoryIds=53' },
  { text: 'Age of Sigmar', url: '/boxes?categoryIds=54' },
  { text: 'Webstore exclusive', url: '/boxes?categoryIds=63' },
  { text: 'Necromunda', url: '/boxes?categoryIds=56' },
];

export const HEADER_OTHER_LINKS = [
  { text: 'Blood Bowl', link: '/boxes?categoryIds=58' },
  { text: 'Blackstone Fortress', link: '/boxes?categoryIds=55' },
];

export const incrementProductViewCount = (productId) => {
  const token = localStorage.getItem("token");
  if (!token) {
    axios.get("/nodeapi/product/views/increment/" + productId);
    return;
  }
  axios
    .get("/api/v1/auth/current-user", {
      headers: {
        Authorization: token
      }
    })
    .then(res => {
      if (res.status === 200) {
        let userType = res.data.data.type;
        if (userType !== 'ADMIN') {
          axios.get("/nodeapi/product/views/increment/" + productId);
        }
      }
    })
    .catch(reason => {
      axios.get("/nodeapi/product/views/increment/" + productId);
    });
}
 export const scrollPageToTop = () => {
   window.scrollTo(0,0);
 };

export const recalculateBasket = (token) => {
  axios
    .post('/api/v1/user/apply-discount',
      useBasketStore.getState().products,
      {
        headers: {
          Authorization: token
        },
      })
    .then(function(res) {
      useBasketStore.getState().setProducts(res.data.data.products);
    })
    .catch(function(error) {
      console.log("Error during price recalculation = ", error);
    });
}

//"http://31.131.24.21"
export const BASE_URL = "https://waaagh-shop.com.ua";
import React, { Component } from "react";
import "../../styles/login.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { validateAll } from "indicative";
import { recalculateBasket } from '../../shared/constants';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      emailOrPhoneNumber: "",
      password: "",
      errors: {
        emailOrPhoneNumber: "",
        password: ""
      },
      responseError: "",
      disabled: false
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ disabled: true });
    const data = this.state;

    const messages = {
      required: "Це поле є обов'язковим",
      "emailOrPhoneNumber.max": "Довжина поля повинна бути не більшою 100 символів",
      "emailOrPhoneNumber.min": "Довжина поля повинна бути не менше 5 символів"
    };

    const rules = {
      emailOrPhoneNumber: "required | string | min:5 | max:100",
      password: "required | string"
    };

    validateAll(data, rules, messages)
      .then(() => {
        const realThis = this;

        const request = {
          emailOrPhoneNumber: data.emailOrPhoneNumber,
          password: data.password
        }

        axios
          .post("/api/v1/auth/login", request)
          .then(function(res) {
            localStorage.setItem("token", res.data.data.token);
            recalculateBasket(res.data.data.token);
            let currentUrl = localStorage.getItem("currentUrl");
            if (currentUrl !== undefined && currentUrl !== null) {
              realThis.props.history.push(currentUrl);
            } else {
              realThis.props.history.push("/");
            }
          })
          .catch(function(error) {
            const res = error.response;
            if (res.data.isError) {
              realThis.setState({
                responseError: res.data.errorMessage,
                errors: {},
                disabled: false
              });
            }
          });
      })
      .catch(errors => {
        this.setState({ responseError: null });
        const formattedErrors = {};
        errors.forEach(error => (formattedErrors[error.field] = error.message));
        this.setState({ errors: formattedErrors, disabled: false });
      });
  };

  render() {
    const { emailOrPhoneNumber, password, responseError, errors, disabled } = this.state;
    return (
      <div id="loginBody">
        <div className="login-form">
          <form action="" onSubmit={this.handleSubmit} method="post">
            <h2 className="modal-title">Вхід в профіль користувача</h2>
            <div className="form-group">
              <div className="input-group">
                <span className="input-group-addon">
                  <i className="fa fa-user"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  name="emailOrPhoneNumber"
                  placeholder="Номер телефону або імейл"
                  onChange={this.onChange}
                  value={emailOrPhoneNumber}
                />
              </div>
              <div className="form-field-error">{errors.emailOrPhoneNumber}</div>
            </div>
            <div className="form-group">
              <div className="input-group">
                <span className="input-group-addon">
                  <i className="fa fa-lock"></i>
                </span>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Пароль"
                  onChange={this.onChange}
                  value={password}
                />
              </div>
              <div className="form-field-error">{errors.password}</div>
            </div>
            <div className="form-group small clearfix">
              <label className="checkbox-inline">
                <input type="checkbox" /> Запамятати мене
              </label>
            </div>
            <input
              type="submit"
              className="btn btn-primary btn-block btn-lg"
              value="Увійти"
              disabled={disabled}
            />
            <div className="form-field-error-special pt-2">{responseError}</div>
          </form>
          <div className="text-center pt-3">
            Забули пароль? <Link to="/restore-password">Відновити пароль</Link>
          </div>
          <div className="text-center">
            Ще не створили аккаунт? <Link to="/signup">Зареєструйтесь</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;

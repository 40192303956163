import React from 'react';
import { renderSum } from '../utils/utils';

import Modal from 'react-bootstrap/Modal';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { useEffect, useState } from 'react';

import { useBasketStore } from '../store/Basket';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BASE_URL } from '../shared/constants';
const MAX_HOUR_IN_BASKET = 16;

const Basket = ({ showModal, close }) => {
  const products = useBasketStore(state => state.products);
  const patchProduct = useBasketStore(state => state.patchProduct);
  const deleteProductById = useBasketStore(state => state.deleteProductById);
  const deleteProductsByIds = useBasketStore(state => state.deleteProductsByIds);
  const [productIdsInCart, setProductIdsInCart] = useState([]);

  useEffect(() => {
    const productIdsInCart = products.map(product => product.id);
    setProductIdsInCart(productIdsInCart);

    const expiredProductIds = products.map(product => {
      const dateNow = Date.now();
      if (Math.abs(dateNow - product.dateAdded) / 36e5 > MAX_HOUR_IN_BASKET) {
        return product.id;
      }
    });

    deleteProductsByIds(expiredProductIds);
  }, []);

  const removeProductFromCart = productId => {
    deleteProductById(productId);
  };

  const onChangeQuantity = (add, productId) => {
    const index = products.findIndex(product => product.id === productId);

    let product = products[index];
    let resultQuantity = add ? product.quantity + 1 : product.quantity - 1;
    if (resultQuantity >= 1 && resultQuantity <= product.maxQuantity) {
      product = { ...product, quantity: resultQuantity, showTooltip: false };
    }

    if (resultQuantity > product.maxQuantity) {
      product = { ...product, showTooltip: true };
    }
    patchProduct(product);
  };

  const renderPrice = (product, multiplyOnQuantity) => {
    let price = multiplyOnQuantity ? product.price * product.quantity : product.price;
    let discountedPrice = multiplyOnQuantity
      ? product.discountedPrice * product.quantity
      : product.discountedPrice;
    if (product.discountedPrice !== product.price) {
      return (
        <div>
          <h6>
            <strike style={{ textDecoration: 'line-through', color: 'red' }}>
              <strong>{price}грн</strong>
            </strike>
          </h6>
          <h6>
            <strong>{discountedPrice}грн</strong>
          </h6>
        </div>
      );
    } else {
      return (
        <h6>
          <strong>{price}грн</strong>
        </h6>
      );
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => close()}
      size='xl'
      aria-labelledby='example-modal-sizes-title-lg'
    >
      <Modal.Header closeButton>
        <Modal.Title id='example-custom-modal-styling-title'>Кошик товарів</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {products.map(product => {
            const { quantity } = product;
            return (
              <div key={product.id + product.name}>
                <Row className='show-grid'>
                  <Col xl={1} lg={1} md={12} xs={12}>
                    <img
                      src={BASE_URL + "/api/v1/product/image/"  + product.image}
                      className='cartImage'
                      alt='cartImage'
                    />
                  </Col>

                  <Col xl={3} lg={2} md={12} xs={12}>
                    <h6>{product.name}</h6>
                  </Col>
                  <Col xl={2} lg={2} md={2} xs={3}>
                    {renderPrice(product, false)}
                  </Col>

                  <Col xl={2} lg={3} md={5} xs={5} className='disable-select'>
                    <span
                      onClick={() => onChangeQuantity(false, product.id)}
                      className='minus bg-dark'
                    >
                      -
                    </span>

                    <span className='quantity pt-1'>{quantity}шт.</span>

                    <OverlayTrigger placement='top' overlay={product.showTooltip ? (<Tooltip id='tooltip'>
                      <strong>Обрано максимальну кількість, більше немає в наявності</strong>
                    </Tooltip>) : (<span></span>)}>
                      <span
                        onClick={() => onChangeQuantity(true, product.id)}
                        className='plus bg-dark'
                      >
                        +
                      </span>
                    </OverlayTrigger>
                  </Col>

                  <Col xl={2} lg={2} md={2} xs={3}>
                    {renderPrice(product, true)}
                  </Col>
                  <Col xl={2} lg={2} md={3} xs={12}>
                    <button
                      type='button'
                      className='btn btn-danger'
                      onClick={() => removeProductFromCart(product.id)}
                    >
                      Видалити
                    </button>
                  </Col>
                </Row>
                <hr />
              </div>
            );
          })}
        </Container>

        {products.length === 0 && <p>Кошик пустий</p>}
      </Modal.Body>

      <Modal.Footer>
        <Container>
          <Row>
            <Col xl={{ span: 4, offset: 6 }} lg={{ span: 5, offset: 5 }} md={8} xs={7}>
              <h5> Загальна сума: {renderSum({ products })}</h5>
            </Col>
            <Col xl={2} lg={2} md={4} xs={5}>
              <a className='btn btn-dark' href='/checkout'>
                Оформити
              </a>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default Basket;

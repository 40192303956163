import React, { Component } from "react";
import "../../styles/signup.css";
import axios from "axios";
import { validateAll } from "indicative";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

class RestorePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      password: "",
      password_confirmation: "",
      confirmationCode: null,
      errors: {
        phoneNumber: "",
        password: ""
      },
      responseError: "",
      showConfirmationBlock: false,
      successMessage: "",
      disabled: false
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangePhone = e => {
    const phoneFormat = "38" + e;
    const regex = /^\+?3?8?(0\d{9})$/;
    if (e === "" || regex.test(phoneFormat)) {
      this.setState({ phoneNumber: e });
      this.setState({ errors: { phoneNumber: "" } });
    } else if (e.length === 10) {
      this.setState({ phoneNumber: "" });
      this.setState({
        errors: { phoneNumber: "Невірний формат номеру телефону" }
      });
    } else {
      this.setState({ phoneNumber: "" });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      errors: {
        phoneNumber: "",
        password: ""
      },
      responseError: "",
      disabled: true
    });

    const data = this.state;

    const messages = {
      required: "Це поле є обов'язковим",
      "phoneNumber.required": "Введіть коректний номер телефону",
      "password.confirmed": "Паролі не однакові ",
      min: "Довжина поля повинна бути не менше 5 символів",
      "phoneNumber.max":
        "Номер телефону має містити 10 цифр Формат: 0501234567",
      "phoneNumber.min":
        "Номер телефону має містити 10 цифр Формат: 0501234567",
      "email.max": "Довжина поля повинна бути не більшою 100 символів"
    };

    const rules = !data.showConfirmationBlock
      ? {
          phoneNumber: "required | number | min:10 | max:10"
        }
      : {
          phoneNumber: "required | number | min:10 | max:10",
          password: "required | string | min:5 | confirmed"
        };

    const request = {
      phoneNumber: data.phoneNumber,
      password: data.password,
      passwordConfirmation: data.password_confirmation,
      confirmationCode: data.confirmationCode
    };
    validateAll(data, rules, messages)
      .then(() => {
        const realThis = this;
        axios
          .post("/api/v1/auth/restore-password", request)
          .then(function(res) {
            if (res.status === 200 && !res.data) {
              realThis.setState({
                confirmationCode: "",
                showConfirmationBlock: true,
                successMessage: "Код підтвердження відправлено на вказаний номер",
                disabled: false
              });
            } else if (res.status === 200 && res.data) {
              localStorage.setItem("token", res.data.data.token);
              realThis.props.history.push("/");
            }
          })
          .catch(function(error) {
            const res = error.response;
            if (res.data.isError) {
              realThis.setState({
                responseError: res.data.errorMessage,
                errors: {},
                disabled: false
              });
            }
          });
      })
      .catch(errors => {
        this.setState({ responseError: null });
        const formattedErrors = {};
        errors.forEach(error => (formattedErrors[error.field] = error.message));
        this.setState({ errors: formattedErrors, disabled: false });
      });
  };

  render() {
    const { errors, responseError, successMessage, disabled } = this.state;
    return (
      <div id="signUpBody">
        <div className="signup-form">
          <form onSubmit={this.handleSubmit}>
            <h2>Відновити пароль</h2>
            <p className="lead">Відновлення паролю за номером телефону</p>
            <div className="form-group">
              <div className="input-group">
                <span className="input-group-addon">
                  <i className="fa fa-user"></i>
                </span>
                <NumberFormat
                  className="form-control"
                  name="phoneNumber"
                  placeholder="Введіть номер телефону"
                  format="+38 (###) ###-####"
                  mask="_"
                  onValueChange={value => this.onChangePhone(value.value)}
                />
              </div>
              <div className="form-field-success">{successMessage}</div>
              <div className="form-field-error">{errors.phoneNumber}</div>
            </div>

            {this.state.showConfirmationBlock ? (
              <div>
                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="fa fa-lock"></i>
                    </span>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Введіть новий пароль"
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-field-error">{errors.password}</div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="fa fa-lock"></i>
                      <i className="fa fa-check"></i>
                    </span>
                    <input
                      type="password"
                      className="form-control"
                      name="password_confirmation"
                      placeholder="Підтвердити пароль"
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-field-error">{errors.password}</div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="fa fa-lock"></i>
                      <i className="fa fa-check"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      name="confirmationCode"
                      placeholder="Введіть код підтвердження"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className='form-group'>
              <button
                type='submit'
                className='btn btn-primary btn-block btn-lg'
                disabled={disabled}
              >
                Змінити пароль
              </button>
            </div>
            <div className='form-field-error-special pt-2'>{responseError}</div>
          </form>
          <div className='text-center'>
            Вже маєте аккаунт? <Link to='/login'>Увійти</Link>.
          </div>
        </div>
      </div>
    );
  }
}

export default RestorePassword;
